import React, { useCallback, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import debounce from 'lodash/debounce'
import {
  ContentWrapper,
  InputBody,
  InputComponents,
  Label,
  LabelWrapper,
  PostText,
  PreText,
  Wrapper,
  DropIcon,
  ErrorMessage
} from '../TextInput/styles'
import { ButtonWrapper, ActionButton } from './styles'
import Options from './Options'
import { fetchResults, getAutoSuggestProps } from './utils'
import { Button } from '../Button'
import { validateInnerHTML, useDocumentWidth, stripHtml } from '../../utils'
import { themes } from '../../themes'

const defaultOptions = [
  {
    id: 'IN',
    label: 'India',
    disabled: false
  },
  {
    id: 'US',
    label: 'USA',
    disabled: false
  },
  {
    id: 'UK',
    label: 'United Kingdom',
    disabled: false
  },
  {
    id: 'GR',
    label: 'Germany',
    disabled: false
  },
  {
    id: 'SP',
    label: 'Spain (disabled)',
    disabled: true
  }
]

const Select = (props) => {
  const [dropdownOptions, setOptions] = useState([])
  const [hasContent, setContent] = useState('')
  const [show, showOptions] = useState(false)
  const [selected, setValue] = useState('')
  const [showSuggestions, setShowSuggestions] = useState(false)

  // Get document width (reactive to window resizing).
  const docWidth = useDocumentWidth()

  const {
    id,
    inputRef,
    floatingLabel,
    labelPosition,
    corner,
    labelText,
    labelMargin,
    labelIsHTML,
    preText,
    postText,
    hasError,
    color,
    asyncProps,
    handleSelect,
    type,
    options,
    width,
    maxOptionsCount,
    filterFunction,
    defaultSelected,
    isFilterable,
    themeConfiguration,
    isAsterisk,
    nListOptions,
    autoSuggestButtonTheme,
    labelStyles,
    ...otherProps
  } = props

  const _fetchResultsDebounce = useRef(
    debounce(async (value) => {
      const op = await fetchResults(asyncProps, value)
      inputRef.current.click()
      setOptions(op)
      return op
    }, 750)
  )

  let autoSuggestProps = getAutoSuggestProps(dropdownOptions, selected)
  const { allowUnrecognized } = asyncProps
  const autoSuggestDefaultOption = [
    {
      id: 'loading',
      label: 'Please wait...',
      disabled: true
    }
  ]
  const { hasAction, removeHandler } = nListOptions || {}
  useEffect(() => {
    if (defaultSelected) {
      if (type === 'A' && defaultSelected.length > 0) {
        setValue(defaultSelected[0].id)
      } else {
        setValue(defaultSelected)
      }
    }
    if (type === 'D' || type === 'DS') {
      setOptions(options)
    }
  }, [type, defaultSelected])

  const filterOptions = (query) => {
    if (filterFunction) {
      return filterFunction(options, query)
    }
    const result = options.filter((o) => {
      if (
        o.label &&
        typeof o.label === 'string' &&
        query &&
        typeof query === 'string'
      ) {
        return (
          o.label.toLowerCase().trim().indexOf(query.trim().toLowerCase()) > -1
        )
      }
    })
    if (result.length) {
      return result
    }
    return [{ id: 'nr', label: 'No results found.', disabled: true }]
  }

  const handleChange = async (e) => {
    const { value } = e.target

    setValue(value)
    setContent(value)
    setShowSuggestions(false)
    if (type === 'A' && value.length > 2) {
      setOptions(autoSuggestDefaultOption)
      await _fetchResultsDebounce.current(value)
    }

    if (type === 'DS' || isFilterable) {
      if (!value) {
        setOptions(options)
        showOptions(true)
      } else {
        const op = filterOptions(value)
        setOptions(op)
        showOptions(true)
      }
    }
  }

  const handleOptionClick = (o) => {
    showOptions(false)
    setShowSuggestions(false)
    setValue(o.label)
    handleSelect(o)
  }

  const handleSuggestions = async () => {
    if (allowUnrecognized && type === 'A' && selected && selected.length > 1) {
      setOptions(autoSuggestDefaultOption)
      const op = await fetchResults(asyncProps, selected)
      const selectedOption = op.filter(
        (option) => option.label.toLowerCase() === selected.toLowerCase()
      )
      setOptions(op)
      if (selectedOption.length) {
        handleSelect(selectedOption)
        setOptions([])
        return
      }
      autoSuggestProps = getAutoSuggestProps(op, selected)
      setShowSuggestions(true)
      if (autoSuggestProps.suggestionText1) {
        showOptions(true)
      } else {
        showOptions(false)
      }
      return
    }
    showOptions(false)
  }

  const keyHandler = useCallback((e) => {
    if (e.key === 'Escape') {
      showOptions(false)
    }
  })
  const docClickHandler = useCallback((e) => {
    if (show && e.target.tagName !== 'INPUT') {
      if (allowUnrecognized) {
        handleSuggestions()
      } else {
        showOptions(false)
      }
    }
  })
  useEffect(() => {
    document.addEventListener('keydown', keyHandler)
    document.addEventListener('click', docClickHandler)
    return () => {
      document.removeEventListener('keydown', keyHandler)
      document.removeEventListener('click', docClickHandler)
    }
  })

  const handleFocus = (e) => {
    const { type } = props
    if (
      type === 'D' ||
      type === 'DS' ||
      (e.target.value.length > 2 && options.length)
    ) {
      showOptions(true)
    }
  }

  /**
   *  @param {string} action
   *  Function to be triggered on clicking suggestion buttons
   */
  const handleButtonClick = (action) => {
    const selectedOption = { id: selected, label: selected }
    if (action !== 'No') {
      handleSelect(selectedOption, true)
    } else {
      setValue('')
    }
    setShowSuggestions(false)
    showOptions(false)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && allowUnrecognized) {
      handleSuggestions()
    }
  }

  const handleActionButtonKeydown = (e) => {
    if (e.key === 'Enter') {
      removeHandler()
    }
  }

  // Remove this when theme is passed from all the clients
  const theme = themeConfiguration || themes[color]

  const _labelStyles = labelStyles || {}

  return (
    <ThemeProvider
      theme={{
        component: 'select',
        floatingLabel,
        labelPosition,
        corner,
        labelMargin,
        preText,
        postText,
        hasContent: hasContent || selected,
        hasError,
        color,
        width,
        showSuggestions,
        ...theme
      }}
    >
      <Wrapper name='wrapper'>
        <ContentWrapper name='content-wrapper'>
          <InputBody data-testid='input-body' docWidth={docWidth}>
            <InputComponents
              onClick={() => showOptions(true)}
              docWidth={docWidth}
              id={`select-field-${id}`}
            >
              {preText && <PreText data-testid='pre-text'>{preText}</PreText>}
              <input
                data-error={hasError}
                onKeyDown={handleKeyDown}
                id={id}
                ref={inputRef}
                aria-label={stripHtml(labelText)}
                onChange={handleChange}
                type='text'
                data-testid='number-input'
                value={selected}
                onFocus={handleFocus}
                autoComplete='new-password'
                {...otherProps}
              />
              {type !== 'A' && (
                <DropIcon name='drop-icon' open={show}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    height='20'
                    width='20'
                    viewBox='0 0 20 20'
                    aria-hidden='true'
                    focusable='false'
                    className='svg-style'
                  >
                    <path d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z' />
                  </svg>
                </DropIcon>
              )}
              {postText && (
                <PostText data-testid='post-text'>{postText}</PostText>
              )}
              {hasAction && (
                <ActionButton
                  onClick={removeHandler}
                  onKeyDown={handleActionButtonKeydown}
                  tabIndex={0}
                />
              )}
            </InputComponents>
            <LabelWrapper id={`select-label-${id}`} name='label-wrapper'>
              {floatingLabel && preText && <PreText>{preText}</PreText>}
              <Label
                htmlFor={id}
                {..._labelStyles}
                data-testid='input-label'
                isAsterisk={isAsterisk}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: validateInnerHTML(labelText, isAsterisk)
                  }}
                />
              </Label>
            </LabelWrapper>
          </InputBody>
          <ErrorMessage data-testid='error-message' />
          {showSuggestions && (
            <LabelWrapper>{autoSuggestProps.suggestionText1}</LabelWrapper>
          )}
        </ContentWrapper>
        {show && dropdownOptions.length > 0 && (
          <Options
            name='options'
            maxOptionsCount={maxOptionsCount}
            onClick={handleOptionClick}
            options={dropdownOptions}
          />
        )}
        {showSuggestions && (
          <div>
            <LabelWrapper>{autoSuggestProps.suggestionText2}</LabelWrapper>
            <ButtonWrapper>
              <Button
                tabIndex='0'
                label={autoSuggestProps.buttonLabel1}
                color={color}
                {...autoSuggestButtonTheme}
                handleButtonClick={() =>
                  handleButtonClick(autoSuggestProps.buttonLabel1)
                }
              />
              {autoSuggestProps.buttonLabel2 && (
                <Button
                  tabIndex='0'
                  label={autoSuggestProps.buttonLabel2}
                  color={color}
                  {...autoSuggestButtonTheme}
                  handleButtonClick={() =>
                    handleButtonClick(autoSuggestProps.buttonLabel2)
                  }
                />
              )}
            </ButtonWrapper>
          </div>
        )}
      </Wrapper>
    </ThemeProvider>
  )
}

Select.defaultProps = {
  floatingLabel: true,
  labelIsHTML: false,
  type: 'D',
  width: 'L',
  corner: 'round',
  labelMargin: 'min',
  options: defaultOptions,
  maxOptionsCount: 5,
  filterFunction: undefined,
  asyncProps: {},
  defaultSelected: {
    label: '',
    id: ''
  }
}

Select.propTypes = {
  /**
   * An ID for the component
   */
  id: PropTypes.string.isRequired,
  /**
   * Ref for Select Component.
   */
  inputRef: PropTypes.object,
  /**
   * A label for the input.
   */
  labelText: PropTypes.string.isRequired,
  /**
   * A placeholder for the input.
   */
  placeholder: PropTypes.string.isRequired,
  /**
   * Indicates behaviour of label.
   */
  floatingLabel: PropTypes.bool,
  /**
   * Position of label, works only if floatingLabel is set to `false`.
   */
  labelPosition: PropTypes.oneOf(['left', 'top']),
  /**
   * Indicates if label is an HTML element.
   */
  labelIsHTML: PropTypes.bool,
  /**
   * Determines the appearance of edge of the box.
   */
  corner: PropTypes.oneOf(['round', 'sharp']),
  /**
   * It refers to the gap between label and the input field. It is applicable only
   * for fixed label when label appears on the left side.
   */
  labelMargin: PropTypes.oneOf(['min', 'max']),
  /**
   * On change handler
   */
  handleSelect: PropTypes.func.isRequired,
  /**
   * Options if it is a dropdown.
   */
  options: PropTypes.array,
  /**
   * Dropdown, Autosuggest, Multiselect Dropdown, DropdownSearch
   */
  type: PropTypes.oneOf(['D', 'A', 'M', 'DS']),
  /**
   * Width of the input field
   */
  width: PropTypes.oneOf(['S', 'M', 'L', 'XL']),
  /**
   * Maximum number of options count.
   */
  maxOptionsCount: PropTypes.number,
  /**
   * Custom Filter Function
   */
  filterFunction: PropTypes.func,
  /**
   * Set filter support.
   */
  isFilterable: PropTypes.bool.isRequired,
  /**
   * Default selected
   */
  defaultSelected: PropTypes.string,
  /**
   * Theme configuration object
   */
  themeConfiguration: PropTypes.object
}

export default Select
