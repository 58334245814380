export class AppConstants {
    public static readonly loginEndPoint = "/api/v2/auth/auth/login";
    public static readonly logoutEndpoint = '/api/v2/auth/auth/logout';
    public static readonly traitsEndpoint = '/api/v2/auth/auth/traits';
    public static readonly jsonServerUrl = "http://localhost:1234";/** this is for restjson testing */
    public static readonly baseUri = "baseUri";
    public static readonly role = "role";
    public static readonly redirectUrl = "redirectUrl";
    public static readonly accessToken = "accessToken";
    public static readonly expiresAt = "expiresAt";
    public static readonly expiresIn = "expiresIn";
    public static readonly code = "code";
    public static readonly login = "/login";
    public static readonly storageAccessTime = 5 * 60 * 1000; //keeping 5 minutes as expiry time we can change this as per requirement
    public static readonly saml = "saml2";
    public static readonly samlResponse = "SAMLResponse";
    public static readonly oidc = "OIDC";
    public static readonly saml2 = "SAML2";
    public static readonly isIdpInitiatedFlow = "isIdpInitiatedFlow";
}
