import styled from 'styled-components'

const Wrapper = styled.div`
  background: ${({ type }) => (type === 'error' ? '#f8d7da' : '#cccccc')};
  border-color: #f5c6cb;
  border-radius: 4px;
  padding: 10px 20px;
  margin-bottom: 30px;
  width: 100%;
`

const Head = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h1 {
    color: ${({ color }) => color || '#721c24'};
    font-size: ${({ fontSize }) => fontSize || '20px'};
  }
`

const CloseIcon = styled.div`
  cursor: pointer;
  font-size: 20px;
  line-height: 1;
  float: right;
`

const Border = styled.hr`
  margin: 0;
`
const Message = styled.p`
  color: ${({ color }) => color || '#721c24'};
  font-size: ${({ fontSize }) => fontSize || '14px'};
`

export { Wrapper, Head, CloseIcon, Border, Message }
