import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import {
  StyledButton,
  FaIcon,
  FaCrossIcon,
  FaLoadingIcon,
  FaPlusCircleIcon,
  ButtonDescription
} from './styles'
import { themes } from '../../themes'

const Button = (props) => {
  const {
    type,
    color,
    shape,
    width,
    disabled,
    label,
    role,
    alignment,
    handleButtonClick,
    tabIndex,
    iconAlignment,
    isLoading,
    themeConfiguration,
    description,
    buttonDescriptionTheme,
    ariaLabel,
    exactBtnTheme,
    addBtnTheme,
    id,
    fontSize,
    marginLeft,
    marginRight,
    attrs
  } = props
  
  // Remove this when theme is passed from all the clients
  const theme = themeConfiguration || themes[color]

  return (
    <ThemeProvider
      theme={{
        type,
        color,
        width,
        shape,
        alignment,
        disabled,
        iconAlignment,
        isLoading,
        buttonDescriptionTheme,
        exactBtnTheme,
        addBtnTheme,
        fontSize,
        marginLeft,
        marginRight,
        ...theme
      }}
    >
      <Fragment>
        <StyledButton
          name='styled-button'
          type='submit'
          role={role}
          onClick={handleButtonClick}
          tabIndex={tabIndex}
          disabled={disabled}
          aria-label={ariaLabel}
          id={id}
          {...attrs}
        >
          <FaPlusCircleIcon
            name='fa-plus-circle'
            className='fas fa-plus-circle'
            aria-hidden='true'
          />
          {label}
          <FaIcon
            data-testid='check-circle-icon'
            name='fa-icon'
            className={
              disabled ? 'fa fa-chevron-circle-right' : 'fa fa-check-circle'
            }
            aria-hidden='true'
          />
          <FaCrossIcon
            data-testid='cross-icon'
            name='fa-cross-icon'
            className='fa fa-times'
            aria-hidden='true'
          />
          <FaLoadingIcon
            data-testid='loading-icon'
            name='fa-loading-icon'
            className='fa fa-circle-o-notch fa-spin'
            aria-hidden='true'
          />
        </StyledButton>
        {description && (
          <ButtonDescription
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </Fragment>
    </ThemeProvider>
  )
}

Button.defaultProps = {
  /**
   * type of the button
   */
  type: 'filled',
  /**
   * tabIndex of the button
   */
  tabIndex: 0,
  /**
   * color of the button
   */
  color: 'blue',
  /**
   * disabled state of the button either true or false
   */
  disabled: false,
  /**
   * alignment of label
   */
  alignment: 'center',
  /**
   * alignment of icon
   */
  iconAlignment: 'right',
  /**
   * shape of the button
   */
  shape: 'rectangle',
  /**
   * width of the button in px or %
   */
  width: 'variable',
  /**
   * to show/hide the loader icon on button
   */
  isLoading: false
}

Button.propTypes = {
  /**
   * type of button
   */
  type: PropTypes.string,
  /**
   * color of the button. Supports colors defined in the theme. Currently supports 'red', 'green', 'blue', 'darkBlue'
   */
  color: PropTypes.string,
  /**
   * disbled state of button
   */
  disabled: PropTypes.bool,
  /**
   * alignment of label
   */
  alignment: PropTypes.string,
  /**
   * alignment of icon
   */
  iconAlignment: PropTypes.string,
  /**
   * shape of the button
   */
  shape: PropTypes.string,
  /**
   * tabIndex of the button
   */
  tabIndex: PropTypes.number,
  /**
   * label of the Button
   */
  label: PropTypes.string.isRequired,
  /**
   * width of the button in px
   */
  width: PropTypes.string,
  /**
   * Function triggered on clicking button.
   */
  handleButtonClick: PropTypes.func.isRequired,
  /**
   * to show/hide the loader icon on button
   */
  isLoading: PropTypes.bool,
  /**
   * Theme configuration object
   */
  themeConfiguration: PropTypes.object,
  /**
   * Additional text to display under button
   */
  description: PropTypes.string,
  /**
   * Theme to be applied to button description
   */
  buttonDescriptionTheme: PropTypes.object,
  /**
   * aria label of the Button
   */
  ariaLabel: PropTypes.string,
  /**
   * id of the Button
   */
  id: PropTypes.string
}

export default Button
