const mapResponse = (response, responseMap) => {
  return response.length && responseMap
    ? response.reduce((acc, r) => {
        return [
          ...acc,
          {
            ...r,
            id: r[responseMap.id],
            label: r[responseMap.label],
            disabled: r[responseMap.completed]
          }
        ]
      }, [])
    : [
        {
          id: 'no-result',
          label: 'No results found',
          disabled: true
        }
      ]
}

const fetchResults = async (asyncProps, query) => {
  const { method, fetchFn, ...rest } = asyncProps
  return await asyncFetch(fetchFn, method, rest, query)
}

const asyncFetch = async (fetchFn, method, rest, query) => {
  const { api, responseMap, defaultParams, searchKey } = rest
  if (method === 'post') {
    const params = {
      ...defaultParams,
      [searchKey]: query
    }
    // eslint-disable-next-line no-undef
    const formData = new FormData()
    for (const key in params) {
      formData.append(key, params[key])
    }
    return await fetchFn.post(api, formData).then((response) => {
      if (response.status === 200) {
        return mapResponse(response.data.response, responseMap)
      } else {
        return [
          {
            id: 'no-result',
            label: 'No results found',
            disabled: true
          }
        ]
      }
    })
  }
  return await fetchFn(api)
    .then((response) => response.json())
    .then((json) => mapResponse(json, responseMap))
}

/**
 *
 * @param {array} options
 * @param {object} selectedOption
 * when type is autouggest and allows unrecognized values
 * Return labels of texts and buttons for autosuggest dropdown
 */
const getAutoSuggestProps = (options, selectedOption) => {
  let suggestionText1 = `The phrase ${selectedOption} was not recognised; please select a phrase from the list below.`
  let suggestionText2 = `Alternatively, select "Cancel" to continue with your original phrase ${selectedOption}.`
  let buttonLabel1 = 'Cancel'
  let buttonLabel2 = ''
  if (Array.isArray(options) && options.length) {
    if (options[0].id === 'no-result') {
      suggestionText1 = ''
      suggestionText2 =
        'Are you sure you wish to continue with an unrecognized disclosure?'
      buttonLabel1 = 'Yes'
      buttonLabel2 = 'No'
    }
  }
  return {
    suggestionText1,
    suggestionText2,
    buttonLabel1,
    buttonLabel2
  }
}

export { mapResponse, fetchResults, getAutoSuggestProps }
