import React, { useState, useRef, useEffect } from 'react'
import { usePopper } from 'react-popper'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import {
  PopperContainer,
  PopperIcon,
  StyledArrow,
  StyledTooltipTitle,
  StyledTooltipBody,
  StyledHorizontalRule,
  PopperWrapper,
  CrossIcon
} from './styles'
import mock from './mockData'
import { stripHtml, validateInnerHTML, useDocumentWidth } from '../../utils'

const TooltipHTMLWrapper = ({ html }) => (
  <StyledTooltipBody
    dangerouslySetInnerHTML={{ __html: validateInnerHTML(html) }}
  />
)

const TooltipText = ({ title, body }) => (
  <React.Fragment>
    {title && (
      <StyledTooltipTitle name='styled-tool-tip-title'>
        {title}
      </StyledTooltipTitle>
    )}
    {title && <StyledHorizontalRule />}
    <StyledTooltipBody name='styled-tool-tip-body'>{body}</StyledTooltipBody>
  </React.Fragment>
)

const Tooltip = (props) => {
  const {
    color,
    faIcon,
    tooltipTitle,
    tooltipBody,
    tooltipHTML,
    popperWidth,
    ariaLabel,
    themeConfiguration
  } = props

  const iconRef = useRef(null)
  const popperRef = useRef(null)
  const mobileArrowRef = useRef(null)
  const docWidth = useDocumentWidth()
  // the ref for the arrow must be a callback ref
  const [arrowRef, setArrowRef] = useState(null)
  const [isMobile, setIsMobile] = useState(docWidth < 540)
  const [showPopper, setShowPopper] = useState(false)
  const [popperleftPosition, setpopperleftPosition] = useState(0)
  const [arrowLeftPosition, setArrowPosition] = useState(0)

  // Determines the placement of popper arrow
  const getArrowPlacement = isMobile ? 'top' : 'right'

  const { styles, attributes } = usePopper(iconRef.current, popperRef.current, {
    placement: getArrowPlacement,
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: arrowRef
        }
      },
      {
        name: 'offset',
        options: {
          offset: [0, 10]
        }
      }
    ]
  })

  useEffect(() => {
    if (popperRef && popperRef.current && showPopper) {
      if (typeof popperRef.current.focus === 'function') {
        popperRef.current.focus()
      }
    }
    const arrowoffsetLeft = mobileArrowRef?.current?.offsetParent?.offsetLeft
    if (arrowoffsetLeft) {
      setArrowPosition(arrowoffsetLeft * -1)
    }
  }, [showPopper])

  useEffect(() => {
    setIsMobile(docWidth < 540)
    const offsetLeft = (((iconRef || {}).current || {}).offsetParent || {})
      .offsetLeft
    if (offsetLeft) {
      setpopperleftPosition(docWidth / 2 - offsetLeft - 300 / 2)
    }
  }, [docWidth])

  const handleKeyDown = (event) => {
    const eventKey = event.key
    const popperState = showPopper
    if (eventKey === 'Enter') {
      setShowPopper(!popperState)
    } else if (showPopper) {
      setShowPopper(false)
    }
  }

  // Defines action of popper on hover
  const onHoverAction = {
    onMouseEnter: () => setShowPopper(true),
    onMouseLeave: () => setShowPopper(false),
    onClick: () => setShowPopper(true),
    onBlur: () => setShowPopper(false)
  }
  const handleClick = (e) => {
    setShowPopper(false)
    e.stopPropagation()
  }
  return (
    <ThemeProvider theme={{ ...themeConfiguration }}>
      <PopperWrapper tabIndex={0} onKeyDown={handleKeyDown}>
        <PopperIcon
          data-tip
          data-for='tooltip'
          ref={iconRef}
          faIcon={faIcon}
          color={color}
          aria-label={stripHtml(ariaLabel)}
          aria-expanded={showPopper}
          {...onHoverAction}
        />
        {showPopper && (
          <PopperContainer
            name='popper-container'
            ref={popperRef}
            tabIndex={0}
            style={styles.popper}
            {...attributes.popper}
            {...onHoverAction}
            popperleftPosition={popperleftPosition}
            popperWidth={popperWidth}
          >
            {!isMobile && (
              <StyledArrow
                name='styled-arrow'
                placement='left'
                ref={setArrowRef}
                style={styles.arrow}
              />
            )}
            {isMobile && (
              <StyledArrow
                name='styled-arrow'
                placement='top'
                ref={mobileArrowRef}
                style={styles.arrow}
                leftAllign={arrowLeftPosition}
              />
            )}
            {tooltipHTML ? (
              <TooltipHTMLWrapper
                name='tool-tip-html-wrapper'
                html={tooltipHTML}
              />
            ) : (
              <TooltipText
                name='tool-tip-text'
                title={tooltipTitle}
                body={tooltipBody}
              />
            )}
            {isMobile && <CrossIcon onClick={handleClick} />}
          </PopperContainer>
        )}
      </PopperWrapper>
    </ThemeProvider>
  )
}

Tooltip.defaultProps = {
  ariaLabel: 'Toggle Tooltip',
  color: 'rgba(0, 51, 141, 1)',
  faIcon: 'fa fa-info-circle',
  tooltipTitle: mock.tooltipObj.title,
  tooltipBody: mock.tooltipObj.body,
  tooltipHTML: ''
}

Tooltip.propTypes = {
  /**
   * aria label of the tooltip
   */
  ariaLabel: PropTypes.string,
  /**
   * It is used to define color for the tooltip
   */
  color: PropTypes.string,
  /**
   * It is used to set the icon css class for tooltip.
   */
  faIcon: PropTypes.string,
  /**
   * It is used to set the tooltip title.
   */
  tooltipTitle: PropTypes.string,
  /**
   * It is used to set the tooltip body.
   */
  tooltipBody: PropTypes.string,
  /**
   * It is used to set Embedded HTML to the tooltip.
   */
  tooltipHTML: PropTypes.string
}

export default Tooltip
