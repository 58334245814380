import styled from 'styled-components'

const PopperContainer = styled.div`
  max-width: ${({ popperWidth, theme }) =>
    popperWidth || theme.popperWidth || '230px'};
  width: ${({ popperWidth, theme }) =>
    popperWidth || theme.popperWidth || '230px'};
  bottom: auto !important;
  outline: 0 !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: ${({ theme }) =>
    theme.tooltipBorderRadius ? theme.tooltipBorderRadius : '5px'};
  background-color: ${({ theme }) =>
    theme.toolTipBackground ? theme.toolTipBackground : 'white'};
  padding: 20px;
  z-index: 999999;
  @media (max-width: 728px) {
    top: 28px !important;
    transform: initial !important;
  }
  // only for portrait mobile view
  @media (min-width: 280px) and (max-width: 540px) {
    ${({ popperleftPosition }) =>
      `
      left:  ${popperleftPosition}px !important;
    `};
  }
`

const PopperIcon = styled.i.attrs((props) => ({
  className: props.faIcon ? props.faIcon : 'fa fa-info-circle'
}))`
  color: ${({ color }) => color};
`

const CrossIcon = styled.i.attrs(() => ({ className: 'fa fa-times fa-lg' }))`
  color: white;
  position: absolute;
  top: 20px;
  right: 10px;
  display: ${({ theme }) => (theme.showCloseToolTipIcon ? 'block' : 'none')};
`

const StyledArrow = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  &:after {
    position: absolute;
    width: 0;
    height: 0;
    content: '';
    pointer-events: none;
  }

  &:after {
    border: 8px solid transparent;
  }

  ${(props) =>
    props.placement === 'top' &&
    `
    top: -8px;
    left: ${`${props.leftAllign}px` || '0'};
  
    &:after {
      top: 0;
      left: 0;
      border-bottom-color: ${
        props.theme.toolTipBackground ? props.theme.toolTipBackground : 'white'
      };
      border-top: none;
    }
  `}
  ${(props) =>
    props.placement === 'left' &&
    `
  left: -8px;
  &:after {
    left: 0;
    top: 0; 
    border-right-color: ${
      props.theme.toolTipBackground ? props.theme.toolTipBackground : 'white'
    };
    border-left: none;
  }
`}
  ${(props) =>
    props.placement === 'right' &&
    `
  right: -8px;

  &:after {
    right: 0;
    top: 0;
    border-left-color: ${
      props.theme.toolTipBackground ? props.theme.toolTipBackground : 'white'
    };

    border-right: none;
  }
`}
  ${(props) =>
    props.placement === 'bottom' &&
    `
  bottom: -8px;

  &:after {
    right: 0;
    top: 0;
    border-top-color: ${
      props.theme.toolTipBackground ? props.theme.toolTipBackground : 'white'
    };

    border-bottom: none;
  }
`}
`

const StyledTooltipTitle = styled.p`
  margin: 0;
  color: #454545;
  font-size: 18px;
  font-weight: bold;
`

const StyledTooltipBody = styled.p`
  margin: 0;
  font-size: 13px;
  line-height: 19px;
  font: ${({ theme }) => theme.tooltipFont};
  color: ${({ theme }) =>
    theme.tooltipTextColor ? theme.tooltipTextColor : '#767676'};
`

const StyledHorizontalRule = styled.hr`
  border: none;
  height: 1px;
  background-color: #d7d7d7;
`

const PopperWrapper = styled.div`
  position: relative;
`

export {
  PopperContainer,
  PopperIcon,
  StyledArrow,
  StyledTooltipTitle,
  StyledTooltipBody,
  StyledHorizontalRule,
  PopperWrapper,
  CrossIcon
}
