import styled from 'styled-components'

const SwitchOn = styled.button`
  padding: 0.5em 0.7em;
  background: #fff;
  color: #767676;
  border: 1px solid #767676;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  &.active {
    background: #767676;
    color: #fff;
  }
`

const SwitchOff = styled.button`
  padding: 0.5em 0.7em;
  background: #fff;
  color: #767676;
  border: 1px solid #767676;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  &.active {
    background: #767676;
    color: #fff;
  }
`

const ToggleWrapper = styled.div`
  display: inline-flex;
`

export { SwitchOn, SwitchOff, ToggleWrapper }
