const colorScheme = {
  blue: {
    primaryBg: '#2865cc'
  },
  green: {
    primaryBg: '#0e7e75'
  },
  orange: {
    primaryBg: '#ff9a00'
  },
  red: {
    primaryBg: '#ed1c2f'
  },
  darkBlue: {
    primaryBg: '#345069'
  },
  fern: {
    primaryBg: '#52c262'
  },
  astronautBlue: {
    primaryBg: '#1f485b'
  },
  lifecoPrimaryLight: {
    primaryBg: '#4589FF'
  }
}

const themes = {
  blue: {
    fillBg: '#cccccc',
    checkedFg: '#ffffff',
    checkedBg: colorScheme.blue.primaryBg,
    bgColor: '#ffffff',
    fontColor: '#333333',
    selectedBgColor: colorScheme.blue.primaryBg,
    selectedFontColor: '#ffffff',
    border: '1px solid #2865cc',
    iconColor: colorScheme.blue.primaryBg,
    selectOptionBg: colorScheme.blue.primaryBg,
    focusColor: colorScheme.blue.primaryBg,
    rangeSlider: {
      selected: colorScheme.blue.primaryBg
    },
    toggleUncheckedBg: '#ccc'
  },
  green: {
    fillBg: '#cccccc',
    checkedFg: '#ffffff',
    checkedBg: colorScheme.green.primaryBg,
    bgColor: '#FFFFFF',
    fontColor: '#333333',
    selectedBgColor: colorScheme.green.primaryBg,
    selectedFontColor: '#FFFFFF',
    border: '1px solid #0d756d',
    iconColor: colorScheme.green.primaryBg,
    selectOptionBg: colorScheme.green.primaryBg,
    focusColor: colorScheme.green.primaryBg,
    rangeSlider: {
      selected: colorScheme.green.primaryBg
    },
    toggleUncheckedBg: '#ccc'
  },
  orange: {
    fillBg: '#cccccc',
    checkedFg: '#000000',
    checkedBg: colorScheme.orange.primaryBg,
    bgColor: '#000000',
    fontColor: '#333333',
    selectedBgColor: colorScheme.orange.primaryBg,
    selectedFontColor: '#000000',
    border: '1px solid #0d756d',
    iconColor: colorScheme.orange.primaryBg,
    selectOptionBg: colorScheme.orange.primaryBg,
    focusColor: colorScheme.orange.primaryBg,
    rangeSlider: {
      selected: colorScheme.orange.primaryBg
    },
    toggleUncheckedBg: '#ccc'
  },
  red: {
    fillBg: '#cccccc',
    checkedFg: '#ffffff',
    checkedBg: colorScheme.red.primaryBg,
    bgColor: '#FFFFFF',
    fontColor: '#333333',
    selectedBgColor: colorScheme.red.primaryBg,
    selectedFontColor: '#FFFFFF',
    border: '1px solid #ed1c2f',
    iconColor: colorScheme.red.primaryBg,
    selectOptionBg: colorScheme.red.primaryBg,
    focusColor: colorScheme.red.primaryBg,
    rangeSlider: {
      selected: colorScheme.red.primaryBg
    },
    toggleUncheckedBg: '#ccc'
  },
  fern: {
    fillBg: '#cccccc',
    checkedFg: '#000000',
    checkedBg: colorScheme.fern.primaryBg,
    bgColor: '#000000',
    fontColor: '#333333',
    selectedBgColor: colorScheme.fern.primaryBg,
    selectedFontColor: '#000000',
    border: '1px solid #0d756d',
    iconColor: colorScheme.fern.primaryBg,
    selectOptionBg: colorScheme.fern.primaryBg,
    focusColor: colorScheme.fern.primaryBg,
    rangeSlider: {
      selected: colorScheme.fern.primaryBg
    },
    toggleUncheckedBg: '#ccc'
  },
  astronautBlue: {
    fillBg: '#cccccc',
    checkedFg: '#ffffff',
    checkedBg: colorScheme.astronautBlue.primaryBg,
    bgColor: '#FFFFFF',
    fontColor: '#333333',
    selectedBgColor: colorScheme.astronautBlue.primaryBg,
    selectedFontColor: '#FFFFFF',
    border: '1px solid #0d756d',
    iconColor: colorScheme.astronautBlue.primaryBg,
    selectOptionBg: colorScheme.astronautBlue.primaryBg,
    focusColor: colorScheme.astronautBlue.primaryBg,
    rangeSlider: {
      selected: colorScheme.astronautBlue.primaryBg
    },
    toggleUncheckedBg: '#ccc'
  },
  darkBlue: {
    fillBg: '#cccccc',
    checkedFg: '#ffffff',
    checkedBg: colorScheme.darkBlue.primaryBg,
    bgColor: '#ffffff',
    fontColor: '#333333',
    selectedBgColor: colorScheme.darkBlue.primaryBg,
    selectedFontColor: '#ffffff',
    border: '1px solid #345069',
    iconColor: colorScheme.darkBlue.primaryBg,
    selectOptionBg: colorScheme.darkBlue.primaryBg,
    focusColor: colorScheme.darkBlue.primaryBg,
    rangeSlider: {
      selected: colorScheme.darkBlue.primaryBg
    },
    toggleUncheckedBg: '#ccc'
  },
  lifecoPrimaryLight: {
    fillBg: '#cccccc',
    checkedFg: '#ffffff',
    checkedBg: colorScheme.lifecoPrimaryLight.primaryBg,
    bgColor: '#ffffff',
    fontColor: '#333333',
    selectedBgColor: colorScheme.lifecoPrimaryLight.primaryBg,
    selectedFontColor: '#ffffff',
    border: '1px solid #2865cc',
    iconColor: colorScheme.lifecoPrimaryLight.primaryBg,
    selectOptionBg: colorScheme.lifecoPrimaryLight.primaryBg,
    focusColor: colorScheme.lifecoPrimaryLight.primaryBg,
    rangeSlider: {
      selected: colorScheme.lifecoPrimaryLight.primaryBg
    },
    toggleUncheckedBg: '#ccc'
  },
  disabled: {
    selectedBgColor: '#F7F7F7',
    fontColor: '#767676',
    border: '1px solid #cccccc',
    bgColor: '#767676',
    checkedBg: '#767676',
    rangeSlider: {
      selected: '#767676'
    },
    checkedFg: '#ffffff'
  },
  WCAGDisabled: {
    selectedBgColor: '#F3F6F9',
    fontColor: '#4E5969',
    border: '1px solid #4E5969'
  }
}

const inputWidth = {
  S: 110,
  M: 260,
  L: 360,
  XL: 400,
  XXL: 480
}

export { themes, inputWidth }
