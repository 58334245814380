export interface PostLoginRequest {
    code?: string,
    SAMLResponse?: string
}

export interface PostLoginResponse {
    code: number,
    data: PostData,
    errors?: string
}

export interface PostData {
    response: PostResponse
}

export interface PostResponse {
    accessToken: string,
    expiresAt: number,
    expiresIn: number,
    tokenType: string
}

export function postLoginResponseJson(json): PostLoginResponse {
    return {
        code: json.code,
        data: postData(json.data),
        errors: json.errors
    }
}

export function postData(postdata): PostData {
    let rtnData = null;
    if (postdata) {
        rtnData = {
            response: postResponse(postdata.response)
        };
    }
    return rtnData;
}

export function postResponse(response): PostResponse {
    return {
        accessToken: response.access_token,
        expiresAt: response.expires_at,
        expiresIn: response.expires_in,
        tokenType: response.token_type
    };
}

export enum IdpTokenReqParamKeyEnum {
    Code = "code",
    SamlResponse = "SAMLResponse"
}
