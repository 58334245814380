import styled from 'styled-components'

const ButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
  margin: 10px 0 20px;
`
const ActionButton = styled.div`
  width: 57px;
  ${({ theme: { backgroundImgSrc, border } }) =>
    `background-image: url(${backgroundImgSrc || ''});
     border: ${border || '0px'}};
   `}
  background-size: cover;

  &:focus {
    ${({ theme: { backgroundImgHoverSrc } }) =>
      `background-image: url(${backgroundImgHoverSrc || ''}); `}
    outline: none;
  }
`

export { ButtonWrapper, ActionButton }
