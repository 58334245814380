import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const FocusTrap = (props) => {
  const wrapperRef = useRef(null)
  const { excludedElementsSelectors, supportedElementsSelector } = props

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [wrapperRef])

  const getFocusableElements = () => {
    // getting the list of all focusable elements within the modal.
    let focusableEls
    if (props.modalType == 'bootstrap') {
      let modalDialog = document.querySelectorAll('[role="dialog"]')
      focusableEls = modalDialog[0].querySelectorAll(supportedElementsSelector)
    } else {
      focusableEls = wrapperRef.current.querySelectorAll(
        supportedElementsSelector
      )
    }
    focusableEls = Array.from(focusableEls)
    return focusableEls
  }

  const getCurrentFocusedElement = () => document.activeElement

  /**
   * Checking whether element is focusable
   * @param {Array} excludedElementsSelectors
   * @param {HTMLElement} element
   * @returns {Boolean}
   */
  const isValidFocusableElement = (excludedElementsSelectors, element) => {
    if (!excludedElementsSelectors.find((s) => element.matches(s))) {
      return true
    }
    return false
  }

  /**
   * Get the focusable element in the modal.
   * @param {boolean} moveBackward Representing focus to shift forwards or backwards.
   * @returns {HTMLElement} Focusable Element.
   */
  const calculateNextFocusableElement = (moveBackward) => {
    const focusableElements = getFocusableElements()
    const currentFocusableElementIndex =
      focusableElements.indexOf(getCurrentFocusedElement()) || 0
    let nextFocusableElementIndex = currentFocusableElementIndex

    if (moveBackward) {
      nextFocusableElementIndex -= 1
      if (nextFocusableElementIndex < 0) {
        nextFocusableElementIndex = focusableElements.length - 1
      }
    } else {
      nextFocusableElementIndex += 1
      if (nextFocusableElementIndex >= focusableElements.length) {
        nextFocusableElementIndex = 0
      }
    }
    const nextFocusableElement = focusableElements[nextFocusableElementIndex]

    // validating if element is focusable or not
    const isValidEl = isValidFocusableElement(
      excludedElementsSelectors,
      nextFocusableElement
    )

    if (isValidEl) {
      return nextFocusableElement
    } else {
      if (nextFocusableElementIndex === 0) {
        return focusableElements[nextFocusableElementIndex]
      }
      if (moveBackward) {
        // if we use Shift+tab decrementing nextFocusableElementIndex
        for (let i = nextFocusableElementIndex; i > 0; i--) {
          const element = focusableElements[i]
          const isValidEl = isValidFocusableElement(
            excludedElementsSelectors,
            element
          )
          if (isValidEl) {
            return element
          }
        }
      }
      for (
        let i = nextFocusableElementIndex;
        i < focusableElements.length;
        i++
      ) {
        const element = focusableElements[i]
        const isValid = isValidFocusableElement(
          excludedElementsSelectors,
          element
        )
        if (isValid) {
          return element
        }
        if (i === focusableElements.length - 1) {
          return focusableElements[0]
        }
      }
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      const elem = calculateNextFocusableElement(event.shiftKey)
      if (elem) {
        setTimeout(() => {
          elem.focus()
        }, 0)
      }
    }
  }

  return <div ref={wrapperRef}>{props.children}</div>
}

FocusTrap.defaultProps = {
  modalType: null,
  excludedElementsSelectors: ['[disabled]'],
  supportedElementsSelector:
    'a[href],area[href],input,button,select,textarea,iframe,object,embed,[tabindex="0"],[contentEditable=true]'
}

FocusTrap.propTypes = {
  children: PropTypes.node.isRequired,
  excludedElementsSelectors: PropTypes.array,
  supportedElementsSelector: PropTypes.string
}

export default FocusTrap
