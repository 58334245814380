import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Head, CloseIcon, Border, Message } from './styles'

const Alert = ({ type, title, message, color, fontSize }) => {
  const [hide, hideAlert] = useState(false)
  if (hide) {
    return null
  }
  return (
    <Wrapper name='wrapper' type={type}>
      <CloseIcon name='close-icon' onClick={() => hideAlert(true)}>
        x
      </CloseIcon>
      {title && (
        <Fragment>
          <Head name='head' color={color} fontSize={fontSize}>
            <h1>{title}</h1>
          </Head>
          <Border name='border' />
        </Fragment>
      )}
      <Message name='message' color={color} fontSize={fontSize}>
        {message}
      </Message>
    </Wrapper>
  )
}

Alert.propTypes = {
  /**
   * Determines the intent of showing the alert.
   */
  type: PropTypes.oneOf(['error', 'warning', 'info']).isRequired,
  /**
   * Title of the alert message.
   */
  title: PropTypes.string,
  /**
   * Description of the alert message.
   */
  message: PropTypes.string.isRequired
}

export default Alert
