import styled from 'styled-components'
import { themes } from '../../themes'

const Label = styled.label`
  display: flex;
  position: relative;
  ${({ theme: { columns } }) =>
    columns > 0 &&
    `
    flex-basis: calc((100%/${columns}) - 10%);
  `};
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &[disabled] {
    cursor: ${({ theme: { disabledPointer } }) => disabledPointer || 'default'};
    opacity: ${({ theme: { blurDisabledOption } }) =>
      blurDisabledOption ? 0.5 : 1};
  }
`

const CheckboxComponent = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  border: ${({ theme }) => theme.inputBorder};
  height: 0;
  width: 0;
  &:focus ~ span {
    outline: ${({ theme }) => theme.borderOnFocus};
    outline-offset: ${({ theme }) => theme.outlineOffset || '-2px'};
  }
  ${({ theme }) => theme.top && `top:${theme.top}; left:${theme.left}`}
`
const SpanComponent = styled.span`
  position: absolute;
  top: ${(props) => (props.alignTopWithLabel ? '0' : '50%')};
  transform: ${(props) =>
    props.alignTopWithLabel ? 'translateY(0%)' : 'translateY(-50%)'};
  left: 0;
  height: 22px;
  width: 22px;
  background-color: ${({ theme }) =>
    theme.shape === 'standard_squircle' ? 'transparent' : ' #eee'};
  border: ${({ theme }) =>
    theme.shape === 'standard_squircle' ? '1px solid #d7d7d7' : theme.border};
  ${(props) => props.disabled && `border: ${themes.disabled.selectedBgColor};`}
  ${({ theme }) =>
    (theme.shape === 'squircle' || theme.shape === 'standard_squircle') &&
    `
    border-radius: 3px;
  `}
  ${({ theme }) =>
    theme.bgColor &&
    `
    background-color : ${theme.bgColor};
  `}
  ${({ theme }) =>
    theme.borderRadius &&
    `
    border-radius: ${theme.borderRadius};
  `}
  ${({ theme }) =>
    theme.shape === 'cardcircle' &&
    `
    border-radius: 50px;
  `}
  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 6px;
    top: 2px;
    width: 7px;
    height: 12px;
    border: 0px solid white;
    border-width: 0px 3px 3px 0px;
    transform: rotate(45deg);
  }
  ${CheckboxComponent}:checked + & {
    border: ${({ theme }) => theme.border};
    background-color: ${({ theme }) => theme.checkedBg};
    &:after {
      display: block;
    }
  }
`

const LabelText = styled.span`
  padding-left: ${({ theme }) =>
    theme.shape === 'standard_squircle' ? 0 : '13px'};
  font-size: ${({ theme }) =>
    theme.shape === 'standard_squircle' ? '16px' : '14px'};
  color: ${({ theme }) => (theme.fontColor ? theme.fontColor : '#454545')};
  font: ${({ theme }) => theme.font};
  text-align: left;
  margin: 0;
  & > a {
    color: ${({ theme }) =>
      theme.anchorColor ? theme.anchorColor : '#007bff'};
`
const CardLabel = styled.div`
  padding-left: 13px;
  font-size: 12px;
  color: #5f5f5f;
  text-align: left;
  margin: 5px 0;
`
const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: ${({ theme }) =>
    theme.orientation === 'rows' ? 'row' : 'column'};
  margin: 0.5em 0;
  & ${Label} {
    margin: ${({ theme: { labelMargin } }) => labelMargin || '0.5em'};
  }
  & ${CardLabel} {
    margin: 0.5em;
  }
  ${CheckboxComponent}:checked + & {
    border: ${({ theme }) => theme.border};
    background-color: ${({ theme }) => theme.checkedBg};
    &:after {
      display: block;
    }
  }
  ${({ theme }) =>
    theme.orientation === 'rows' &&
    `
  flex-wrap: wrap;
 `}
`
const CardLabelWrapper = styled.label`
  display: block;
  position: relative;
  ${({ theme: { columns } }) =>
    columns > 0 &&
    `
    flex-basis: calc((100%/${columns}) - 10%);
  `};
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  border: ${({ theme }) => theme.border};
  & ${SpanComponent} {
    left: 15px;
    border: 1px solid #a6a6a6;
    background-color: #fff;
  }
  & ${LabelText} {
    font-size: 16px;
    position: relative;
    top: 5px;
    left: 30px;
    color: #2d2d2d;
  }
  & ${CardLabel} {
    font-size: 14px;
    position: relative;
    top: 3px;
    left: 24px;
    color: #838383;
  }
  ${(props) => !props.checked && `border: 1px solid #a6a6a6`};
`

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
`
export {
  Label,
  CardLabel,
  CardLabelWrapper,
  CheckboxComponent,
  SpanComponent,
  LabelText,
  CheckboxWrapper,
  LabelWrapper
}
