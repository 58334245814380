import YearDropdown from './year_dropdown'
import MonthDropdown from './month_dropdown'
import MonthYearDropdown from './month_year_dropdown'
import Month from './month'
import Time from './time'
import Year from './year'
import InputTime from './inputTime'
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import CalendarContainer from './calendar_container'
import {
  newDate,
  setMonth,
  getMonth,
  addMonths,
  subMonths,
  getStartOfWeek,
  getStartOfToday,
  addDays,
  formatDate,
  setYear,
  getYear,
  isBefore,
  addYears,
  subYears,
  isAfter,
  getFormattedWeekdayInLocale,
  getWeekdayShortInLocale,
  getWeekdayMinInLocale,
  isSameDay,
  monthDisabledBefore,
  monthDisabledAfter,
  yearDisabledBefore,
  yearDisabledAfter,
  getEffectiveMinDate,
  getEffectiveMaxDate,
  addZero
} from './date_utils'
import styles from './stylesheets/react-datepicker.module.css'

const DROPDOWN_FOCUS_CLASSNAMES = [
  styles['react-datepicker__year-select'],
  styles['react-datepicker__month-select'],
  styles['react-datepicker__month-year-select']
]

const isDropdownSelect = (element = {}) => {
  const classNames = (element.className || '').split(/\s+/)
  return DROPDOWN_FOCUS_CLASSNAMES.some(
    (testClassname) => classNames.indexOf(testClassname) >= 0
  )
}
const close_button = {
  padding: "10px",
  fontSize: "16px",
  color: "rgb(73,80,87)",
  border: "0",
  float: "right",
  margin: "10px",
  cursor: "pointer"
}
export default class Calendar extends React.Component {
  static get defaultProps() {
    return {
      onDropdownFocus: () => {},
      monthsShown: 1,
      monthSelectedIn: 0,
      forceShowMonthNavigation: false,
      timeCaption: 'Time',
      previousYearButtonLabel: 'Previous Year',
      nextYearButtonLabel: 'Next Year',
      previousMonthButtonLabel: 'Previous Month',
      nextMonthButtonLabel: 'Next Month',
      customTimeInput: null,
      showWCAGCloseBtn: true
    }
  }

  static propTypes = {
    adjustDateOnChange: PropTypes.bool,
    chooseDayAriaLabelPrefix: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node,
    container: PropTypes.func,
    dateFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
      .isRequired,
    dayClassName: PropTypes.func,
    weekDayClassName: PropTypes.func,
    disabledDayAriaLabelPrefix: PropTypes.string,
    monthClassName: PropTypes.func,
    timeClassName: PropTypes.func,
    disabledKeyboardNavigation: PropTypes.bool,
    dropdownMode: PropTypes.oneOf(['scroll', 'select']),
    endDate: PropTypes.instanceOf(Date),
    excludeDates: PropTypes.array,
    filterDate: PropTypes.func,
    fixedHeight: PropTypes.bool,
    formatWeekNumber: PropTypes.func,
    highlightDates: PropTypes.instanceOf(Map),
    includeDates: PropTypes.array,
    includeTimes: PropTypes.array,
    injectTimes: PropTypes.array,
    inline: PropTypes.bool,
    locale: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({ locale: PropTypes.object })
    ]),
    maxDate: PropTypes.instanceOf(Date),
    minDate: PropTypes.instanceOf(Date),
    monthsShown: PropTypes.number,
    monthSelectedIn: PropTypes.number,
    nextMonthAriaLabel: PropTypes.string,
    nextYearAriaLabel: PropTypes.string,
    onClickOutside: PropTypes.func.isRequired,
    onMonthChange: PropTypes.func,
    onYearChange: PropTypes.func,
    forceShowMonthNavigation: PropTypes.bool,
    onDropdownFocus: PropTypes.func,
    onSelect: PropTypes.func.isRequired,
    onWeekSelect: PropTypes.func,
    showTimeSelect: PropTypes.bool,
    showTimeInput: PropTypes.bool,
    showMonthYearPicker: PropTypes.bool,
    showFullMonthYearPicker: PropTypes.bool,
    showYearPicker: PropTypes.bool,
    showQuarterYearPicker: PropTypes.bool,
    showTimeSelectOnly: PropTypes.bool,
    timeFormat: PropTypes.string,
    timeIntervals: PropTypes.number,
    onTimeChange: PropTypes.func,
    timeInputLabel: PropTypes.string,
    minTime: PropTypes.instanceOf(Date),
    maxTime: PropTypes.instanceOf(Date),
    excludeTimes: PropTypes.array,
    timeCaption: PropTypes.string,
    openToDate: PropTypes.instanceOf(Date),
    peekNextMonth: PropTypes.bool,
    previousMonthAriaLabel: PropTypes.string,
    previousYearAriaLabel: PropTypes.string,
    scrollableYearDropdown: PropTypes.bool,
    scrollableMonthYearDropdown: PropTypes.bool,
    preSelection: PropTypes.instanceOf(Date),
    selected: PropTypes.instanceOf(Date),
    selectsEnd: PropTypes.bool,
    selectsStart: PropTypes.bool,
    showMonthDropdown: PropTypes.bool,
    showPreviousMonths: PropTypes.bool,
    showMonthYearDropdown: PropTypes.bool,
    showWeekNumbers: PropTypes.bool,
    showYearDropdown: PropTypes.bool,
    startDate: PropTypes.instanceOf(Date),
    todayButton: PropTypes.string,
    useWeekdaysShort: PropTypes.bool,
    formatWeekDay: PropTypes.func,
    withPortal: PropTypes.bool,
    weekLabel: PropTypes.string,
    yearDropdownItemNumber: PropTypes.number,
    setOpen: PropTypes.func,
    shouldCloseOnSelect: PropTypes.bool,
    useShortMonthInDropdown: PropTypes.bool,
    showDisabledMonthNavigation: PropTypes.bool,
    previousMonthButtonLabel: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
    ]),
    nextMonthButtonLabel: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
    ]),
    previousYearButtonLabel: PropTypes.string,
    nextYearButtonLabel: PropTypes.string,
    renderCustomHeader: PropTypes.func,
    renderDayContents: PropTypes.func,
    onDayMouseEnter: PropTypes.func,
    onMonthMouseLeave: PropTypes.func,
    showPopperArrow: PropTypes.bool,
    handleOnKeyDown: PropTypes.func,
    isInputFocused: PropTypes.bool,
    customTimeInput: PropTypes.element,
    weekAriaLabelPrefix: PropTypes.string,
    setPreSelection: PropTypes.func
  }

  constructor(props) {
    super(props)
    this.containerRef = React.createRef()

    this.state = {
      date: this.getDateInView(),
      selectingDate: null,
      monthContainer: null,
      monthView: false,
      yearView: false
    }
  }

  componentDidMount() {
    // monthContainer height is needed in time component
    // to determine the height for the ul in the time component
    // setState here so height is given after final component
    // layout is rendered
    if (this.props.showTimeSelect) {
      this.assignMonthContainer = (() => {
        this.setState({ monthContainer: this.monthContainer })
      })()
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.preSelection &&
      !isSameDay(this.props.preSelection, prevProps.preSelection)
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        date: this.props.preSelection
      })
    } else if (
      this.props.openToDate &&
      !isSameDay(this.props.openToDate, prevProps.openToDate)
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        date: this.props.openToDate
      })
    }
  }

  handleClickOutside = (event) => {
    this.props.onClickOutside(event)
  }

  setClickOutsideRef = () => {
    return this.containerRef.current
  }

  handleDropdownFocus = (event) => {
    if (isDropdownSelect(event.target)) {
      this.props.onDropdownFocus()
    }
  }

  getDateInView = () => {
    const { preSelection, selected, openToDate } = this.props
    const minDate = getEffectiveMinDate(this.props)
    const maxDate = getEffectiveMaxDate(this.props)
    const current = newDate()
    const initialDate = openToDate || selected || preSelection
    if (initialDate) {
      return initialDate
    } else {
      if (minDate && isBefore(current, minDate)) {
        return minDate
      } else if (maxDate && isAfter(current, maxDate)) {
        return maxDate
      }
    }
    return current
  }

  increaseMonth = () => {
    this.setState(
      ({ date }) => ({
        date: addMonths(date, 1)
      }),
      () => this.handleMonthChange(this.state.date)
    )
  }

  decreaseMonth = () => {
    this.setState(
      ({ date }) => ({
        date: subMonths(date, 1)
      }),
      () => this.handleMonthChange(this.state.date)
    )
  }

  handleDayClick = (day, event, monthSelectedIn) =>
    this.props.onSelect(day, event, monthSelectedIn)

  handleDayMouseEnter = (day) => {
    this.setState({ selectingDate: day })
    this.props.onDayMouseEnter && this.props.onDayMouseEnter(day)
  }

  handleMonthMouseLeave = () => {
    this.setState({ selectingDate: null })
    this.props.onMonthMouseLeave && this.props.onMonthMouseLeave()
  }

  handleYearChange = (date) => {
    if (this.props.onYearChange) {
      this.props.onYearChange(date)
    }
  }

  handleMonthChange = (date) => {
    if (this.props.onMonthChange) {
      this.props.onMonthChange(date)
    }
    if (this.props.adjustDateOnChange) {
      if (this.props.onSelect) {
        this.props.onSelect(date)
      }
      if (this.props.setOpen) {
        this.props.setOpen(true)
      }
    }

    this.props.setPreSelection && this.props.setPreSelection(date)
  }

  handleMonthYearChange = (date) => {
    this.handleYearChange(date)
    this.handleMonthChange(date)
  }

  changeYear = (year) => {
    this.setState(
      ({ date }) => ({
        date: setYear(date, year)
      }),
      () => this.handleYearChange(this.state.date)
    )
  }

  changeMonth = (month) => {
    this.setState(
      ({ date }) => ({
        date: setMonth(date, month)
      }),
      () => this.handleMonthChange(this.state.date)
    )
  }

  changeMonthYear = (monthYear) => {
    this.setState(
      ({ date }) => ({
        date: setYear(setMonth(date, getMonth(monthYear)), getYear(monthYear))
      }),
      () => this.handleMonthYearChange(this.state.date)
    )
  }

  header = (date = this.state.date) => {
    const startOfWeek = getStartOfWeek(date, this.props.locale)
    const dayNames = []
    if (this.props.showWeekNumbers) {
      dayNames.push(
        <div key='W' className={styles['react-datepicker__day-name']}>
          {this.props.weekLabel || '#'}
        </div>
      )
    }
    return dayNames.concat(
      [0, 1, 2, 3, 4, 5, 6].map((offset) => {
        let dayname = ''
        switch (offset) {
          case 0:
            dayname = 'Sunday'
            break

          case 1:
            dayname = 'Monday'
            break

          case 2:
            dayname = 'Tuesday'
            break

          case 3:
            dayname = 'Wednesday'
            break

          case 4:
            dayname = 'Thrusday'
            break

          case 5:
            dayname = 'Friday'
            break

          case 6:
            dayname = 'Saturday'
            break
        }
        const day = addDays(startOfWeek, offset)
        const weekDayName = this.formatWeekday(day, this.props.locale)

        const weekDayClassName = this.props.weekDayClassName
          ? this.props.weekDayClassName(day)
          : undefined

        return (
          <div
            key={offset}
            className={classnames(
              styles['react-datepicker__day-name'],
              weekDayClassName
            )}
            role="heading"
            aria-label={dayname}
          >
            {weekDayName}
          </div>
        )
      })
    )
  }

  formatWeekday = (day, locale) => {
    if (this.props.formatWeekDay) {
      return getFormattedWeekdayInLocale(day, this.props.formatWeekDay, locale)
    }
    return this.props.useWeekdaysShort
      ? getWeekdayShortInLocale(day, locale)
      : getWeekdayMinInLocale(day, locale)
  }

  decreaseYear = () => {
    this.setState(
      ({ date }) => ({
        date: subYears(date, this.props.showYearPicker ? 11 : 1)
      }),
      () => this.handleYearChange(this.state.date)
    )
  }

  renderPreviousButton = () => {
    if (
      this.props.renderCustomHeader ||
      this.state.yearView ||
      this.state.monthView ||
      this.props.showMonthYearPicker ||
      this.props.showYearPicker
    ) {
      return
    }

    const allPrevDaysDisabled = this.props.showMonthYearPicker
      ? yearDisabledBefore(this.state.date, this.props)
      : monthDisabledBefore(this.state.date, this.props)

    if (
      (!this.props.forceShowMonthNavigation &&
        !this.props.showDisabledMonthNavigation &&
        allPrevDaysDisabled) ||
      this.props.showTimeSelectOnly
    ) {
      return
    }

    const classes = [
      styles['react-datepicker__navigation'],
      styles['react-datepicker__navigation--previous'],
      styles.chevron,
      styles['rotate-left']
    ]

    let clickHandler = this.decreaseMonth

    if (
      this.props.showMonthYearPicker ||
      this.props.showQuarterYearPicker ||
      this.props.showYearPicker
    ) {
      clickHandler = this.decreaseYear
    }

    if (allPrevDaysDisabled && this.props.showDisabledMonthNavigation) {
      classes.push(styles['react-datepicker__navigation--previous--disabled'])
      clickHandler = null
    }

    const isForYear =
      this.props.showMonthYearPicker || this.props.showQuarterYearPicker

    const {
      previousMonthAriaLabel = 'Previous Month',
      previousYearAriaLabel = 'Previous Year'
    } = this.props

    return (
      <button
        type='button'
        className={classes.join(' ')}
        onClick={clickHandler}
        aria-label={isForYear ? previousYearAriaLabel : previousMonthAriaLabel}
      />
    )
  }

  increaseYear = () => {
    this.setState(
      ({ date }) => ({
        date: addYears(date, this.props.showYearPicker ? 11 : 1)
      }),
      () => this.handleYearChange(this.state.date)
    )
  }

  renderNextButton = () => {
    if (
      this.props.renderCustomHeader ||
      this.state.yearView ||
      this.state.monthView ||
      this.props.showMonthYearPicker ||
      this.props.showYearPicker
    ) {
      return
    }

    const allNextDaysDisabled = this.props.showMonthYearPicker
      ? yearDisabledAfter(this.state.date, this.props)
      : monthDisabledAfter(this.state.date, this.props)

    if (
      (!this.props.forceShowMonthNavigation &&
        !this.props.showDisabledMonthNavigation &&
        allNextDaysDisabled) ||
      this.props.showTimeSelectOnly
    ) {
      return
    }

    const classes = [
      styles['react-datepicker__navigation'],
      styles['react-datepicker__navigation--next'],
      styles.chevron,
      styles['rotate-right']
    ]
    if (this.props.showTimeSelect) {
      classes.push(styles['react-datepicker__navigation--next--with-time'])
    }
    if (this.props.todayButton) {
      classes.push(
        styles['react-datepicker__navigation--next--with-today-button']
      )
    }

    let clickHandler = this.increaseMonth

    if (
      this.props.showMonthYearPicker ||
      this.props.showQuarterYearPicker ||
      this.props.showYearPicker
    ) {
      clickHandler = this.increaseYear
    }

    if (allNextDaysDisabled && this.props.showDisabledMonthNavigation) {
      classes.push(styles['react-datepicker__navigation--next--disabled'])
      clickHandler = null
    }

    const isForYear =
      this.props.showMonthYearPicker || this.props.showQuarterYearPicker

    const {
      nextMonthAriaLabel = 'Next Month',
      nextYearAriaLabel = 'Next Year'
    } = this.props

    return (
      <button
        type='button'
        className={classes.join(' ')}
        onClick={clickHandler}
        aria-label={isForYear ? nextYearAriaLabel : nextMonthAriaLabel}
      />
    )
  }

  toggleYearView = () => {
    this.props.setYearView(!this.state.yearView)
    this.setState({
      yearView: !this.state.yearView
    })
  }

  toggleMonthView = () => {
    this.props.setMonthView(!this.state.monthView)
    this.setState({
      monthView: !this.state.monthView
    })
  }

  renderCurrentMonth = (date = this.state.date) => {
    const classes = [styles['react-datepicker__current-month']]

    if (this.props.showYearDropdown) {
      classes.push(styles['react-datepicker__current-month--hasYearDropdown'])
    }
    if (this.props.showMonthDropdown) {
      classes.push(styles['react-datepicker__current-month--hasMonthDropdown'])
    }
    if (this.props.showMonthYearDropdown) {
      classes.push(
        styles['react-datepicker__current-month--hasMonthYearDropdown']
      )
    }
    return (
      <div className={classes.join(' ')}>
        {formatDate(date, this.props.dateFormat, this.props.locale)}
      </div>
    )
  }

  renderYearDropdown = (overrideHide = false) => {
    if (!this.props.showYearDropdown || overrideHide) {
      return
    }
    return (
      <YearDropdown
        adjustDateOnChange={this.props.adjustDateOnChange}
        date={this.state.date}
        onSelect={this.props.onSelect}
        setOpen={this.props.setOpen}
        dropdownMode={this.props.dropdownMode}
        onChange={this.changeYear}
        minDate={this.props.minDate}
        maxDate={this.props.maxDate}
        year={getYear(this.state.date)}
        scrollableYearDropdown={this.props.scrollableYearDropdown}
        yearDropdownItemNumber={this.props.yearDropdownItemNumber}
      />
    )
  }

  renderMonthDropdown = (overrideHide = false) => {
    if (!this.props.showMonthDropdown || overrideHide) {
      return
    }
    return (
      <MonthDropdown
        dropdownMode={this.props.dropdownMode}
        locale={this.props.locale}
        onChange={this.changeMonth}
        month={getMonth(this.state.date)}
        useShortMonthInDropdown={this.props.useShortMonthInDropdown}
      />
    )
  }

  renderMonthYearDropdown = (overrideHide = false) => {
    // if (!this.props.showMonthYearDropdown || overrideHide) {
    //   return
    // }
    return (
      <MonthYearDropdown
        dropdownMode={this.props.dropdownMode}
        locale={this.props.locale}
        dateFormat={this.props.dateFormat}
        onChange={this.changeMonthYear}
        minDate={this.props.minDate}
        maxDate={this.props.maxDate}
        date={this.state.date}
        toggleYearView={this.toggleYearView}
        toggleMonthView={this.toggleMonthView}
        monthView={this.state.monthView}
        yearView={this.state.yearView}
        showMonthYearPicker={this.props.showMonthYearPicker}
        scrollableMonthYearDropdown={this.props.scrollableMonthYearDropdown}
      />
    )
  }

  renderTodayButton = () => {
    if (!this.props.todayButton || this.props.showTimeSelectOnly) {
      return
    }
    return (
      <div
        className={styles['react-datepicker__today-button']}
        onClick={(e) => this.props.onSelect(getStartOfToday(), e)}
      >
        {this.props.todayButton}
      </div>
    )
  }

  renderDefaultHeader = ({ monthDate, i }) => (
    <div className={styles['react-datepicker__header']}>
      {/* {this.renderCurrentMonth(monthDate)} */}
      <div
        className={`${styles['react-datepicker__header__dropdown']} ${
          styles[
            `react-datepicker__header__dropdown--${this.props.dropdownMode}`
          ]
        }`}
        onFocus={this.handleDropdownFocus}
      >
        {this.renderMonthDropdown(i !== 0)}
        {this.renderMonthYearDropdown(i !== 0)}
        {this.renderYearDropdown(i !== 0)}
      </div>
      {!this.state.yearView &&
        !this.state.monthView &&
        !this.props.showMonthYearPicker &&
        !this.props.showYearPicker && (
          <div className={styles['react-datepicker__day-names']}>
            {this.header(monthDate)}
          </div>
        )}
    </div>
  )

  renderCustomHeader = (headerArgs = {}) => {
    const { monthDate, i } = headerArgs

    if (i !== 0 && i !== undefined) {
      return null
    }

    const prevMonthButtonDisabled = monthDisabledBefore(
      this.state.date,
      this.props
    )

    const nextMonthButtonDisabled = monthDisabledAfter(
      this.state.date,
      this.props
    )

    const prevYearButtonDisabled = yearDisabledBefore(
      this.state.date,
      this.props
    )

    const nextYearButtonDisabled = yearDisabledAfter(
      this.state.date,
      this.props
    )

    const showDayNames =
      !this.props.showMonthYearPicker &&
      !this.props.showQuarterYearPicker &&
      !this.props.showYearPicker

    return (
      <div
        className={`${styles['react-datepicker__header']} ${styles['react-datepicker__header--custom']}`}
        onFocus={this.props.onDropdownFocus}
      >
        {this.props.renderCustomHeader({
          ...this.state,
          changeMonth: this.changeMonth,
          changeYear: this.changeYear,
          decreaseMonth: this.decreaseMonth,
          increaseMonth: this.increaseMonth,
          decreaseYear: this.decreaseYear,
          increaseYear: this.increaseYear,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
          prevYearButtonDisabled,
          nextYearButtonDisabled
        })}
        {showDayNames && (
          <div className={styles['react-datepicker__day-names']}>
            {this.header(monthDate)}
          </div>
        )}
      </div>
    )
  }

  renderYearHeader = () => {
    return (
      <div
        className={`${styles['react-datepicker__header']} ${styles['react-datepicker-year-header']}`}
      >
        {this.props.showYearPicker && getYear(this.state.date)}
      </div>
    )
  }

  renderHeader = (headerArgs) => {
    switch (true) {
      case this.props.renderCustomHeader !== undefined:
        return this.renderCustomHeader(headerArgs)
      case this.props.showQuarterYearPicker || this.props.showYearPicker:
        return this.renderYearHeader(headerArgs)
      default:
        return this.renderDefaultHeader(headerArgs)
    }
  }

  renderMonths = () => {
    if (
      this.props.showTimeSelectOnly ||
      this.props.showYearPicker ||
      this.state.yearView
    ) {
      return
    }

    var monthList = []
    var monthsToSubtract = this.props.showPreviousMonths
      ? this.props.monthsShown - 1
      : 0
    var fromMonthDate = subMonths(this.state.date, monthsToSubtract)
    for (var i = 0; i < this.props.monthsShown; ++i) {
      var monthsToAdd = i - this.props.monthSelectedIn
      var monthDate = addMonths(fromMonthDate, monthsToAdd)
      var monthKey = `month-${i}`
      monthList.push(
        <div
          key={monthKey}
          ref={(div) => {
            this.monthContainer = div
          }}
          className={styles['react-datepicker__month-container']}
        >
          {this.renderHeader({ monthDate, i })}
          <Month
            chooseDayAriaLabelPrefix={this.props.chooseDayAriaLabelPrefix}
            disabledDayAriaLabelPrefix={this.props.disabledDayAriaLabelPrefix}
            weekAriaLabelPrefix={this.props.weekAriaLabelPrefix}
            onChange={this.changeMonthYear}
            day={monthDate}
            dayClassName={this.props.dayClassName}
            monthClassName={this.props.monthClassName}
            onDayClick={this.handleDayClick}
            handleOnKeyDown={this.props.handleOnKeyDown}
            onDayMouseEnter={this.handleDayMouseEnter}
            onMouseLeave={this.handleMonthMouseLeave}
            onWeekSelect={this.props.onWeekSelect}
            orderInDisplay={i}
            formatWeekNumber={this.props.formatWeekNumber}
            locale={this.props.locale}
            minDate={this.props.minDate}
            maxDate={this.props.maxDate}
            excludeDates={this.props.excludeDates}
            highlightDates={this.props.highlightDates}
            selectingDate={this.state.selectingDate}
            includeDates={this.props.includeDates}
            inline={this.props.inline}
            fixedHeight={this.props.fixedHeight}
            filterDate={this.props.filterDate}
            preSelection={this.props.preSelection}
            selected={this.props.selected}
            selectsStart={this.props.selectsStart}
            selectsEnd={this.props.selectsEnd}
            showWeekNumbers={this.props.showWeekNumbers}
            startDate={this.props.startDate}
            endDate={this.props.endDate}
            peekNextMonth={this.props.peekNextMonth}
            setOpen={this.props.setOpen}
            shouldCloseOnSelect={this.props.shouldCloseOnSelect}
            renderDayContents={this.props.renderDayContents}
            disabledKeyboardNavigation={this.props.disabledKeyboardNavigation}
            showMonthYearPicker={this.props.showMonthYearPicker}
            showFullMonthYearPicker={this.props.showFullMonthYearPicker}
            showYearPicker={this.props.showYearPicker}
            showQuarterYearPicker={this.props.showQuarterYearPicker}
            isInputFocused={this.props.isInputFocused}
            containerRef={this.containerRef}
            monthView={this.state.monthView}
            toggleMonthView={this.toggleMonthView}
          />
        </div>
      )
    }
    return monthList
  }

  renderYears = () => {
    if (this.props.showTimeSelectOnly) {
      return
    }
    if (this.props.showYearPicker || this.state.yearView) {
      return (
        <div className={styles['react-datepicker__year']}>
          {this.renderHeader(this.state.date, 0)}
          <Year
            onDayClick={this.handleDayClick}
            date={this.state.date}
            minDate={this.props.minDate}
            maxDate={this.props.maxDate}
            yearView={this.state.yearView}
            toggleMonthView={this.toggleMonthView}
            toggleYearView={this.toggleYearView}
          />
        </div>
      )
    }
  }

  renderTimeSection = () => {
    if (
      this.props.showTimeSelect &&
      (this.state.monthContainer || this.props.showTimeSelectOnly)
    ) {
      return (
        <Time
          selected={this.props.selected}
          openToDate={this.props.openToDate}
          onChange={this.props.onTimeChange}
          timeClassName={this.props.timeClassName}
          format={this.props.timeFormat}
          includeTimes={this.props.includeTimes}
          intervals={this.props.timeIntervals}
          minTime={this.props.minTime}
          maxTime={this.props.maxTime}
          excludeTimes={this.props.excludeTimes}
          timeCaption={this.props.timeCaption}
          todayButton={this.props.todayButton}
          showMonthDropdown={this.props.showMonthDropdown}
          showMonthYearDropdown={this.props.showMonthYearDropdown}
          showYearDropdown={this.props.showYearDropdown}
          withPortal={this.props.withPortal}
          monthRef={this.state.monthContainer}
          injectTimes={this.props.injectTimes}
          locale={this.props.locale}
        />
      )
    }
  }

  renderInputTimeSection = () => {
    const time = new Date(this.props.selected)
    const timeString = `${addZero(time.getHours())}:${addZero(
      time.getMinutes()
    )}`
    if (this.props.showTimeInput) {
      return (
        <InputTime
          timeString={timeString}
          timeInputLabel={this.props.timeInputLabel}
          onChange={this.props.onTimeChange}
          customTimeInput={this.props.customTimeInput}
        />
      )
    }
  }

  renderWCAGCloseButton = () => {
    return (
      <button
        type='button'
        onClick={this.handleClickOutside}
        aria-label='close'
        tabIndex={0}
        className="close_button"
        style={close_button}
      >
        Close
      </button>
    )
  }

  render() {
    const Container = this.props.container || CalendarContainer
    return (
      <div ref={this.containerRef}>
        <Container
          className={classnames(
            styles['react-datepicker'],
            this.props.className,
            {
              [styles['react-datepicker--time-only']]: this.props
                .showTimeSelectOnly
            }
          )}
          showPopperArrow={this.props.showPopperArrow}
        >
          {this.renderPreviousButton()}
          {this.renderNextButton()}
          {this.renderMonths()}
          {this.renderYears()}
          {this.renderTodayButton()}
          {this.renderTimeSection()}
          {this.renderInputTimeSection()}
          {this.props.children}
          {this.props.showWCAGCloseBtn && this.renderWCAGCloseButton()}
        </Container>
      </div>
    )
  }
}
