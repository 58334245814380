import React, { useState, useEffect, useRef } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import IMask from 'imask'
import { IMaskInput } from 'react-imask'
import { ThemeProvider } from 'styled-components'
import utils from './utils'
import { validateInnerHTML, useDocumentWidth, stripHtml } from '../../utils'
import {
  Label,
  Wrapper,
  InputBody,
  LabelWrapper,
  HelperText,
  ErrorMessage,
  ContentWrapper,
  InputComponents,
  TooltipWrapper
} from '../../styles'
import {
  StyledDatePicker,
  StyledSpan,
  StyledIcon,
  DateErrorContainer
} from './styles'
import { Tooltip } from '../Tooltip'
import CalendarContainer from '../../packages/react-datepicker/calendar_container'
import { themes } from '../../themes'

const DatePicker = (props) => {
  const {
    floatingLabel,
    labelText,
    corner,
    labelMargin,
    id,
    inputRef,
    labelPosition,
    placeholder,
    labelIsHTML,
    disabled,
    response,
    autoFormat,
    onChange,
    onBlur,
    onValueChangeRaw,
    minValue,
    maxValue,
    popperMinValue,
    popperMaxValue,
    required,
    showCalendarPopper,
    showCalendarIcon,
    guide,
    customError,
    responseFormat,
    placeholderChar,
    hasTooltip,
    tooltipTitle,
    tooltipBody,
    tooltipHTML,
    color,
    width,
    calendarIconPlacement,
    themeConfiguration,
    errorMessageColor,
    errorFontSize,
    helperText,
    spanText,
    labelStyles,
    inputStyles,
    isOptional,
    isDateChange = false,
    trackCalendarPopper,
    isDatePrefilled,
    isAsterisk,
    maskOverwrite,
    ...otherProps
  } = props
  // Error Handling states
  const { error: e = false, error_message: em = '' } = customError
  const [hasError, setError] = useState(e)
  const [errorMsg, setErrorMsg] = useState(em)
  const [openCalendar, setOpenCalendar] = useState(false)
  const createErrorObject = (err, errorMsg) => {
    return { error: err, error_message: errorMsg }
  }

  // Get document width (reactive to window resizing).
  const docWidth = useDocumentWidth()
  let exactWidth = ''
  if (themeConfiguration && themeConfiguration.exactWidth) {
    exactWidth = themeConfiguration.exactWidth[width]
  }

  // Response Format Value
  const responseFormatValue = responseFormat

  // Calender Popper Format Value
  const { value: formatValue } = autoFormat

  // Picker Format Value
  const pickerDateFormat = utils.formatPickerValue(formatValue)

  // Formats date response
  const responseDate = utils.formatDate(response, responseFormatValue)
  const currentDateValue = useRef(responseDate)
  const [dateValue, setDateValue] = useState(responseDate)
  const [isDateValueFromKeyboard, setIsDateValueFromKeyboard] = useState(false)
  const hasFocus = useRef(false)
  const datePickerRef = useRef()
  const calendarClick = useRef({
    date: false,
    month: false,
    year: false
  })
  const initialRender = useRef(true)

  useEffect(() => {
    // Not to run on initial render.
    if (initialRender.current) {
      initialRender.current = false
      return
    }

    if (!dateValue) return

    const errorObj = handleError(dateValue)
    onBlur(dateValue, errorObj)
  }, [minValue.value, maxValue.value])

  useEffect(() => {
    setIsDateValueFromKeyboard(!isDatePrefilled)
  }, [isDatePrefilled])

  useEffect(() => {
    const {
      date: isDateClick,
      month: isMonthClick,
      year: isYearClick
    } = calendarClick.current
    const date = currentDateValue.current
    if (
      trackCalendarPopper &&
      !openCalendar &&
      (isYearClick || isMonthClick) &&
      !isDateClick
    ) {
      const errorObj = handleError(date)
      onChange(
        utils.responseDateFormat(date, formatValue, responseFormatValue),
        errorObj
      )
    }
  }, [openCalendar])

  if (dateValue?.toString() !== responseDate?.toString()) {
    if (!isDateValueFromKeyboard && isDatePrefilled) {
      setDateValue(responseDate)
    }
  }

  maxValue.error_message = (id && id.includes('GIWLMemberDOB')) ? autoFormat.error_message : `Date should be less than ${moment().format('MM/DD/YYYY')}.`;
  minValue.error_message = (id && id.includes('GIWLMemberDOB')) ? autoFormat.error_message : `Date should be greater than 01/01/1919.`;
  // Formats min and max data values
  const { value: minDateValue } = minValue
  const minDate = utils.formatDate(minDateValue, formatValue)
  const { value: maxDateValue } = maxValue
  const maxDate = utils.formatDate(maxDateValue, formatValue)

  // Formats popper min and max Date values
  let popperMinDate = minDate
  let popperMaxDate = maxDate
  if (popperMinValue && popperMaxValue) {
    popperMinDate = utils.formatDate(popperMinValue, formatValue)
    popperMaxDate = utils.formatDate(popperMaxValue, formatValue)
  }

  const getErrorObject = (date) => {
    return utils.validateDateInput(props, date, formatValue)
  }

  // Handles date error messages and error state
  const handleError = (dateValue) => {
    const { error, errorMessage } = getErrorObject(dateValue)
    if (error) {
      setError(true)
      setErrorMsg(errorMessage)
      return createErrorObject(error, errorMessage)
    }
    setError(false)
    setErrorMsg('')
    return createErrorObject(error, errorMessage)
  }

  // Formats response date according to formatValue
  const [inputValue, setInputValue] = useState(
    utils.responseDateFormat(response, responseFormatValue, formatValue)
  )
  const [hasContent, setContent] = useState(!!response)
  // Handles date changes
  const handleChange = (date, event) => {
    const { target } = event
    if (target && (!isNaN(target.innerHTML) || !isNaN(target.id))) {
      calendarClick.current = {
        date: target.innerHTML < 32 && target.innerHTML > 0,
        // eslint-disable-next-line no-use-before-define
        month: !isDateClick && target.id < 12 && target.id >= 0,
        year: target.innerHTML > 32
      }
    }

    const {
      date: isDateClick,
      month: isMonthClick,
      year: isYearClick
    } = calendarClick.current

    setIsDateValueFromKeyboard(!isDateClick)
    const formattedDate = utils.formatDate(date, formatValue)
    // If date is of type moment or current date value and changed date value
    // are same, return.
    const errorObj = handleError(date)
    if (
      moment.isMoment(date) ||
      (formattedDate &&
        currentDateValue.current &&
        utils.compareDates(formattedDate, currentDateValue.current))
    ) {
      if (isDateChange && isDateClick) {
        onChange(
          utils.responseDateFormat(date, formatValue, responseFormatValue),
          errorObj,
          isDateClick
        )
      } else if (
        isDateClick ||
        (event && event.target && event.target.innerHTML && isMonthYearFormat)
      ) {
        setOpenCalendar(false)
      }
      return
    }
    const { error } = getErrorObject(date)
    if (error || guide) {
      setInputValue(date)
    }
    setContent(!!date)
    setDateValue(formattedDate)
    // check if clicked on date for default format (or) month when format is MM/YYYY
    const isMonthYearFormatClick =
      event &&
      event.target &&
      event.target.innerHTML &&
      isMonthYearFormat &&
      isMonthClick
    if (isDateClick || isMonthYearFormatClick) {
      setOpenCalendar(false)
    }
    currentDateValue.current = formattedDate
    if (!isDateChange) {
      onChange(
        utils.responseDateFormat(date, formatValue, responseFormatValue),
        errorObj,
        isMonthYearFormat ? !isMonthClick : isDateClick
      )
    } else if (
      isDateChange &&
      ((!isYearClick && !isMonthClick) || isMonthYearFormat)
    ) {
      onChange(
        utils.responseDateFormat(date, formatValue, responseFormatValue),
        errorObj,
        isDateClick
      )
    }
  }

  const handleFocus = (e) => {
    hasFocus.current = true
    setOpenCalendar(false)
    if (props.onFocus) {
      props.onFocus(e)
    }
  }
  const handleBlur = (e) => {
    hasFocus.current = false
    const { value } = e.target
    const { error } = getErrorObject(value)
    const errorObj = createErrorObject(hasError, errorMsg)
    if (formatValue && value && onBlur) {
      if (formatValue !== value && formatValue.length === value.length) {
        if (!guide) handleChange(value, e)
        if (!error) {
          onBlur(
            utils.responseDateFormat(
              dateValue,
              formatValue,
              responseFormatValue
            ),
            errorObj
          )
        }
      } else if (formatValue !== value) {
        // onBlur should always fire if available in props
        let errObject = errorObj
        if (hasError && isOptional) {
          // updating error state to false onBlur, if the date field is optional
          setError(false)
          setErrorMsg('')
          errObject = createErrorObject(false, '')
        }
        onBlur(guide ? value : '', errObject)
      }
    } else {
      onBlur('', errorObj)
    }
  }

  // Handles data raw value changes
  const handleValueChangeRaw = (event) => {
    const dateValue = event.target.value
    setContent(!!dateValue)
    handleError(dateValue)
    onValueChangeRaw(dateValue)
  }

  const handleCalendarIconClick = (event) => {
    datePickerRef.current.setOpen(true)
    const calendarState = openCalendar
    event.stopPropagation()
    setOpenCalendar(!calendarState)
  }

  const handleKeyDown = (event) => {
    const eventKey = event.key
    if (eventKey === 'Enter' || eventKey === 'Escape') {
      setOpenCalendar(false)
    }
  }

  const handleCalendarIconKeyDown = (event) => {
    const eventKey = event.key
    const calendarState = openCalendar
    if (eventKey === 'Enter') {
      datePickerRef.current.setOpen(true)
      setOpenCalendar(!calendarState)
    } else if (eventKey === 'Escape') {
      setOpenCalendar(false)
    }
  }

  const handleCalendarAwayClick = () => {
    setOpenCalendar(false)
  }

  // Returns true if popper is of month year format
  const isMonthYearFormat = utils.checkMonthYearFormat(formatValue)

  // Returns true if popper is of year format
  const isYearFormat = utils.checkYearFormat(formatValue)

  // Placeholder related variables
  const hasPlaceholder = true

  // Guide related Masked Props
  const maskedProps = useRef({})
  const guideMaskedProps = (guide) => {
    if (guide) {
      maskedProps.current = {
        lazy: false,
        overwrite: maskOverwrite !== undefined ? maskOverwrite : true,
        onComplete: handleChange
      }
    }
  }

  useEffect(() => {
    guideMaskedProps(guide)
  }, [])

  // Defines blocks for iMask library
  const iMaskBlocks = {
    YYYY: {
      mask: IMask.MaskedRange,
      placeholderChar: placeholderChar || 'Y',
      maxLength: 4,
      from: 1900,
      to: 2099
    },
    YY: {
      mask: IMask.MaskedRange,
      placeholderChar: placeholderChar || 'Y',
      maxLength: 2,
      from: utils.getShortYear(minDate),
      to: utils.getShortYear(maxDate)
    },
    MM: {
      mask: IMask.MaskedRange,
      placeholderChar: placeholderChar || 'M',
      maxLength: 2,
      from: 1,
      to: 12
    },
    DD: {
      mask: IMask.MaskedRange,
      placeholderChar: placeholderChar || 'D',
      maxLength: 2,
      from: 1,
      to: 31
    }
  }

  // Custom Masked Input defined for react-datepicker
  // More Info - https://imask.js.org/guide.html#masked-date
  const MaskedInput = (
    <IMaskInput
      name='imask-input'
      mask={Date}
      inputRef={(input) => {
        if (inputRef) inputRef(input)
      }}
      unmask={false}
      pattern={formatValue}
      format={(date) => moment(date).format(formatValue)}
      parse={(str) => moment(str, formatValue)}
      blocks={iMaskBlocks}
      {...maskedProps.current}
    />
  )

  const customErrorContainer = ({ className, children }) => {
    return (
      <CalendarContainer name='calendar-container' className={className}>
        {hasError && errorMsg && (
          <DateErrorContainer
            name='date-error-container'
            errorMessageColor={errorMessageColor}
          >
            <StyledIcon
              name='styled-icon'
              className='fa fa-exclamation-circle fa-lg'
            />
            {errorMsg}
          </DateErrorContainer>
        )}
        <div style={{ position: 'relative' }}>{children}</div>
      </CalendarContainer>
    )
  }

  // Inserts min max date values to react-datepicker library
  let minmaxDateValues = {}
  if (showCalendarPopper !== false) {
    minmaxDateValues = {
      minDate: popperMinDate,
      maxDate: popperMaxDate
    }
  }
  const inlineTooltip = hasTooltip && !floatingLabel && labelPosition === 'top'

  // Remove this when theme is passed from all the clients
  const theme = themeConfiguration || themes[color]

  // ARIA specific props
  let ariaProps = {
    'aria-label': stripHtml(labelText),
    'aria-required': required && required.value
  }
  const ariaDescibebyErrorId = `${id}-error`
  if (hasError) {
    ariaProps = {
      ...ariaProps,
      'aria-invalid': hasError,
      'aria-describedby': ariaDescibebyErrorId
    }
  }

  const showHelperText = helperText && !hasError
  const HelperTextComponent = (
    <HelperText
      id={ariaDescibebyErrorId}
      data-testid='helper-text'
      aria-live='polite'
    >
      {helperText}
    </HelperText>
  )

  const ErrorComponent = (
    <ErrorMessage
      id={ariaDescibebyErrorId}
      data-testid='error-message'
      aria-live='polite'
    >
      {errorMsg}
    </ErrorMessage>
  )

  const _labelStyles = labelStyles || {}

  return (
    <ThemeProvider
      theme={{
        hasError,
        floatingLabel,
        labelPosition,
        corner,
        labelMargin,
        hasPlaceholder,
        color,
        width: exactWidth || width,
        hasContent: hasContent || response || hasFocus.current,
        calendarIconPlacement,
        spanText,
        inputStyles,
        errorMessageColor,
        errorFontSize,
        disabled,
        ...theme
      }}
    >
      <Wrapper name='wrappper'>
        <ContentWrapper name='content-wrapper'>
          <InputBody data-testid='input-body' docWidth={docWidth}>
            <InputComponents
              id={`date-picker-field-${id}`}
              showCalendarPopper
              name='input-components'
              docWidth={docWidth}
            >
              <StyledDatePicker
                name='styled-date-picker'
                id={id}
                autoComplete='off'
                ariaProps={ariaProps}
                customInput={MaskedInput}
                customInputRef={inputRef}
                disabled={disabled}
                dateFormat={pickerDateFormat}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChangeRaw={handleValueChangeRaw}
                placeholderText={placeholder}
                selected={dateValue}
                value={guide && inputValue}
                onKeyDown={handleKeyDown}
                onSelect={handleError}
                formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
                showPopperArrow={false}
                peekNextMonth={false}
                showMonthYearDropdown
                showMonthYearPicker={isMonthYearFormat}
                showYearPicker={isYearFormat}
                open={!disabled && openCalendar}
                onClickOutside={handleCalendarAwayClick}
                calendarContainer={customErrorContainer}
                ref={datePickerRef}
                {...minmaxDateValues}
                {...otherProps}
              />
              {showCalendarIcon && (
                <StyledSpan
                  role='application'
                  name='styled-span'
                  aria-label='Calendar view date-picker'
                  aria-describedby={id + 'calendarAccessibilty'}
                  tabIndex='0'
                  onKeyDown={handleCalendarIconKeyDown}
                  onClick={handleCalendarIconClick}
                >
                  <StyledIcon isDate className='fa fa-calendar fa-lg' />
                </StyledSpan>
              )}
            </InputComponents>
            <LabelWrapper name='label-wrapper' id={`date-picker-label-${id}`}>
              <Label
                htmlFor={id}
                data-testid='input-label'
                {..._labelStyles}
                isAsterisk={isAsterisk}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: validateInnerHTML(labelText)
                  }}
                />
              </Label>
              {inlineTooltip && (
                <TooltipWrapper name='tool-tip-wrapper' position='inline'>
                  <Tooltip
                    tooltipTitle={tooltipTitle}
                    tooltipBody={tooltipBody}
                    tooltipHTML={tooltipHTML}
                  />
                </TooltipWrapper>
              )}
            </LabelWrapper>
            {hasTooltip && !inlineTooltip && (
              <TooltipWrapper name='tooltip-wrapper'>
                <Tooltip
                  name='tool-tip'
                  tooltipTitle={tooltipTitle}
                  tooltipBody={tooltipBody}
                  tooltipHTML={tooltipHTML}
                />
              </TooltipWrapper>
            )}
          </InputBody>
        </ContentWrapper>
        {/* <ErrorMessage id={ariaDescibebyErrorId} data-testid='error-message'>
          {errorMsg}
        </ErrorMessage> */}
        {showHelperText ? HelperTextComponent : ErrorComponent}
      </Wrapper>
      <p id={id + 'calendarAccessibilty'} style={{ display: 'none' }}>
        Press Enter to open calendar. Press home and end buttons for year
        navigation. Press pageDown and pageUp buttons for month navigation.
        Press arrows for day navigation.
      </p>
    </ThemeProvider>
  )
}

DatePicker.defaultProps = {
  labelText: 'Date of Birth',
  floatingLabel: true,
  labelPosition: 'top',
  disabled: false,
  response: '',
  responseFormat: 'YYYY-MM-DD',
  required: {
    value: true,
    error_message: 'This is a required field.'
  },
  minValue: {
    value: '01/01/1919',
    error_message: 'Date should be greater than 01/01/1919.'
  },
  maxValue: {
    value: moment().format('MM/DD/YYYY'),
    error_message: `Date should be less than ${moment().format('MM/DD/YYYY')}.`
  },
  autoFormat: {
    type: 'date',
    value: 'MM/DD/YYYY',
    error_message: ''
  },
  popperMinValue: '01/01/1919',
  popperMaxValue: moment().format('MM/DD/YYYY'),
  showCalendarIcon: true,
  customError: {},
  guide: false,
  color: 'blue',
  width: 'M',
  corner: 'round',
  labelMargin: 'min',
  labelIsHTML: false,
  calendarIconPlacement: 'right',
  isDatePrefilled: false,
  isAsterisk: false,
  trackCalendarPopper: false
}

DatePicker.propTypes = {
  /**
   * `id` is required for datepicker field.
   */
  id: PropTypes.string.isRequired,
  /**
   * Ref for DatePicker.
   */
  inputRef: PropTypes.object,
  /**
   * Default value send to the datepicker field.
   */
  response: PropTypes.string.isRequired,
  /**
   * Determines if datpicker field is disabled or not.
   */
  disabled: PropTypes.bool,
  /**
   * Determines the behavior of label.
   */
  floatingLabel: PropTypes.bool,
  /**
   * When label is not floating, then position of label could either be
   * `top` or `left`. If `floatingLabel` is true, then labelPosition is ignored.
   */
  labelPosition: PropTypes.oneOf(['top', 'left']),
  /**
   * The text to be displayed for the datepicker field.
   */
  labelText: PropTypes.string.isRequired,
  /**
   * Placeholder text for the datepicker field.
   */
  placeholder: PropTypes.string.isRequired,
  /**
   * Placeholder char value (limited to single character), used if `guide` is true.
   */
  placeholderChar: PropTypes.string,
  /**
   * Determines if popper is closed by default (Only false value should be passed).
   */
  showCalendarPopper: PropTypes.bool,
  /**
   * A datepicker change handler to exchange value entered by the end user.
   */
  onChange: PropTypes.func.isRequired,
  /**
   * A datepicker input field change handler to exchange raw input values entered by the end user.
   */
  onValueChangeRaw: PropTypes.func.isRequired,
  /**
   * Determines if the calendar Font-Awesome Icon is show or not
   */
  showCalendarIcon: PropTypes.bool,
  /**
   * Guide provides date placeholder character overwrite feature.
   */
  guide: PropTypes.bool,
  /**
   * Response format required by backend to parse date value.
   */
  responseFormat: PropTypes.string,
  /**
   * An object determining if datepicker value is required or not and
   * an error message associated with the validation.
   */
  required: PropTypes.shape({
    value: PropTypes.bool.isRequired,
    error_message: PropTypes.string
  }),
  /**
   * An object containing maximum date value allowed in the datepicker field and
   * an error message associated with the validation.
   */
  maxValue: PropTypes.shape({
    value: PropTypes.string,
    error_message: PropTypes.string
  }),
  /**
   * An object containing minimum date value allowed in the datepicker field and
   * an error message associated with the validation.
   */
  minValue: PropTypes.shape({
    value: PropTypes.string,
    error_message: PropTypes.string
  }),
  /**
   * Min Date Value shown on Calendar Popper.
   */
  popperMinValue: PropTypes.string,
  /**
   * Max Date Value shown on Calendar Popper.
   */
  popperMaxValue: PropTypes.string,
  /**
   * Auto format is used to determine the datepicker `picker` format (like Month/Date/Year or Month/Year),
   * and allows the date to be formatted in given format.
   */
  autoFormat: PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.string.isRequired,
    error_message: PropTypes.string
  }),
  /**
   * If present, initialise the error state with this object.
   */
  customError: PropTypes.shape({
    error: PropTypes.bool.isRequired,
    error_message: PropTypes.string.isRequired
  }),
  /**
   * Determines the color theme of datepicker field.
   */
  color: PropTypes.string,
  /**
   * Determines the appearance of edge of the box.
   */
  corner: PropTypes.oneOf(['round', 'sharp']),
  /**
   * It refers to the gap between label and the datepicker field. It is applicable only
   * for fixed label when label appears on the left side.
   */
  labelMargin: PropTypes.oneOf(['min', 'max']),
  /**
   * Indicate if the label text is html text or plain text.
   */
  labelIsHTML: PropTypes.bool,
  /**
   * Width of the input field
   */
  width: PropTypes.oneOf(['S', 'M', 'L', 'XL']),
  /**
   * If `calendarIconPlacement` is left, then calendar icon will be appearing in left side. bydefault it will be in right side.
   */
  calendarIconPlacement: PropTypes.oneOf(['left', 'right']),
  /**
   * Theme configuration object
   */
  themeConfiguration: PropTypes.object,
  /**
   * Determines the color of error message.
   */
  errorMessageColor: PropTypes.string,
  /**
   * Indicate if the input is required or not.
   */
  isAsterisk: PropTypes.bool,
  /**
   * Indicate if the calendar popper closed action is to be tracked to trigger onChange handler.
   */
  trackCalendarPopper: PropTypes.bool
}

export default DatePicker
