/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Scrollbars } from 'react-custom-scrollbars'
import { getYear } from './date_utils'
import * as utils from './date_utils'
import styles from './stylesheets/react-datepicker.module.css'


export default class Year extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      current_year: utils.getYear(this.props.date)
    }
  }
  scrollbars = React.createRef()
  yearRef = React.createRef()
  static propTypes = {
    date: PropTypes.instanceOf(Date),
    onDayClick: PropTypes.func
  }

  componentDidMount() {
    this.scrollbars.current.scrollToBottom()
    const { date} = this.props;
    const selected_year = document.getElementById(utils.getYear(date));
    if(selected_year){
      selected_year.focus();
    }
    this.yearRef.current = selected_year;
  }

  handleYearClick = (day, event) => {
    if (this.props.onDayClick) {
      this.props.onDayClick(day, event)
    }
  }

  onYearClick = (e, y) => {
    this.handleYearClick(utils.setYear(this.props.date, y), e)
  }
  handleKeyDown = (e, y) => {
    if (e.key === 'ArrowLeft') {
      y = this.getYearOnKeyHandle(y, 'left');
      this.yearRef.current = y;
      this.setState({
        current_year : parseInt(this.yearRef.current)
      })
    }
    if (e.key === 'ArrowRight') {
      y = this.getYearOnKeyHandle(y, 'right');
      this.yearRef.current = y;
      this.setState({
        current_year : parseInt(this.yearRef.current)
      })
    }
    if(e.keyCode === 13){
      this.onYearClick(e, y)
      this.props.toggleYearView()
      this.props.toggleMonthView()
    }

  }
  getYearOnKeyHandle = (y, key) => {
    if(y){
      let selected_year;
      if(key === 'left')
        selected_year = document.getElementById(y).previousSibling;
      
      if(key === 'right')
        selected_year = document.getElementById(y).nextSibling;

      if(selected_year){
        selected_year.focus()
        return selected_year.innerHTML;
      }
    }
    
  }
  getYearClassNames = (y) => {
    const { date } = this.props
    return classnames(
      styles['react-datepicker__year-container-text'],
      styles[`react-datepicker__year-${y}`],
      {
        [styles['react-datepicker__year--selected']]: utils.getYear(date) === y
      }
    )
  }
  render() {
    const { minDate, maxDate, date } = this.props
    const current_year = this.state.current_year!==null && !isNaN(this.state.current_year) ? this.state.current_year : utils.getYear(date);
    const yearsList = []
    for (let y = getYear(minDate), i = 0; y <= getYear(maxDate); y++, i++) {
      yearsList.push(
        <div
          onClick={(ev) => {
            this.onYearClick(ev, y)
            this.props.toggleYearView()
            this.props.toggleMonthView()
          }}
          className={
            current_year  === y
            ? `${styles['react-datepicker__year-container-text']} ${styles['react-datepicker__year--selected']}`
            : styles['react-datepicker__year-container-text']
          }
          key={y}
          ref={this.yearRef}
          tabIndex={y === this.state.current_year ? 0 : -1}
          onKeyDown={(ev) => {
            this.handleKeyDown(ev, current_year)
          }}
          id={y}
        >
          {y}
        </div>
      )
    }
    return (
      <div className={styles['react-datepicker__year-container']}>
        <Scrollbars ref={this.scrollbars} style={{ width: 250, height: 150 }}>
          {yearsList}
        </Scrollbars>
      </div>
    )
  }
}