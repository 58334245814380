import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Label,
  CardLabel,
  CardLabelWrapper,
  CheckboxComponent,
  SpanComponent,
  LabelText,
  LabelWrapper
} from './styles'
import { parseHtml } from '../../utils'
import Tooltip from '../Tooltip/Tooltip'

const Checkbox = (props) => {
  const {
    label,
    label1,
    shape,
    id,
    inputRef,
    handleCheckboxChange,
    checked,
    isControlledInput,
    disabled,
    handlePopup,
    alignTopWithLabel,
    tooltipProp
  } = props

  let checkboxProps = {}
  if (isControlledInput) {
    checkboxProps = { checked }
  } else {
    checkboxProps = { defaultChecked: checked }
  }
  const [isChecked, setIsChecked] = useState(checked)
  return shape !== 'cardcircle' ? (
    <LabelWrapper>
      <Label name='label' for={id} disabled={disabled}>
        <LabelText name='label-text'>{parseHtml(label, handlePopup)}</LabelText>
        <CheckboxComponent
          name='check-box-component'
          id={id}
          ref={inputRef}
          type='checkbox'
          onChange={handleCheckboxChange}
          disabled={disabled}
          {...checkboxProps}
        />
        <SpanComponent
          name='span-component'
          disabled={disabled}
          alignTopWithLabel={alignTopWithLabel}
        />
      </Label>
      {tooltipProp && <Tooltip {...tooltipProp} />}
    </LabelWrapper>
  ) : (
    <CardLabelWrapper
      name='card-label-wrapper'
      checked={isControlledInput ? checked : isChecked}
    >
      <LabelText name='label-text'>{label}</LabelText>
      <CardLabel name='card-label'>{label1}</CardLabel>
      <CheckboxComponent
        name='check-box-component'
        id={id}
        ref={inputRef}
        type='checkbox'
        disabled={disabled}
        onChange={(e) => {
          handleCheckboxChange(e)
          setIsChecked(!isChecked)
        }}
        {...checkboxProps}
      />
      <SpanComponent
        name='span-component'
        disabled={disabled}
        alignTopWithLabel={alignTopWithLabel}
      />
    </CardLabelWrapper>
  )
}

Checkbox.defaultProps = {
  /**
   * Default status of checkbox.
   */
  checked: false,
  isControlledInput: false,
  disabled: false
}

Checkbox.propTypes = {
  /**
   * Label of the checkbox.
   */
  label: PropTypes.string.isRequired,
  /**
   * An id for the checkbox.
   */
  id: PropTypes.string.isRequired,
  /**
   * Ref for Checkboxes.
   */
  inputRef: PropTypes.object,
  /**
   * Function triggered on changing checkbox.
   */
  handleCheckboxChange: PropTypes.func.isRequired,
  /**
   * Default status of checkbox.
   */
  checked: PropTypes.oneOf([true, false]),
  /**
   * isControlledInput if true, makes the checkbox a controlled component
   */
  isControlledInput: PropTypes.bool,
  /**
   * disables checkbox
   */
  disabled: PropTypes.bool
}

export default Checkbox
