import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import {
  Wrapper,
  InputBody,
  ContentWrapper,
  InputComponents,
  LabelWrapper,
  Label,
  ErrorMessage,
  TooltipWrapper
} from '../../styles'
import { PreText, PostText } from '../TextInput/styles'
import utils from '../TextInput/utils'
import { validateInnerHTML, useDocumentWidth, stripHtml } from '../../utils'
import { Tooltip } from '../Tooltip'
import { themes } from '../../themes'

const NumberInput = (props) => {
  const {
    floatingLabel,
    labelPosition,
    corner,
    labelMargin,
    labelText,
    labelIsHTML,
    id,
    inputRef,
    precision,
    step,
    metric,
    value,
    pattern,
    onChange,
    minValue,
    maxValue,
    minLength,
    maxLength,
    placeholder,
    hidePlaceholder,
    width,
    color,
    required,
    hasTooltip,
    tooltipTitle,
    tooltipBody,
    tooltipHTML,
    tooltipWidth,
    customError,
    themeConfiguration,
    spanText,
    labelStyles,
    errorMessageColor,
    errorFontSize,
    preTextLeftPadding,
    restrictInputValue,
    allowedMaxValue,
    inputStyles,
    isAsterisk,
    disabled,
    ...otherProps
  } = props

  const inpPlaceholder = hidePlaceholder ? '' : placeholder

  const { error: e = false, error_message: em = '' } = customError
  const [hasContent, setContent] = useState(!!value)
  const [hasError, setError] = useState(e)
  const [errorMsg, setErrorMsg] = useState(em)

  // Get document width (reactive to window resizing).
  const docWidth = useDocumentWidth()

  const { errorTextHeight } = themeConfiguration || {}

  const handleChange = (e) => {
    const { value } = e.target
    setContent(!!value)

    if (value) {
      const val = utils.handleBigInt(value, precision && precision.value)
      if (!isNaN(val)) {
        const { error: pError, errorMessage: pErrMsg } = utils.precisionCheck(
          precision,
          val
        )
        const { error: numError, errorMessage: numErrMsg } =
          utils.validateNumber(props, val)
        const absValueLen = Math.abs(value).toString().length
        const { error: lenError, errorMessage: lenErrMsg } =
          utils.validateLength(minLength, maxLength, absValueLen)
        const error = pError || numError || lenError
        const errorMessage = pErrMsg || numErrMsg || lenErrMsg
        setError(error)
        setErrorMsg(errorMessage)

        // allow last decimal (dot)
        const dot =
          !step && precision && precision.value && value.slice(-1) === '.'
            ? '.'
            : ''
        e.target.value = `${val}${dot}`
        onChange(val, {
          error,
          error_message: errorMessage
        })
      } else {
        e.target.value = ''
      }
    } else {
      const { error: reqError, errorMessage: reqErrMsg } = utils.validateInput(
        props,
        value
      )

      const error = reqError
      const errorMessage = reqErrMsg

      setError(error)
      setErrorMsg(errorMessage)

      onChange(value, {
        error,
        error_message: errorMessage
      })
    }
  }

  const handleKeyDown = (e) => {
    if (restrictInputValue) {
      const value = allowedMaxValue && allowedMaxValue.value
      const actualValue = e.target.value + e.key
      if (
        Number(actualValue) > value ||
        (Number(actualValue) === 0 && Number(minValue.value) === 1)
      ) {
        e.preventDefault()
        return
      }
    }
    if (props.onKeyDown) {
      props.onKeyDown(e)
    }
    if (step) {
      if (!utils.allowNumbers(e)) {
        e.preventDefault()
      }
    }
  }

  const type = step ? 'number' : 'text'
  const { preText, postText } = utils.getMetrics(metric)

  const inlineTooltip = hasTooltip && !floatingLabel && labelPosition === 'top'

  // Remove this when theme is passed from all the clients
  const theme = themeConfiguration || themes[color]
  // ARIA specific props
  let ariaProps = {
    'aria-label': stripHtml(labelText),
    'aria-required': required && required.value
  }
  const ariaDescibebyErrorId = `${id}-error`
  if (hasError) {
    ariaProps = {
      ...ariaProps,
      'aria-invalid': hasError,
      'aria-describedby': ariaDescibebyErrorId
    }
  }

  const _labelStyles = labelStyles || {}

  const { labelFontWeight } = _labelStyles || {}

  return (
    <ThemeProvider
      theme={{
        floatingLabel,
        labelPosition,
        corner,
        labelMargin,
        preText,
        postText,
        hasContent,
        hasError,
        width,
        color,
        errorMessageColor,
        errorTextHeight,
        preTextLeftPadding,
        spanText,
        inputStyles,
        errorFontSize,
        hasTooltip,
        labelFontWeight,
        disabled,
        ...theme
      }}
    >
      <Wrapper name='wrapper'>
        <ContentWrapper name='content-wrapper'>
          <InputBody data-testid='input-body' docWidth={docWidth}>
            <InputComponents
              id={`number-input-field-${id}`}
              name='input-components'
              docWidth={docWidth}
            >
              {preText && <PreText data-testid='pre-text'>{preText}</PreText>}
              <input
                id={id}
                ref={inputRef}
                name={id}
                data-error={hasError}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                placeholder={inpPlaceholder}
                type={type}
                step={step}
                pattern={pattern && pattern.value}
                data-testid='number-input'
                defaultValue={value}
                disabled={disabled}
                {...(maxLength.restrict ? { maxLength: maxLength.value } : {})}
                {...otherProps}
                {...ariaProps}
              />
              {postText && (
                <PostText data-testid='post-text'>{postText}</PostText>
              )}
            </InputComponents>
            <LabelWrapper id={`number-input-label-${id}`} name='label-wrapper'>
              {floatingLabel && preText && <PreText>{preText}</PreText>}
              <Label
                htmlFor={id}
                data-testid='input-label'
                {..._labelStyles}
                isAsterisk={isAsterisk}
              >
                {labelIsHTML ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: validateInnerHTML(labelText, isAsterisk)
                    }}
                  />
                ) : (
                  labelText
                )}
                {!labelText && <br />}
              </Label>
              {inlineTooltip && (
                <TooltipWrapper name='tool-tip-wrapper' position='inline'>
                  <Tooltip
                    tooltipTitle={tooltipTitle}
                    tooltipBody={tooltipBody}
                    tooltipHTML={tooltipHTML}
                    popperWidth={tooltipWidth}
                  />
                </TooltipWrapper>
              )}
            </LabelWrapper>
            {hasTooltip && !inlineTooltip && (
              <TooltipWrapper name='tool-tip-wrapper'>
                <Tooltip
                  name='tool-tip'
                  tooltipTitle={tooltipTitle}
                  tooltipBody={tooltipBody}
                  tooltipHTML={tooltipHTML}
                  popperWidth={tooltipWidth}
                />
              </TooltipWrapper>
            )}
          </InputBody>
        </ContentWrapper>
        <ErrorMessage
          id={ariaDescibebyErrorId}
          data-testid='error-message'
          aria-live='polite'
        >
          {errorMsg}
        </ErrorMessage>
      </Wrapper>
    </ThemeProvider>
  )
}

NumberInput.defaultProps = {
  floatingLabel: true,
  labelPosition: 'left',
  labelIsHTML: false,
  corner: 'round',
  precision: {},
  step: 0,
  metric: {
    display_position: 'prefix',
    value: '',
    postfix: ''
  },
  minValue: {},
  maxValue: {},
  minLength: {},
  maxLength: {},
  width: 'L',
  color: 'blue',
  required: {},
  customError: {},
  hidePlaceholder: false,
  isAsterisk: false
}

NumberInput.propTypes = {
  /**
   * An ID for the component
   */
  id: PropTypes.string.isRequired,
  /**
   * Ref for Number Input.
   */
  inputRef: PropTypes.object,
  /**
   * Default value
   */
  value: PropTypes.string,
  /**
   * A label for the input.
   */
  labelText: PropTypes.string.isRequired,
  /**
   * A placeholder for the input.
   */
  placeholder: PropTypes.string.isRequired,
  /**
   * To hide Placeholder text for the input field.
   */
  hidePlaceholder: PropTypes.bool,
  /**
   * Indicates behaviour of label.
   */
  floatingLabel: PropTypes.bool,
  /**
   * Position of label, works only if floatingLabel is set to `false`.
   */
  labelPosition: PropTypes.oneOf(['left', 'top']),
  /**
   * Indicates if label is an HTML element.
   */
  labelIsHTML: PropTypes.bool,
  /**
   * Determines the appearance of edge of the box.
   */
  corner: PropTypes.oneOf(['round', 'sharp']),
  /**
   * It refers to the gap between label and the input field. It is applicable only
   * for fixed label when label appears on the left side.
   */
  labelMargin: PropTypes.oneOf(['min', 'max']),
  /**
   * Precision allows to restrict user input, default is `0`.
   */
  precision: PropTypes.shape({
    value: PropTypes.number.isRequired,
    error_message: PropTypes.string.isRequired
  }),
  /**
   * If `step` is a valid number, the input type turns to number and
   * it displays a spinner in the input box.
   */
  step: PropTypes.number,
  /**
   * Shows pre and post text, useful for showing metrics, thus named.
   */
  metric: PropTypes.shape({
    display_position: PropTypes.oneOf(['prefix', 'postfix']),
    value: PropTypes.string.isRequired,
    postfix: PropTypes.string
  }),
  /**
   * On change handler
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Minimum accepted value
   */
  minValue: PropTypes.shape({
    value: PropTypes.number,
    error_message: PropTypes.string
  }),
  /**
   * Minimum accepted value
   */
  maxValue: PropTypes.shape({
    value: PropTypes.number,
    error_message: PropTypes.string
  }),
  /**
   * An object containing maximum number of character allowed in the input and
   * an error message associated with the validation.
   * pass restrict as true to limit max characters in the input
   */
  maxLength: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    error_message: PropTypes.string,
    restrict: PropTypes.bool
  }),
  /**
   * An object containing maximum number of character allowed in the input and
   * an error message associated with the validation.
   */
  minLength: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    error_message: PropTypes.string
  }),
  /**
   * Width of the input field
   */
  width: PropTypes.oneOf(['S', 'M', 'L', 'XL']),
  /**
   * An object to specifiy whether the input is a required field or not.
   */
  required: PropTypes.shape({
    value: PropTypes.bool.isRequired,
    error_message: PropTypes.string.isRequired
  }),
  /**
   * If present, initialise the error state with this object.
   */
  customError: PropTypes.shape({
    error: PropTypes.bool.isRequired,
    error_message: PropTypes.string.isRequired
  }),
  /**
   * Theme color.
   */
  color: PropTypes.string,
  /**
   * Theme configuration object
   */
  themeConfiguration: PropTypes.object,

  onKeyDown: PropTypes.func,
  /**
   * Determines the color of error message.
   */
  errorMessageColor: PropTypes.string,
  /**
   * applies padding-left when labelPosition is top & floatingLabel is false
   */
  preTextLeftPadding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Indicate if the input is required or not.
   */
  isAsterisk: PropTypes.bool
}

export default NumberInput
