/**
 * Function to throw error messages
 * @param error Error object
 * @param prefixData text need to be displayed when throwing an error this can be empty string
 * @throws Error object
 */
import {Buffer} from "buffer";

export function ThrowAPIError(error, prefixData) {
    if (error.response) {
        throw new Error(prefixData + JSON.stringify(error.response));
    } else if (error.request) {
        // The request was made but no response was received
        throw new Error(prefixData + JSON.stringify(error.request));
    } else {
        // Something happened in setting up the request that triggered an Error
        throw new Error(prefixData + JSON.stringify(error.message));
    }
}

/**
 * Function to store value in localstorage for a specific amount of time
 * @param key identifier for which the value is assigned
 * @param value value that need to be assign to a key
 * @param ttl add time for current time (value should be in milliseconds)
 */
export function setItemWithExpiry(key, value, ttl): void {
    const now = new Date()

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
        value: value,
        expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item))
}

/**
 * Function to get key value based on expiry
 * @param key identifier to get a value
 */
export function getItemWithExpiry(key) {
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
        return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key)
        return null
    }
    return item.value
}

/**
 * Function to delete the localstorage value based on key
 * @param key identifier to get a value
 */
export function forceDeleteItem(key) {
    localStorage.removeItem(key)
}

/**
 * Function to get the cookies and get the cookie value based on cookie name
 * @param cookieName identifier to get a value
 */
 export function getSamlIdpTokenFromCookie (cookieName: string){
    const cookieValue = document.cookie
    .split('; ')
    .find(row => row.startsWith(`${cookieName}=`))
    ?.split('=')[1];

    return cookieValue;
  }

 /**
 * Function to execute the form saml url
 * @param form identifier as a form value
 */
  export function executeSamlFormUrl(form: string) {
    let samlForm = JSON.parse(JSON.stringify(form));
    let samlFormDiv: HTMLDivElement = document.createElement('div'); // adding a dummy div node
    samlFormDiv.innerHTML = samlForm;
    samlFormDiv.style.visibility = "hidden";
    document.body.appendChild(samlFormDiv);
    let samlFormButton = document.getElementById('SAMLSubmitButton');
    samlFormButton.click();
  }

/**
 * Given a string, encode it to Base64
 * @param stringToBeEncoded
 * @return Base64 encoded string
 */
export function getBase64EncodedString(stringToBeEncoded: string): string {
    return !!stringToBeEncoded ?
      Buffer.from(stringToBeEncoded).toString('base64') : null;
  }
