import styled from 'styled-components'

const RangeSliderRoot = styled.div`
  ${({ decrementButton, incrementButton }) => {
    return decrementButton || incrementButton
      ? `display:flex;
        justify-content:space-between;
        align-items:center;
        .rangeslider{
          width:100%;
          margin-left:20px;
          margin-right:20px;
        }
      `
      : 'display:block;'
  }}
  .rangeslider__handle {
    background: ${({ theme }) => theme.selected};
  }
  .rangeslider__fill {
    background-color: ${({ theme }) => theme.sliderFillColor || 'initial'};
  }
`

export { RangeSliderRoot }
