import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Slider from './Core'
import { RangeSliderRoot } from './styles'
import { themes } from '../../themes'
import { Button } from '../Button'

const RangeSlider = (props) => {
  const [value, setValue] = useState(550)
  const {
    defaultSelected,
    color,
    minValue,
    maxValue,
    step,
    onChange,
    prefix,
    postfix,
    currency,
    showOnlyStepMultiples,
    hasCurrencyAbbrivation,
    showValueToLeft,
    showValueToTop,
    haveCenterValueItem,
    themeConfiguration,
    decrementButton,
    incrementButton,
    customLabelStyles,
    displayType,
    donotShowValueOnSlider,
    sliderLabels,
    formatSliderValue,
    isReadOnly,
    isEditable,
    isQuestionsLoading,
    markingArray
  } = props

  useEffect(() => {
    if (defaultSelected) {
      setValue(defaultSelected)
    } else {
      setValue(minValue)
    }
  }, [defaultSelected, minValue])

  //Labels are added horizontally so insertion order is important when sortLabelValues prop is set to false
  const getHorizontalLabels = (sliderLabels) => {
    const horizontalLabels = {};
    horizontalLabels[minValue] = `${prefix}${minValue}${postfix}`;
    for(let key in sliderLabels){
      horizontalLabels[key] = `${prefix}${sliderLabels[key]}${postfix}`;
    }
    horizontalLabels[maxValue] = `${prefix}${maxValue}${postfix}`;
    return horizontalLabels;
  }
  const handleChangeComplete = () => {
    // check for the correct step value
    const rem = value % step
    if (rem === 0) {
      onChange(value)
      return
    }
    // round figure to next possible value.
    const roundValue = value + (step - rem)
    setValue(roundValue)
    onChange(roundValue)
  }
  const handleDecrementButtonCLick = () => {
    if(!props.isQuestionsLoading){
      if (value === minValue) return

      setValue(value - step)
      onChange(value - step)
    }
  }
  const handleIncrementButtonClick = () => {
    if(!props.isQuestionsLoading){
      if (value === maxValue) return

      setValue(value + step)
      onChange(value + step)
    }
  }

  const theme = themeConfiguration || themes[color].rangeSlider

  return (
    <RangeSliderRoot
      name='range-slider-root'
      theme={theme}
      decrementButton={decrementButton}
      incrementButton={incrementButton}
    >
      {decrementButton && (
        <Button
          label={decrementButton.label}
          handleButtonClick={handleDecrementButtonCLick}
          tabIndex={1}
          width={decrementButton.width}
          themeConfiguration={decrementButton.themeConfiguration}
          type='filled'
          marginRight={decrementButton.marginRight}
          disabled={!isEditable && isReadOnly}
        />
      )}
      <Slider
        name='slider'
        inputRef={props.inputRef}
        descriptionId={props.descriptionId}
        min={minValue}
        max={maxValue}
        step={5}
        stepFromBackEnd={step}
        showOnlyStepMultiples={showOnlyStepMultiples}
        value={value}
        tooltip
        prefix={prefix}
        postfix={postfix}
        onChange={(v, e, handlekey) => {
          setValue(v)
          if (handlekey) {
            onChange(v)
          }
        }}
        hasCurrencyAbbrivation={hasCurrencyAbbrivation}
        showValueToLeft={showValueToLeft}
        showValueToTop={showValueToTop}
        haveCenterValueItem={haveCenterValueItem}
        currency={currency}
        labels={getHorizontalLabels(sliderLabels)}
        onChangeComplete={handleChangeComplete}
        customLabelStyles={customLabelStyles}
        displayType={displayType}
        donotShowValueOnSlider={donotShowValueOnSlider}
        sortLabelValues={false}
        formatSliderValue={formatSliderValue}
        disabled={!isEditable && isReadOnly}
        isQuestionsLoading={isQuestionsLoading}
        markingArray={markingArray}
      />
      {incrementButton && (
        <Button
          label={incrementButton.label}
          handleButtonClick={handleIncrementButtonClick}
          tabIndex={2}
          width={incrementButton.width}
          themeConfiguration={incrementButton.themeConfiguration}
          type='filled'
          marginLeft={incrementButton.marginLeft}
          disabled={!isEditable && isReadOnly}
        />
      )}
    </RangeSliderRoot>
  )
}

RangeSlider.defaultProps = {
  color: 'blue',
  prefix: '',
  postfix: ''
}

RangeSlider.propTypes = {
  /**
   * Color of the selected portion.
   */
  color: PropTypes.string,
  /**
   * Minimum range value.
   */
  minValue: PropTypes.number.isRequired,
  /**
   * Maximum range value.
   */
  maxValue: PropTypes.number.isRequired,
  /**
   * Step value (increase and decrease by).
   */
  step: PropTypes.number.isRequired,
  /**
   * Default selected value
   */
  defaultSelected: PropTypes.number.isRequired,
  /**
   * Handle change/update callback.
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Prefix to be displayed, e.g. $100
   */
  prefix: PropTypes.string,
  /**
   * Postfix to be displayed, e.g. 100cm
   */
  postfix: PropTypes.string,
  /**
   * Theme configuration object
   */
  themeConfiguration: PropTypes.object,
  /**
   * customLabelStyles is an array for the individual labels
   * The right label styles needs to be passed first
   */
  customLabelStyles: PropTypes.array
}

export default RangeSlider
