import React from 'react'
import PropTypes from 'prop-types'
import { SwitchOn, SwitchOff, ToggleWrapper } from './styles'

const ToggleSwitch = (props) => {
  const { label, currentActive, onChange } = props
  return (
    <ToggleWrapper>
      <SwitchOn
        className={currentActive ? 'active' : ''}
        onClick={() => onChange(true)}
      >
        {label[0]}
      </SwitchOn>
      <SwitchOff
        className={!currentActive ? 'active' : ''}
        onClick={() => onChange(false)}
      >
        {label[1]}
      </SwitchOff>
    </ToggleWrapper>
  )
}

ToggleSwitch.defaultProps = {
  label: ['On', 'Off']
}

ToggleSwitch.propTypes = {
  /**
   * Change handler
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Label to display ['On','Off']
   */
  label: PropTypes.array,
  /**
   * Current value of toggle checkbox input.
   */
  currentActive: PropTypes.bool.isRequired
}

export default ToggleSwitch
