import styled from 'styled-components'
import { inputWidth } from './themes'

const getRadius = (theme) => {
  const { preText, postText, corner, borderRadius } = theme
  if (borderRadius) {
    return [borderRadius, borderRadius, borderRadius, borderRadius]
  } else if (
    (preText && postText) ||
    corner === 'sharp' ||
    borderRadius === 0
  ) {
    return ['0', '0', '0', '0']
  }
  if (preText && corner === 'round') {
    return ['0', '4px', '4px', '0']
  }
  if (postText && corner === 'round') {
    return ['4px', '0', '0', '4px']
  }
  return ['4px', '4px', '4px', '4px']
}

const Wrapper = styled.div`
  & * {
    box-sizing: border-box;
  }
  position: relative;
  background: transparent !important;
  ${(props) => (!props.hasInfoIcon && props.questionId && !((props.questionId.includes('GIWLMembershipNumber') || props.questionId.includes('EnteredAgentNumber')
  ))) ? `max-width: 100%` : '' };
  display: ${(props) =>
    props.displayVariant === 'inline' ? 'inline-block' : ''};
  //width: ${(props) => (props.displayVariant === 'inline' ? '50%' : '100%')};
  width: ${(props) =>
    (props.questionId && ((props.questionId.includes('GIWLMembershipNumber') || props.questionId.includes('EnteredAgentNumber')
    ))) ? "100%" : (props.width && !props.inlineTooltip
      ? Object.keys(inputWidth).includes(props.width)
        ? `${inputWidth[props.width]}px`
        : props.width
      : '100%')};
  margin-left: ${(props) => (props.displayVariant ? '10px' : '0%')};
`

const LabelWrapper = styled.div.attrs((props) => ({
  'aria-hidden': props.theme.hideLabel ? 'true' : undefined
}))`
  position: ${({ theme }) => (theme.floatingLabel ? 'absolute' : 'relative')};
  display: ${({ theme }) => (theme.hideLabel ? 'none' : 'flex')};
  align-items: ${({ theme }) =>
    theme.labelWrapperStyles?.alignItems || 'center'};
  ${({ theme: { preText } }) => preText && `align-items: center`};
  top: 0;
  ${({ theme }) =>
    theme.calendarIconPlacement && theme.calendarIconPlacement === 'left'
      ? `left: 20px`
      : `left: 0;`}
  ${({ theme: { floatingLabel } }) =>
    !floatingLabel &&
    `
      order: -1;
      margin-right: 20px;
    `};
  & > span {
    visibility: hidden;
  }
  max-width: 100%;
  width: ${({ theme }) => theme.width};
  ${({ type, theme }) =>
    type === 'text_area' &&
    `
     ${theme.floatingLabel && 'top: 1px'};
     left:2px;
     width:calc(100% - 5px);
     background-color: ${theme.backgroundColor || '#ffffff'};
     z-index:2000;
    `};
`

const Label = styled.label`

  ${(props) => props.isAsterisk ? `
  &:before{
    content:'*';
    margin:5px 5px 0 0;
  }` : ''};
  font-size: ${(props) => props.fontSize || '16px'};
  font-family: ${(props) => props.fontFamily || 'inherit'};
  font-weight: ${({ theme: { labelFontWeight } }) => labelFontWeight || 'bold'};
  line-height: ${({ theme: { labelLineHeight } }) => labelLineHeight || 1};
  width: auto;
  padding: 0.5em;
  ${({ theme }) =>
    theme.hasTooltip &&
    theme.labelTooltipAlign &&
    `text-align: ${theme.labelTooltipAlign}`};
  padding-left: ${({ theme: { labelPosition } }) =>
    labelPosition === 'top' ? '0' : '0.5em'};
  transition: transform 0.25s, opacity 0.25s, padding 0.25s ease-in-out;
  transform-origin: 0 0;
  color: ${(props) => props.fontColor || '#767676'};
  z-index: ${({ theme: { component } }) =>
    component !== 'select' ? '3000' : '0'};
  cursor: text;
  ${({ theme }) =>
    !theme.preText &&
    theme.floatingLabel &&
    `
      top: 50%;
      transform: translate(10px, 10px);`};
  ${({ theme: { floatingLabel, hasContent, preText } }) =>
    floatingLabel &&
    hasContent &&
    `
      z-index: 2500;
      padding: 0;
      padding-left: 10px;
      transform: ${
        preText
          ? 'translate(10px,-12px) scale(0.7)'
          : 'translate(10px,6px) scale(0.7)'
      };
      color: ${(props) => props.fontColor || 'rgba(153, 153, 153, 1)'};
    `}
  ${({ theme: { preText, labelPosition, preTextLeftPadding } }) =>
    preText &&
    `
    padding-left: ${
      labelPosition === 'top' && preTextLeftPadding
        ? preTextLeftPadding
        : '0.5em'
    };
    margin-bottom: 0 !important;
  `};
  ${({ theme: { floatingLabel, component, width } }) =>
    floatingLabel &&
    `
    & * {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        width: ${
          component === 'select' ? `${inputWidth[width] - 90}px` : `auto`
        };
      }
  `};
  & * {
    margin: 0 !important;
    padding: 0 !important;
  }
  
  ${({ theme: { labelText } }) =>
    labelText &&
    `
    span{
      color:${labelText.color};
      font-size:${labelText.fontSize};
      font-family:${labelText.fontFamily};
      line-height:${labelText.lineHeight};
      font-weight:${labelText.fontWeight}; 
    }
    `}

  ${({ theme: { spanText } }) =>
    spanText &&
    `
  span{
    color:${spanText.fontColor};
    font-size:${spanText.fontSize};
    font-family:${spanText.fontFamily};
    line-height:${spanText.lineHeight};
    font-weight:${spanText.fontWeight};
    overflow: ${spanText.overFlow}
  }

  div{
    color:${spanText.fontColor};
    font-size:${spanText.fontSize};
    font-family:${spanText.fontFamily};
    line-height:${spanText.lineHeight};
    font-weight:${spanText.fontWeight};
    
  }
  `};
  ${({ theme: { font } }) =>
    font &&
    `
    span{
      font:${font};
      
    }
`};
`

const InputBody = styled.div`
  display: flex;
  flex-direction: row;
  textarea {
    height: ${({theme}) => theme.hasRows ? 'auto' : '100px'} !important;
    resize: vertical;
  }
  input,
  textarea {
    padding: 16px;
    padding-left: ${({ theme: { calendarIconPlacement } }) =>
      calendarIconPlacement && calendarIconPlacement === 'left'
        ? '40px'
        : '16px'};
    padding-bottom: ${({ theme: { floatingLabel, hasContent } }) =>
      floatingLabel && hasContent ? '8px' : '16px'};
    height: 52px;
    font-size: 16px;
    ${({ theme }) =>
      theme.padding &&
      `
      padding:${theme.padding};
    `};
    transition: border-color 0.25s ease-in-out;
    box-shadow: ${({ boxShadow }) =>
      boxShadow || 'rgb(153, 153, 153) 0px 2px 2px -2px'};
    border-top-left-radius: ${({ theme }) => getRadius(theme)[0]};
    border-top-right-radius: ${({ theme }) => getRadius(theme)[1]};
    border-bottom-right-radius: ${({ theme }) => getRadius(theme)[2]};
    border-bottom-left-radius: ${({ theme }) => getRadius(theme)[3]};
    background-color: ${({ theme }) =>
      theme.backgroundColor ? theme.backgroundColor : 'transparent'};
    width: ${({ theme: { width }, docWidth }) => getWidth(width, docWidth)};
    cursor: ${({ theme: { disabled, disabledPointer }}) => ( disabled && disabledPointer) ? disabledPointer : 'text'};
    position: relative;
    z-index: 2000;
    color: rgb(73, 80, 87);
    border: ${({
      theme: { border, hasError, errorMessageColor, errorBorderWidth = '1px' }
    }) =>
      hasError
        ? `${errorBorderWidth} solid ${
            errorMessageColor || 'rgba(255, 0, 0, 1)'
          }`
        : `${border || '1px solid rgba(153, 153, 153, 1)'}`};
    ${({ theme: { borderBottom, hasError, errorBottomBorderWidth = '1px' } }) =>
      borderBottom &&
      `
      border-bottom: ${
        hasError
          ? `${errorBottomBorderWidth} solid ${'rgba(255, 0, 0, 1)'}`
          : `${borderBottom || '1px solid rgba(153, 153, 153, 1)'}`
      };
    `};
    &:focus {
      outline: 0;
      border-color: ${({
        theme: { focusColor, borderOnFocus, hasError, errorMessageColor }
      }) =>
        hasError
          ? errorMessageColor || '#cf102d'
          : borderOnFocus || focusColor};
      padding-bottom: ${({ theme: { floatingLabel } }) =>
        floatingLabel ? '8px' : '16px'};
    }
    &:hover {
      border-color: ${({
        theme: { focusColor, hasError, errorMessageColor }
      }) => (hasError ? errorMessageColor || '#cf102d' : focusColor)};
    }
    &::placeholder {
      color: ${({ theme }) =>
        theme.floatingLabel ? 'transparent' : 'rgb(153, 153, 153)'};
        ${({ theme: { placeHolder } }) =>
          placeHolder &&
          `
    color:${placeHolder.color};
    font-size:${placeHolder.fontSize};
    font-family:${placeHolder.fontFamily};
    line-height:${placeHolder.lineHeight};
  `};
  font:${({ theme }) => theme.placeholderFont}
    }
    &:disabled {
      background: #fafafa;
    }
  } 
  // this condition is to prevent overlap of label
  // inside the input with the value being entered, NAT-1470
  ${({ theme: { hasContent } }) =>
    hasContent &&
    `
      & > ${LabelWrapper} > label {
        white-space: nowrap !important;
      }

      & > ${LabelWrapper} > label > span {
        white-space: normal !important;
      }
      `};
  ${({ theme }) =>
    theme.floatingLabel &&
    `
      &:focus ~ ${LabelWrapper} > label,
       &:not(:placeholder-shown) ~ ${LabelWrapper} > label {
        z-index: 2500;
        padding: 0;
        padding-left: 10px;
        white-space: nowrap !important;
        transform: ${
          theme.preText
            ? 'translate(10px,-12px) scale(0.7)'
            : 'translate(10px,6px) scale(0.7)'
        };
        color: rgba(153, 153, 153, 1);
        & > span {
          width: auto;
        }
      }
    `};
    &:focus::placeholder {
      color: rgb(153, 153, 153);
    }
    &:-internal-autofill-previewed {
      background-color: #ffffff !important;
      appearance: none !important;
    }
  }
  ${({ theme: { floatingLabel, labelPosition, labelMargin } }) =>
    !floatingLabel &&
    labelPosition === 'left' &&
    `
      flex-direction: row;
      justify-content: ${
        labelMargin === 'max' ? 'space-between' : 'flex-start'
      };
      align-items: center;
    `};
  ${({ theme: { floatingLabel, labelPosition } }) =>
    !floatingLabel &&
    labelPosition === 'top' &&
    `
      flex-direction: column;
      align-items: flex-start;
    `};
    ${({ theme: { inputStyles } }) =>
      inputStyles &&
      `
      input {
        font-size : ${inputStyles.fontSize};
        font-family: ${inputStyles.fontFamily};
        color: ${inputStyles.fontColor};
      };
      `};
    
`

const getWidth = (width, docWidth) => {
  if (Object.keys(inputWidth).includes(width)) {
    const w = inputWidth[width]
    docWidth = docWidth - 100 // +100 for extra padding.
    if (docWidth < w) {
      return `100%`
    }
    return `${w}px`
  }
  return width
}

const InputComponents = styled.div`
    display: flex;
    position: relative;
    border: ${({ theme }) => theme.inputBorder};
    margin: ${({ theme }) => theme.margin};
    overflow: ${(props) => (props.showCalendarPopper ? 'none' : 'hidden')};
    width: ${({ theme: { width }, docWidth }) => getWidth(width, docWidth)};
    font-size : ${(props) => props.fontSize || 'inherit'}
    overflow: ${({ theme }) => theme.overflow};
    cursor: ${({ theme: { component } }) =>
      component === 'select' ? 'pointer' : 'default'};
    ${({ theme }) =>
      theme.floatingLabel &&
      `
        &:focus-within + ${LabelWrapper} > label {
          z-index: 2500;
          padding: 0;
          padding-left: 10px;
          transform: ${
            theme.preText
              ? 'translate(10px,-12px) scale(0.7)'
              : 'translate(10px,6px) scale(0.7)'
          };
          color: rgba(153, 153, 153, 1);
          & > span {
            width: auto;
          }
        }
        
      `};
      &:focus::placeholder {
        color: rgb(153, 153, 153);
      }
      &:-internal-autofill-previewed {
        background-color: #ffffff !important;
        appearance: none !important;
      }
      & > input {
        ::-ms-clear { 
          display: none; 
        }
      }
      & > span {
        flex-grow: 1;
      }
    }
    ${({
      theme: {
        shouldFocusWithin,
        errorMessageColor,
        hasError,
        preText,
        postText,
        width,
        borderOnFocus,
        borderRadiusOnFocus
      }
    }) =>
      shouldFocusWithin &&
      `
      &:focus-within {
        border:none;
        outline-color: ${
          hasError ? errorMessageColor || '#eeacb6' : borderOnFocus
        };
        outline-style: solid; 
        outline-width: 4px;
        outline-radius: ${borderRadiusOnFocus || 'inherit'};
        & input {
          border-radius: 0;
        };
        ${
          preText &&
          `
          & > span:first-of-type {
            border-radius: 0;
          }
        `
        }
      }
      ${
        (postText || preText) &&
        `
        & > input { 
            width: 80%
         }`
      };
      ${
        (postText || preText) &&
        width === 'S' &&
        `
        & > input { 
            width: 65%
         }`
      };
    `}
    }   
    @media(max-width: 768px){
      & > input[type=number] {
        -moz-appearance: textfield;
      }
    }
  `

const InputWrapper = styled.div`
  box-sizing: border;
  border: 1px solid grey;
  padding: 12px;
  height: 52px;
  font-size: 16px;
  -webkit-transition: border-color 0.25s ease-in-out;
  transition: border-color 0.25s ease-in-out;
  box-shadow: rgb(153, 153, 153) 0px 2px 2px -2px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: transparent;
  width: ${({ theme: { width }, docWidth }) => getWidth(width, docWidth)};
  cursor: ${({ theme: { disabled, disabledPointer }}) => ( disabled && disabledPointer) ? disabledPointer : 'text'};
  position: relative;
  z-index: 2000;
  color: rgb(73, 80, 87);
  border: 1px solid #cccccc;
`

const ContentWrapper = styled.div`
  position: relative;
`

const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.errorMessageColor || '#ff0000'};
  font-size: ${({ theme }) => theme.errorFontSize || '0.75em'};
  padding: 0.5em;
  white-space: ${(props) => props.questionId && (props.questionId.includes('GIWLMembershipNumber') || props.questionId.includes('EnteredAgentNumber')
  ) ? 'normal' : 'nowrap'};
  visibility: ${({ theme }) => (theme.hasError ? 'visible' : 'hidden')};
  width: 100%;
  @media (max-width: 767px) {
    overflow-wrap: break-word;
    white-space: normal;
    ${({ theme: { width, errorTextHeight } }) =>
      width === 'S' &&
      errorTextHeight &&
      `
      display: block;
      width: ${parseInt(getWidth(width)?.replace('px', '')) + 10}px;
      height: ${errorTextHeight};
    `}
  }
`

const HelperText = styled.span`
  color: ${({ theme }) => theme.helperTextColor || '#555555'};
  font-size: 0.75em;
  padding: 0.5em;
  padding-left: 0em;
  width: 100%;
`

const TooltipWrapper = styled.div`
  margin: ${({ position, tooltipPlacement }) => {
    if (position === 'inline') return 0
    else if (tooltipPlacement === 'label') return '0 5px'
    else return '18px 5px'
  }};
  ${({ theme }) =>
    theme.shape === 'oval' &&
    `
    @media (min-width: 416px) and (max-width: 465px) {
      position: absolute;
      right: 0%;
    }
    @media (min-width: 400px) and (max-width: 415px) {
      position: absolute;
      right: 0%;
      top: 10%;
    }
    @media (max-width: 399px) {
      position: absolute;
      top: 20%;
      right: 15%;
    }
  `}
`

export {
  Wrapper,
  Label,
  getWidth,
  ErrorMessage,
  HelperText,
  InputBody,
  ContentWrapper,
  LabelWrapper,
  InputComponents,
  TooltipWrapper,
  InputWrapper
}