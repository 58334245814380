import { AppConstants } from "../model/AppConstants";
import {buildRequestConfigWithAccessToken, buildRequestConfigWithRole, requests} from "../utils/AxiosHelper";
import { getLoginResponseFromJson, GetLoginResponse } from '../model/GetLoginResponse';
import { postLoginResponseJson, PostLoginResponse, PostLoginRequest, IdpTokenReqParamKeyEnum } from "../model/PostLogin";
import {AxiosError, AxiosRequestConfig, AxiosRequestHeaders} from "axios";
import {executeSamlFormUrl, getBase64EncodedString} from "../utils/CommonUtil";
import { getLogoutResponseFromJson, LogoutResponse } from "../model/LogoutResponse";
import {getTraitsResponseFromJson, TraitsResponse} from "../model/TraitsResponse";

/**
 * Makes a post API call for a given parameter code and baseUri
 * @param baseUri base url to make an api call
 * @param code the post parameter you get when you call getLogin function
 * @param role role for which the api need to be called
 * @returns PostLoginResponse
 * @throws Error on failure
 */
export async function postLogin(baseUri: string, idpToken: string, role: string, requestKey: IdpTokenReqParamKeyEnum): Promise<PostLoginResponse | void> {
  const urlEndPoint = baseUri + AppConstants.loginEndPoint;
  const headersRequest = buildRequestConfigWithRole(role);
  const bodyRequest: PostLoginRequest = requestKey && requestKey === IdpTokenReqParamKeyEnum.Code ? { code: idpToken } : { SAMLResponse: idpToken };

  const response = await requests.post(urlEndPoint, bodyRequest, headersRequest);
  let loginResponse: PostLoginResponse = postLoginResponseJson(response); // Post Login Response model call
  if (loginResponse?.code === 200 || loginResponse?.errors) {
    return loginResponse;
  } else {
    throw new Error("Post API Error:- " + JSON.stringify(response));
  }
}

/**
 * Retrieve the right redirect URL/Form for a given role
 * @param baseUri base url to make an api call
 * @param role role for which the api need to be called
 * @returns <PostLoginResponse>
 * @throws Error on failure
 */
export async function getLogin(baseUri: string, role: string): Promise<GetLoginResponse | void> {
  const apiUrl = baseUri.concat(AppConstants.loginEndPoint);
  const state: Base64EncodedState = { app_host_url: window.location.origin };
  const base64EncodedState: string = getBase64EncodedString(JSON.stringify(state));
  if(!base64EncodedState) {
    console.warn('base64EncodedState value is null. Please check window.location.origin is fetching the URL correctly');
  }
  const params: StateParam = !!base64EncodedState ? {state: base64EncodedState} : null
  const settings = buildRequestConfigWithRole(role, params);
  const response = await requests.get(apiUrl, settings);
  const loginApiResponseModal: GetLoginResponse = getLoginResponseFromJson(response);
  if (loginApiResponseModal.errors) {
    throw new Error('Got error response from auth component: ' + JSON.stringify(response));
  } else {
    const {serverType, redirectUrl} = loginApiResponseModal.data;
    if((serverType === AppConstants.saml2 ||serverType === AppConstants.oidc) && redirectUrl?.url) {
      window.location.assign(loginApiResponseModal.data.redirectUrl.url);
    } else if(loginApiResponseModal.data.serverType === AppConstants.saml2 && loginApiResponseModal.data.render?.form) {
      executeSamlFormUrl(loginApiResponseModal.data.render.form)
    } else {
      throw new Error('Failed to receive valid redirect url or form in the response: ' + JSON.stringify(response));
    }
  }
}

/**
 * Logout users from current SSO session
 * @param baseUri - base url of lifetime auth component
 * @param accessToken - access token provided by lifetime auth component
 * @returns - Promise containing {@link LogoutResponse}
 */
export async function getLogoutRedirect(baseUri: string, accessToken: string): Promise<LogoutResponse> {
  const apiUrl = baseUri + AppConstants.logoutEndpoint;
  const requestConfig = buildRequestConfigWithAccessToken(accessToken);
  const response = await requests.post(apiUrl, {}, requestConfig);
  return getLogoutResponseFromJson(response);
}

/**
 * Fetch all traits (claims for OIDC, attributes for SAML) for this user.
 * If an array of trait keys is provided, this function will only return those values if available.
 * @param baseUri - base URL of lifetime auth component
 * @param accessToken - access token provided by lifetime auth component
 * @param traitKeys - Promise containing {@link TraitsResponse}
 */
export async function getTraits(baseUri: string, accessToken: string, traitKeys?: Array<string>): Promise<TraitsResponse> {
  const apiUrl = baseUri + AppConstants.traitsEndpoint;
  const allTraitKeys = traitKeys?.join(',') || null;
  const trait: Traits = {trait_keys: allTraitKeys};
  const requestConfig = buildRequestConfigWithAccessToken(accessToken, trait);
  const response = await requests.get(apiUrl, requestConfig);
  return getTraitsResponseFromJson(response);
}
