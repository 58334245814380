import styled from 'styled-components'
import { inputWidth } from '../../themes'

const getRadius = (theme) => {
  const { preText, postText, corner } = theme
  if ((preText && postText) || corner === 'sharp') {
    return ['0', '0', '0', '0']
  }
  if (preText && corner === 'round') {
    return ['0', '4px', '4px', '0']
  }
  if (postText && corner === 'round') {
    return ['4px', '0', '0', '4px']
  }
  return ['4px', '4px', '4px', '4px']
}

const Wrapper = styled.div`
  & * {
    box-sizing: border-box;
  }
  position: relative;
  background: transparent !important;
  max-width: 100%;
  width: 100%;
`

const LabelWrapper = styled.div`
  position: ${({ theme }) => (theme.floatingLabel ? 'absolute' : 'relative')};
  display: flex;
  ${({ theme: { preText } }) => preText && `align-items: center`};
  top: 0;
  left: 0;
  ${({ theme: { floatingLabel } }) =>
    !floatingLabel &&
    `
    order: -1;
    margin-right: 20px;
  `};
  & > span {
    visibility: hidden;
  }
`

const Label = styled.label`
  ${(props) =>
    props.isAsterisk
      ? `
    &:before{
      content:'*';
      margin:5px 5px 0 0;
    }`
      : ''};
  && {
    font-size: ${(props) => props.labelFontSize || '16px'};
  }
  font-weight: bold;
  line-height: 1;
  width: auto;
  padding: 0.5em;
  ${({ theme }) =>
    theme.hasTooltip &&
    theme.labelTooltipAlign &&
    `text-align: ${theme.labelTooltipAlign}`};
  padding-left: ${({ theme: { labelPosition } }) =>
    labelPosition === 'top' ? '0' : '0.5em'};
  transition: transform 0.25s, opacity 0.25s, padding 0.25s ease-in-out;
  transform-origin: 0 0;
  color: #767676;
  z-index: ${({ theme: { component } }) =>
    component !== 'select' ? '3000' : '0'};
  cursor: text;
  ${({ theme }) =>
    !theme.preText &&
    theme.floatingLabel &&
    `
    top: 50%;
    transform: translate(10px, 10px);`};
  ${({ theme: { floatingLabel, hasContent, preText } }) =>
    floatingLabel &&
    hasContent &&
    `
    z-index: 2500;
    padding: 0;
    padding-left: 10px;
    transform: ${
      preText
        ? 'translate(10px,-12px) scale(0.7)'
        : 'translate(10px,6px) scale(0.7)'
    };
    color: rgb(118, 118, 118);
  `};
  ${({ theme: { floatingLabel, component, width } }) =>
    floatingLabel &&
    `
    & * {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        width: ${
          component === 'select' ? `${inputWidth[width] - 90}px` : `auto`
        };
      }
  `};
  & * {
    margin: 0 !important;
    padding: 0 !important;
  }
  font: ${({ theme }) => theme.font};
  color: ${({ theme }) => theme.fontColor};
`

const InputBody = styled.div`
  display: flex;
  flex-direction: row;
  input {
    padding: 16px;
    padding-bottom: ${({ theme: { floatingLabel, hasContent } }) =>
      floatingLabel && hasContent ? '8px' : '16px'};
    height: 52px;
    font-size: 16px;
    transition: border-color 0.25s ease-in-out;
    box-shadow: ${({ boxShadow }) =>
      boxShadow || 'rgb(153, 153, 153) 0px 2px 2px -2px'};
    border-top-left-radius: ${({ theme }) => getRadius(theme)[0]};
    border-top-right-radius: ${({ theme }) => getRadius(theme)[1]};
    border-bottom-right-radius: ${({ theme }) => getRadius(theme)[2]};
    border-bottom-left-radius: ${({ theme }) => getRadius(theme)[3]};
    background-color: transparent;
    width: ${({ theme: { width } }) => getWidth(width)};
    cursor: text;
    position: relative;
    z-index: 2000;
    color: rgb(73, 80, 87);
    border: ${({ theme: { hasError, border } }) =>
      hasError
        ? '1px solid rgba(255, 0, 0, 1)'
        : border || '1px solid rgba(153, 153, 153, 1)'};
    &:focus {
      outline: 0;
      border-color: ${({ theme }) => theme.focusColor};
      padding-bottom: ${({ theme: { floatingLabel } }) =>
        floatingLabel ? '8px' : '16px'};
    }
    &:hover {
      border-color: ${({ theme }) => theme.focusColor};
    }
    &::placeholder {
      color: ${({ theme }) =>
        theme.floatingLabel ? 'transparent' : 'rgb(118, 118, 118)'};
    }
    ${({ theme }) =>
      theme.floatingLabel &&
      `
      &:focus ~ ${LabelWrapper} > label,
       &:not(:placeholder-shown) ~ ${LabelWrapper} > label {
        z-index: 2500;
        padding: 0;
        padding-left: 10px;
        transform: ${
          theme.preText
            ? 'translate(10px,-12px) scale(0.7)'
            : 'translate(10px,6px) scale(0.7)'
        };
        color: rgb(118, 118, 118);
        & > span {
          width: auto;
        }
      }
    `};
    &:focus::placeholder {
      color: rgb(118, 118, 118);
    }
    &:-internal-autofill-previewed {
      background-color: #ffffff !important;
      appearance: none !important;
    }
  }
  ${({ theme: { floatingLabel, labelPosition, labelMargin } }) =>
    !floatingLabel &&
    labelPosition === 'left' &&
    `
    flex-direction: row;
    justify-content: ${labelMargin === 'max' ? 'space-between' : 'flex-start'};
    align-items: center;
  `};
  ${({ theme: { floatingLabel, labelPosition } }) =>
    !floatingLabel &&
    labelPosition === 'top' &&
    `
    flex-direction: column;
    align-items: flex-start;
  `};
`

const getWidth = (width) => {
  const w = inputWidth[width]
  const docWidth = document.documentElement.clientWidth - 100 // +100 for extra padding.
  if (docWidth < w) {
    return `100%`
  }
  return `${w}px`
}

const InputComponents = styled.div`
  display: flex;
  position: relative;
  width: ${({ theme: { width } }) => getWidth(width)};
  cursor: ${({ theme: { component } }) =>
    component === 'select' ? 'pointer' : 'default'};
  ${({ theme }) =>
    theme.floatingLabel &&
    `
      &:focus-within + ${LabelWrapper} > label {
        z-index: 2500;
        padding: 0;
        padding-left: 10px;
        transform: ${
          theme.preText
            ? 'translate(10px,-12px) scale(0.7)'
            : 'translate(10px,6px) scale(0.7)'
        };
        color: rgba(118, 118, 118, 1);
        & > span {
          width: auto;
        }
      }
      
    `}; 
    ${({ theme: { inputBorder } }) => `border: ${inputBorder};`}
    &:focus::placeholder {
      color: rgb(118, 118, 118);
    }
    &:focus-within {
      ${({ theme: { borderOnFocus } }) => `border: ${borderOnFocus};`}
    }
    &:-internal-autofill-previewed {
      background-color: #ffffff !important;
      appearance: none !important;
    }
  }
`

const ContentWrapper = styled.div`
  position: relative;
`
const Texts = styled.span`
  padding: 16px 10px;
  font-size: 16px;
  font-weight: 400;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  white-space: nowrap;
  border-radius: 4px;
  height: 52px;
  line-height: 1;
`

const PreText = styled(Texts)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`

const PostText = styled(Texts)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`

const DropIcon = styled.div`
  display: inline-block;
  fill: #cccccc;
  line-height: 1;
  stroke: #cccccc;
  stroke-width: 0;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: ${({ open }) =>
    open ? 'translateY(-50%) scale(1, -1)' : 'translateY(-50%);'};
  cursor: pointer;
  z-index: 99999;
  transition: all 0.3s linear;
`
const ErrorMessage = styled.span`
  color: #ff0000;
  font-size: 0.75em;
  padding: 0.5em;
  visibility: ${({ theme }) => (theme.hasError ? 'visible' : 'hidden')};
`

const CaptionText = styled.div`
  color: ${({ theme }) => theme.captionTextColor && theme.captionTextColor};
  font-size: 0.75em;
  padding-top: 0.5em;
  font-weight: bold;
  text-align: end;
`

export {
  PreText,
  PostText,
  DropIcon,
  Wrapper,
  LabelWrapper,
  Label,
  ContentWrapper,
  InputComponents,
  InputBody,
  ErrorMessage,
  CaptionText
}
