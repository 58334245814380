import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'

import {
  AccordionContainer,
  AccordionHeaderWrapper,
  AccordionHeader,
  AccordionHeaderTitleWrapper,
  AccordionHeaderIconWrapper,
  AccordionTogglerWrapper,
  AccordionTogglerIcon,
  AccordionHeaderTitle,
  AccordionBody
} from './styles'
import { Button } from '../Button'

const Accordion = (props) => {
  const {
    title,
    children,
    themeConfiguration,
    iconSrc,
    iconClass,
    iconTheme,
    defaultOpened
  } = props

  const [active, setActive] = useState(defaultOpened)

  const handleAccordionClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setActive(!active)
  }

  if (!children) return null

  const isIconPresent = iconSrc || iconClass;

  const HeaderLabel = (
    <Fragment>
      {(iconSrc || iconClass) && (
        <AccordionHeader>
          <AccordionHeaderIconWrapper iconTheme={iconTheme}>
            {iconSrc && <img src={iconSrc} alt='accordion-icon' />}
            {iconClass && <i className={`fas ${iconClass}`} />}
          </AccordionHeaderIconWrapper>
        </AccordionHeader>
      )}
      <AccordionHeaderTitleWrapper active={active}>
        <AccordionTogglerWrapper>
          <AccordionTogglerIcon active={active} />
        </AccordionTogglerWrapper>
        {title && <AccordionHeaderTitle>{title}</AccordionHeaderTitle>}
      </AccordionHeaderTitleWrapper>
    </Fragment>
  )

  return (
    <AccordionContainer>
      <AccordionHeaderWrapper>
        <Button
          label={HeaderLabel}
          themeConfiguration={themeConfiguration}
          handleButtonClick={handleAccordionClick}
        />
      </AccordionHeaderWrapper>
      <AccordionBody active={active} isIconPresent={isIconPresent}>{children}</AccordionBody>
    </AccordionContainer>
  )
}

Accordion.defaultProps = {
  defaultOpened: false,
  themeConfiguration: {
    selectedBgColor: '#FFF',
    bgColor: '#3C5972',
    width: '100%',
    alignment: 'left',
    fontWeight: 'bold',
    padding: '0',
    border: 'none'
  },
  iconClass: null,
  iconSrc: null,
  iconTheme: null
}

Accordion.propTypes = {
  defaultOpened: PropTypes.bool,
  title: PropTypes.string.isRequired,
  themeConfiguration: PropTypes.object,
  iconSrc: PropTypes.string,
  iconClass: PropTypes.string,
  iconTheme: PropTypes.object
}

export default Accordion
