import React, { useState, useRef } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import utils from '../DatePicker/utils'
import { useDocumentWidth, stripHtml } from '../../utils'
import {
  Wrapper,
  ErrorMessage,
  ContentWrapper,
  InputComponents,
  InputWrapper
} from '../../styles'
import { Input, Separator, DummyInput, Label } from './styles'

const DateInput = (props) => {
  const {
    corner,
    id,
    response,
    autoFormat,
    onChange,
    minValue,
    maxValue,
    customError,
    color,
    required,
    responseFormat,
    yearPlaceholder,
    monthPlaceholder,
    dayPlaceholder,
    width,
    questionStatus,
    labelText,
    errorMessageColor
  } = props

  // Error Handling states
  const { error: e = false, error_message: em = '' } = customError
  const [hasError, setError] = useState(e)
  const [errorMsg, setErrorMsg] = useState(em)

  const hasPlaceholder = true

  let d = ''
  let m = ''
  let y = ''

  /**
   * We would use these previous values of valid day, month and year to populate when
   * all the fields like day, month and year are deleted by the user and doesn't enter any
   * values again.
   **/
  const [prevDayVal, setPrevDayVal] = useState('')
  const [prevMonthVal, setPrevMonthVal] = useState('')
  const [prevYearVal, setPrevYearVal] = useState('')

  const [dayVal, setDay] = useState('')
  const [monthVal, setMonth] = useState('')
  const [yearVal, setYear] = useState('')

  const dayRef = useRef()
  const monthRef = useRef()
  const yearRef = useRef()

  const [dayWidth, setDayWidth] = useState('')
  const [monthWidth, setMonthWidth] = useState('')

  const createErrorObject = (err, errorMsg) => {
    return { error: err, error_message: errorMsg }
  }

  // Get document width (reactive to window resizing).
  const docWidth = useDocumentWidth()

  // Response Format Value
  const responseFormatValue = responseFormat

  // Calender Popper Format Value
  const { value: formatValue } = autoFormat

  // Formats min and max data values
  const { value: minDateValue } = minValue
  const minDate = utils.formatDate(minDateValue, formatValue)
  const { value: maxDateValue } = maxValue
  const maxDate = utils.formatDate(maxDateValue, formatValue)

  const isLeapYear = (year) =>
    (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0

  const convertToNumber = (...args) => {
    args = Array.prototype.slice.call(args)
    for (let k = 0; k < args.length; k++) {
      args[k] = args[k] !== undefined ? Number(args[k]) : 0
    }
    return args.length > 1 ? [...args] : args[0]
  }

  const isValidDay = (d, m, y) => {
    const [day, month, year] = convertToNumber(d, m, y)
    if (isNaN(day) || day === 0 || day < 0) return false
    if (day > 31) return false // no month has > 31 days
    if (day > 29 && month === 2) return false // feb has 28/29 days
    // check for leap year
    if (day > 28 && year && month === 2) {
      return isLeapYear(year)
    }

    // months that have 31 days
    if (day === 31 && month !== 0) {
      switch (month) {
        case 1:
        case 3:
        case 5:
        case 7:
        case 8:
        case 10:
        case 12:
          return true
        default:
          return false
      }
    }

    // remaining cases, return true
    return true
  }

  const isValidMonth = (mnth) => {
    const [day, month, year] = convertToNumber(dayVal, mnth, yearVal)

    if (
      month === '' ||
      month === 0 ||
      month > 12 ||
      month < 0 ||
      isNaN(month)
    ) {
      return false
    }
    if (dayVal) {
      return isValidDay(day, month, year)
    }
    return true
  }

  const isValidYear = (yr, inFocus = false) => {
    const length = yr.length
    const [day, month, year] = convertToNumber(dayVal, monthVal, yr)
    if (year === 0 || year < 0 || isNaN(year)) {
      return false
    }

    if (length === 1 && inFocus) {
      return parseInt(year / 1, 10) === 1 || parseInt(year / 1, 10) === 2
    }

    if (length === 2 && inFocus) {
      return parseInt(year, 10) >= 19
    }

    if (length === 3 && inFocus) {
      return parseInt(year / 190, 10) >= 1
    }

    if (!inFocus && length <= 3) {
      return false
    }

    if (!inFocus && length < 4) {
      return false
    }

    if (parseInt(year / 1000, 10) > 0 && parseInt(year / 1900, 10) < 1) {
      return false
    }

    if (month === 2 && day === 29) {
      return isLeapYear(year)
    }

    return true
  }

  const getExcludedMonths = () => {
    let excludeMonthList = []
    const day = convertToNumber(dayVal)
    if (day > 30) {
      excludeMonthList = [2, 4, 6, 9, 11]
    } else if (day > 29) {
      excludeMonthList = [2]
    }
    return excludeMonthList
  }

  const getExcludedDays = () => {
    const [month, year] = convertToNumber(monthVal, yearVal)
    let excludeDays = []
    if (month === 2) {
      excludeDays = isLeapYear(year) ? [30, 31] : [29, 30, 31]
    } else if ([2, 4, 6, 9, 11].includes(month)) {
      excludeDays = [31]
    } else if (month === 2) {
      excludeDays = [30, 31]
    }
    return excludeDays
  }

  const getNonLeapYears = () => {
    const nonLeapYearList = []
    for (let k = minYear; k <= maxYear; k++) {
      if (!isLeapYear(k)) {
        nonLeapYearList.push(k)
      }
    }
    return nonLeapYearList
  }

  const getExcludedYears = () => {
    if (monthVal === 2) {
      return getNonLeapYears()
    }
    return []
  }

  /**
   * returns true if all day, month and year values are valid
   **/
  const isAllValid = (d = dayVal, m = monthVal, y = yearVal) => {
    return isValidDay(d) && isValidMonth(m) && isValidYear(y)
  }

  /**
   * returns true if all prevDay, prevMonth and prevYear values are Valid
   **/
  const isPrevValid = () => {
    return (
      isValidDay(prevDayVal) &&
      isValidMonth(prevMonthVal) &&
      isValidYear(prevYearVal)
    )
  }

  /**
   * returns true if all month, day and year values are null
   **/
  const isAllNull = () => {
    return !(dayVal || monthVal || yearVal)
  }

  const getFont = (element) => {
    const prop = [
      'font-style',
      'font-variant',
      'font-weight',
      'font-size',
      'font-family'
    ]
    let font = ''
    for (var x in prop)
      font +=
        window.getComputedStyle(element, null).getPropertyValue(prop[x]) + ' '

    return font
  }

  const getTextWidth = (text) => {
    const element = document.getElementById(id)
    const canvas =
      getTextWidth.canvas ||
      (getTextWidth.canvas = document.createElement('canvas'))
    const context = canvas.getContext('2d')
    // element is undefined when the DOM is being loaded
    context.font = element ? getFont(element) : '10px sans-serif'
    var metrics = context.measureText(text)
    return Math.ceil(metrics.width) + 2 + 'px'
  }

  const setPrevNull = () => {
    setPrevDayVal('')
    setPrevMonthVal('')
    setPrevYearVal('')
  }

  /**
   * returns true if all prevDay, prevMonth and prevYear values are null
   **/
  const isPrevValNull = () => {
    const isValid = isPrevValid()
    if (!isValid) {
      setPrevNull()
    }
    return !isValid
  }

  const isAnyNull = () => {
    return !(Number(dayVal) && Number(monthVal) && Number(yearVal))
  }

  // Handles date error messages and error state
  const handleError = (dateValue) => {
    const { error, errorMessage } = utils.validateDateInput(
      props,
      dateValue,
      formatValue
    )
    if (error) {
      setError(true)
      setErrorMsg(errorMessage)
      return createErrorObject(error, errorMessage)
    }
    setError(false)
    setErrorMsg('')
    return createErrorObject(error, errorMessage)
  }

  const validateDayMonthYear = (d = dayVal, m = monthVal, y = yearVal) => {
    const date = utils.formatDate(m + '/' + d + '/' + y, formatValue)
    setDayWidth(getTextWidth(d))
    setMonthWidth(getTextWidth(m))
    let errorObj = ''
    if (isAllValid(d, m, y)) {
      setPrevDayVal(d)
      setPrevMonthVal(m)
      setPrevYearVal(y)
    }
    if (d && d.length === 2 && m && m.length === 2 && y && y.length === 4) {
      errorObj = handleError(date)
      onChange(
        utils.responseDateFormat(date, formatValue, responseFormatValue),
        errorObj
      )
    }
  }

  /**
   * Saving previous response to compare with the latest response, otherwise
   * the 'response' sets the state and calls the render indefinitely
   */
  const [prevRes, setPrevRes] = useState(null)

  if (moment(response).isValid() && prevRes !== response) {
    setPrevRes(response)
    m = response[0] + response[1]
    d = response[3] + response[4]
    y = response[6] + response[7] + response[8] + response[9]
    setMonth(m)
    setDay(d)
    setYear(y)
    setPrevMonthVal(response[0] + response[1])
    setPrevDayVal(response[3] + response[4])
    setPrevYearVal(response[6] + response[7] + response[8] + response[9])
  }

  const minYear = moment(minDate, formatValue).year()
  const maxYear = moment(maxDate, formatValue).year()

  const maxLengthCheck = (value, length) => {
    if (length === 4 && value.length > length) {
      return (value = value.slice(0, length))
    } else if (value.length > length) {
      return (value = value.slice(1, length + 1))
    }
    return value
  }

  /**
   * returns true if the value given by the user is invalid,
   * and sets the day or month value to null and width to default value
   * @param {*} val value input by user
   * @param {*} setInput setFunction that sets day or month based on passed function
   * @param {*} setInputWidth widthFunc that has to be called
   * @param {*} width width value
   */
  const shouldPreventDefault = (val, setInput) => {
    if (isNaN(val)) {
      setInput('')
      return true
    }
    return false
  }

  /**
   * returns the list of days or months or years that have to be excluded
   * for the day / month / year input by user
   * for eg: if day = 29, month = 02 then all non-leap years have to excluded,
   * for eg: if day = 31, year = 1992 then all months that donot have days > 30 have to excluded
   * @param {*} isValidFunc1
   * @param {*} val1 paramter for isValidFunc1
   * @param {*} isValidFunc2
   * @param {*} val2 parameter for isValidFunc2
   * @param {*} val3 parameter for isValidFun2
   * @param {*} getExcludFunc
   */
  // val2, val3 are for the parameters to the 'isValidFunc2' function
  const getList = (
    isValidFunc1,
    val1,
    isValidFunc2,
    val2,
    val3,
    getExcludFunc
  ) => {
    // let list = (isValidDay(dayVal) || isValidMonth(monthVal)) ? getExcludedYears() : [];
    // let list = isValidMonth(monthVal) ? getExcludedDays() : [];
    // let list = (isValidDay(dayVal) || isValidYear(yearVal, false)) ? getExcludedMonths() : [];

    if (!isValidFunc2) {
      // this returns the list of days that have to be excluded
      return isValidFunc1(val1) ? getExcludFunc() : []
    } else if (val3) {
      // this returns the list of months that have to be excluded
      return isValidFunc1(val1) || isValidFunc2(val2, val3)
        ? getExcludFunc()
        : []
    }
    // this returns the list of years that have to be excluded
    return isValidFunc1(val1) || isValidFunc2(val2) ? getExcludFunc() : []
  }

  /**
   * this function sets the final value of day, month after checking the validness of input.
   * We consider only the first digit if the second digit input is invalid
   * eg: for month if the value given is 14 which is invalid we would consider only
   * the value[0] i.e., 1 in this case.
   * eg: for day if the value given is 35 which is invalid we would consider only
   * the value[0] i.e 3 in this case
   */
  const setFinalInputVal = (setFunc, val, ref, setWidthFunc) => {
    setFunc(val)
    setWidthFunc(getTextWidth(val))
    if (ref && val.length === 2) {
      setTimeout(() => {
        if (ref) {
          ref.current.focus()
        }
      }, 10)
    }
  }

  const handleMonth = (event) => {
    let month = maxLengthCheck(event.target.value, 2)
    let ref = null

    if (shouldPreventDefault(month, setMonth)) {
      event.preventDefault()
      return
    }

    const list = getList(
      isValidDay,
      dayVal,
      isValidYear,
      yearVal,
      false,
      getExcludedMonths
    )

    if (list.includes(Number(monthVal)) || !isValidMonth(month)) {
      if (month.length === 2) {
        month = '0' + month[1]
      }
    } else {
      ref = dayRef
    }
    setFinalInputVal(setMonth, month, ref, setMonthWidth)
  }

  const handleDay = (event) => {
    let day = maxLengthCheck(event.target.value, 2)
    let ref = null

    if (shouldPreventDefault(day, setDay)) {
      event.preventDefault()
      return
    }

    const list = getList(
      isValidMonth,
      monthVal,
      null,
      null,
      null,
      getExcludedDays
    )
    if (list.includes(Number(day)) || !isValidDay(day)) {
      if (day.length === 2) {
        day = '0' + day[1]
      }
    } else {
      ref = yearRef
    }
    setFinalInputVal(setDay, day, ref, setDayWidth)
  }

  const handleYear = (event) => {
    const year = maxLengthCheck(event.target.value, 4)

    if (shouldPreventDefault(year, setYear)) {
      event.preventDefault()
      return
    }

    const list = getList(
      isValidDay,
      dayVal,
      isValidMonth,
      monthVal,
      null,
      getExcludedYears
    )

    if (list.includes(Number(year)) || !isValidYear(year, true)) {
      setYear('')
    } else {
      // don't validate for every key stroke i.e.,(eg: 1, 19, 199)
      // only validate when reminder is > 0 when divided by 1900
      setYear(year)
    }
  }

  const appendZero = (val) => {
    if (val.length === 1 && val[0] !== '0' && val > 0 && val < 10)
      val = '0' + val
    return val
  }

  const handleMonthBlur = (e) => {
    setTimeout(() => {
      if (!e.relatedTarget && monthVal) {
        const val = isValidMonth(monthVal) ? appendZero(monthVal) : ''
        setMonth(val)
        setMonthWidth(getTextWidth(val))
      }
    }, 0)
  }

  const handleDayBlur = (e) => {
    setTimeout(() => {
      if (!e.relatedTarget && dayVal) {
        const val = isValidDay(dayVal) ? appendZero(dayVal) : ''
        setDay(val)
        setDayWidth(getTextWidth(val))
      }
    }, 0)
  }

  const handleYearBlur = (e) => {
    setTimeout(() => {
      if (!e.relatedTarget) {
        if (!isValidYear(yearVal, false)) {
          setYear('')
        } else {
          if (yearVal) {
            setYear(yearVal)
          }
        }
      }
    }, 0)
  }

  const setAllNull = () => {
    setDay('')
    setMonth('')
    setYear('')
  }

  /**
   * This function returns true if setting of date, month, year to previous valid date is successfull.
   *    * We reset the date to previous valid date if user clears all the fields
   * In order to reset :
   * 1. The prevDayVal, prevMonthVal, prevYearVal should not be null or invalid
   * 2. Atleast one of dayVal, monthVal, yearVal have to be null
   */
  const resetToPrev = () => {
    let d = dayVal
    let m = monthVal
    let y = yearVal
    if (!dayVal) {
      setDay(prevDayVal)
      d = prevDayVal
    }
    if (!monthVal) {
      setMonth(prevMonthVal)
      m = prevMonthVal
    }
    if (!yearVal) {
      setYear(prevYearVal)
      y = prevYearVal
    }
    validateDayMonthYear(d, m, y)
  }

  const finalValidation = () => {
    let err
    let errMsg
    let date = ''
    let day = Number(dayVal)
    let month = Number(monthVal)
    let year = Number(yearVal)

    if (
      hasError &&
      !isAllValid() &&
      day.length === 2 &&
      month.length === 2 &&
      year.length === 4
    ) {
      err = true
      errMsg = errorMsg || ''
    } else if (
      questionStatus === 'absent' ||
      (questionStatus === 'valid' && id.toString().search('Beneficiary'))
    ) {
      // hard coding the above check as we only allow null string to be valid for DOB in Beneficiary
      if (isAllNull()) {
        err = false
        errMsg = ''
      } else if (isAnyNull()) {
        day = null
        month = null
        year = null
        setAllNull()
        err = false
        errMsg = ''
      } else if (hasError) {
        err = true
      }
    } else if (isAllNull()) {
      if (!isPrevValNull()) {
        resetToPrev()
        return
      } else {
        setAllNull()
        day = null
        month = null
        year = null
        err = true
      }
    } else if (isAnyNull()) {
      // check which of the current values is null and replace it with valid prevValue if exists
      // else throw make everything null
      if (!day && prevDayVal && isValidDay(prevDayVal)) {
        day = appendZero(prevDayVal)
        setDay(day)
      }
      if (!month && prevMonthVal && isValidMonth(prevMonthVal)) {
        month = appendZero(prevMonthVal)
        setMonth(month)
      }
      if (!year && prevYearVal && isValidYear(prevYearVal)) {
        setYear(prevYearVal)
        year = prevYearVal
        if (prevYearVal > maxYear) {
          err = true
          errMsg = errorMsg
        }
      }
      if (!(day && month && year)) {
        setAllNull()
        setPrevNull()
        day = null
        month = null
        year = null
        err = true
      }
    } else if (!isValidYear(year.toString(), false)) {
      year = null
      err = true
    } else {
      err = false
      errMsg = ''
    }
    const errorObj = { error: err, errorMsg: errMsg }
    date = utils.formatDate(month + '/' + day + '/' + year, formatValue)
    date = utils.responseDateFormat(date, formatValue, responseFormatValue)
    if (isNaN(date)) {
      date = ''
    }
    setError(err)
    setErrorMsg(errMsg)
    onChange(date, errorObj)
    validateDayMonthYear()
  }

  const onWrapperBlur = (e) => {
    const currentTarget = e.currentTarget
    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        if (e.relatedTarget === null) {
          finalValidation()
        }
      }
    }, 0)
  }

  const handlekeyDown = (e, currVal, prevRef, nextRef) => {
    if (e.key === 'Backspace') {
      if (currVal === '') {
        prevRef.current.focus()
      }
    } else if (e.key === 'ArrowLeft') {
      if (e.target.selectionStart === 0 && prevRef) {
        // left key is pressed
        setTimeout(() => {
          prevRef.current.focus()
        }, 0)
        prevRef.current.selectionStart = prevRef.current.maxLength
        prevRef.current.selectionEnd = prevRef.current.maxLength
      }
    } else if (e.key === 'ArrowRight') {
      // right key is pressed
      if (e.target.selectionStart === e.target.maxLength && nextRef) {
        nextRef.current.selectionStart = 1
        nextRef.current.selectionEnd = 1
        setTimeout(() => {
          nextRef.current.focus()
        }, 0)
      }
    } else if (isNaN(e.key) && e.key !== 'Tab') {
      e.preventDefault()
    }
  }

  const handleOnInput = (d, m, y) => {
    validateDayMonthYear(d, m, y)
  }

  // ARIA specific props
  let ariaProps = {
    'aria-label': stripHtml(labelText),
    'aria-required': required && required.value
  }
  const ariaDescibebyErrorId = `${id}-error`
  if (hasError) {
    ariaProps = {
      ...ariaProps,
      'aria-invalid': hasError,
      'aria-describedby': ariaDescibebyErrorId
    }
  }

  return (
    <ThemeProvider
      theme={{
        hasError,
        corner,
        hasPlaceholder,
        color,
        width,
        errorMessageColor
      }}
    >
      <Wrapper name='wrappper'>
        <ContentWrapper name='content-wrapper'>
          <Label id={`date-input-label-${id}`}>{labelText}</Label>
          <InputWrapper
            id={`date-input-field-${id}`}
            name='input-wrapper'
            docWidth={docWidth}
          >
            <InputComponents
              name='input-components'
              docWidth={docWidth}
              onBlur={onWrapperBlur}
            >
              <DummyInput
                id={id}
                type='date'
                value={yearVal + monthVal + dayVal}
              />
              <Input
                name='month'
                type='text'
                inputMode='numeric'
                autocomplete='off'
                ref={monthRef}
                width={monthWidth || getTextWidth(monthVal, 'month')}
                placeholder={monthPlaceholder}
                value={monthVal}
                maxLength={2}
                onInput={(e) => handleOnInput(dayVal, e.target.value, yearVal)}
                onChange={handleMonth}
                id='month'
                onKeyDown={(e) => handlekeyDown(e, monthVal, yearRef, dayRef)}
                onBlur={handleMonthBlur}
                {...ariaProps}
              />
              <Separator>/</Separator>
              <Input
                name='day'
                type='text'
                inputMode='numeric'
                autocomplete='off'
                ref={dayRef}
                width={dayWidth || getTextWidth(dayVal, 'day')}
                placeholder={dayPlaceholder}
                value={dayVal}
                maxLength={2}
                onInput={(e) =>
                  handleOnInput(e.target.value, monthVal, yearVal)
                }
                onChange={handleDay}
                id='day'
                onKeyDown={(e) => handlekeyDown(e, dayVal, monthRef, yearRef)}
                onBlur={handleDayBlur}
                {...ariaProps}
              />
              <Separator name='separator'>/</Separator>
              <Input
                name='year'
                type='text'
                inputMode='numeric'
                autocomplete='off'
                ref={yearRef}
                placeholder={yearPlaceholder}
                value={yearVal}
                maxLength={4}
                onInput={(e) => handleOnInput(dayVal, monthVal, e.target.value)}
                onChange={handleYear}
                onKeyDown={(e) => handlekeyDown(e, yearVal, dayRef, monthRef)}
                onBlur={handleYearBlur}
                {...ariaProps}
              />
            </InputComponents>
          </InputWrapper>
        </ContentWrapper>
        <ErrorMessage id={ariaDescibebyErrorId} data-testid='error-message'>
          {errorMsg}
        </ErrorMessage>
      </Wrapper>
    </ThemeProvider>
  )
}

DateInput.defaultProps = {
  labelText: 'Date of Birth',
  labelPosition: 'top',
  disabled: false,
  response: '',
  responseFormat: 'YYYY-MM-DD',
  required: {
    value: true,
    error_message: 'This is a required field.'
  },
  minValue: {
    value: '01/01/1919',
    error_message: 'Date should be greater than 01/01/1919.'
  },
  maxValue: {
    value: moment().format('MM/DD/YYYY'),
    error_message: `Date should be less than ${moment().format('MM/DD/YYYY')}.`
  },
  autoFormat: {
    type: 'date',
    value: 'MM/DD/YYYY',
    error_message: ''
  },
  customError: {},
  color: 'blue',
  width: 'M',
  corner: 'round'
}

DateInput.propTypes = {
  /**
   * `id` is required for datepicker field.
   */
  id: PropTypes.string.isRequired,
  /**
   * Default value send to the datepicker field.
   */
  response: PropTypes.string.isRequired,
  /**
   * Placeholder text for the datepicker field.
   */
  placeholder: PropTypes.string.isRequired,
  /**
   * A datepicker change handler to exchange value entered by the end user.
   */
  onChange: PropTypes.func.isRequired,
  /**
   * A datepicker input field change handler to exchange raw input values entered by the end user.
   */
  onValueChangeRaw: PropTypes.func.isRequired,
  /**
   * Response format required by backend to parse date value.
   */
  responseFormat: PropTypes.string,
  /**
   * An object determining if datepicker value is required or not and
   * an error message associated with the validation.
   */
  required: PropTypes.shape({
    value: PropTypes.bool.isRequired,
    error_message: PropTypes.string
  }),
  /**
   * An object containing maximum date value allowed in the datepicker field and
   * an error message associated with the validation.
   */
  maxValue: PropTypes.shape({
    value: PropTypes.string,
    error_message: PropTypes.string
  }),
  /**
   * An object containing minimum date value allowed in the datepicker field and
   * an error message associated with the validation.
   */
  minValue: PropTypes.shape({
    value: PropTypes.string,
    error_message: PropTypes.string
  }),
  /**
   * Auto format is used to determine the datepicker `picker` format (like Month/Date/Year or Month/Year),
   * and allows the date to be formatted in given format.
   */
  autoFormat: PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.string.isRequired,
    error_message: PropTypes.string
  }),
  /**
   * If present, initialise the error state with this object.
   */
  customError: PropTypes.shape({
    error: PropTypes.bool.isRequired,
    error_message: PropTypes.string.isRequired
  }),
  /**
   * Determines the color theme of datepicker field.
   */
  color: PropTypes.string,
  /**
   * Determines the appearance of edge of the box.
   */
  corner: PropTypes.oneOf(['round', 'sharp']),
  /**
   * Width of the input field
   */
  width: PropTypes.oneOf(['S', 'M', 'L', 'XL']),

  /**
   * Determines the color of error message.
   */
  errorMessageColor: PropTypes.string
}

export default DateInput
