import React, { Fragment, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { validateInnerHTML } from '../../utils'
import {
  ProductCardDetail,
  ProductIconWrapper,
  Title,
  ProductDescription,
  ProductContent,
  ViewMore,
  QuoteButtonWrapper,
  QuoteButton,
  ProductCardWrapper,
  Input,
  CheckMark,
  ProductIcon
} from './styles'

const ProductCard = ({
  id,
  labelText,
  ref,
  onChange,
  checked,
  name,
  productDetails
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [showViewMore, setShowViewMore] = useState(true)

  const contentRef = useRef(null)
  const hasMoreDescription = () =>
    contentRef?.current?.scrollHeight > contentRef?.current?.clientHeight

  useEffect(() => {
    setShowViewMore(hasMoreDescription())
  }, [])

  const handelToggleView = (e) => {
    setIsCollapsed(!isCollapsed)
  }

  const onTransitionEnd = () => {
    setShowViewMore(hasMoreDescription() || !isCollapsed)
  }

  return (
    <Fragment>
      <Input
        id={id}
        ref={ref}
        type='radio'
        name={name}
        onChange={(e) => {
          onChange(e, productDetails.id)
        }}
        defaultChecked={checked}
      />
      <ProductCardWrapper id={`product-wrapper-${id}`}>
        <ProductCardDetail id={`product-details-${id}`}>
          <ProductIconWrapper>
            <ProductIcon id={`product-icon-${id}`} url={productDetails.image} />
            <CheckMark />
          </ProductIconWrapper>
          <ProductContent
            ref={contentRef}
            onTransitionEnd={onTransitionEnd}
            isCollapsed={isCollapsed}
          >
            <Title id={`product-title-${id}`} title={labelText}>
              {labelText}
            </Title>
            <ProductDescription
              id={`product-description-${id}`}
              dangerouslySetInnerHTML={{
                __html: validateInnerHTML(productDetails.description)
              }}
            />
          </ProductContent>
          {showViewMore && (
            <ViewMore
              id={`btn-view-more-less-${id}`}
              onClick={handelToggleView}
              isCollapsed={isCollapsed}
            >
              {isCollapsed ? 'View More' : 'View Less'}
            </ViewMore>
          )}
        </ProductCardDetail>
        <QuoteButtonWrapper
          id={`btn-select-product-${id}`}
          className='quote-button-wrapper'
        >
          <QuoteButton className='quote-button'>
            {checked
              ? productDetails.button_selected_text
              : productDetails.button_text}
          </QuoteButton>
        </QuoteButtonWrapper>
      </ProductCardWrapper>
    </Fragment>
  )
}

ProductCard.propTypes = {
  /**
   * Label of the radio button.
   */
  labelText: PropTypes.string.isRequired,
  /**
   * An id for the button.
   */
  id: PropTypes.string.isRequired,
  /**
   * Ref for Radio buttons.
   */
  ref: PropTypes.object,
  /**
   * A name for the buttons.
   */
  name: PropTypes.string.isRequired,
  /**
   * On change handler
   */
  onChange: PropTypes.func.isRequired,
  /**
   * productDetails object
   */
  productDetails: PropTypes.object.isRequired
}

export default ProductCard
