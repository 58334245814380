import React from 'react'
import MaskedInput from 'react-text-mask'
import utils from './utils'

export default function getInput(options) {
  const {
    displayMaskedInput,
    type,
    inputRef,
    inpPlaceholder,
    hasError,
    maskedValue,
    handleChange,
    handleKeyDown,
    handleKeyUp,
    handleFocus,
    handleBlur,
    displayType,
    valueProps,
    ariaProps,
    maxLength,
    ...props
  } = options

  const commonProps = {
    ref: inputRef,
    name: options.id,
    'data-testid': 'user-input',
    placeholder: inpPlaceholder,
    'data-error': hasError,
    onChange: handleChange,
    onKeyDown: handleKeyDown,
    onKeyUp: handleKeyUp,
    onFocus: handleFocus,
    onBlur: handleBlur,
    inputMode: displayType,
    ...valueProps,
    ...ariaProps
  }

  const { value: _mask, type: ziptype = '' } = props.autoFormat

  const isSpecialChar = (char) => {
    // checks for special characters like '-', '/', ':' which we use as a separator for phone numbers, SSN fields
    // eslint-disable-next-line
    const format = /[ `!@#$%&*()_+\-\[\]{};':"\\|,.<>\/?~]/
    return format.test(char)
  }

  const onKeyDown = (e) => {
    // Get cursor position and key code
    const cursorPos = Number(e.target.selectionStart)
    const keyCode = Number(e.keyCode)

    // Do work only on backspace key and on cursor position 4 and 7 for '-')

    if (
      _mask &&
      isSpecialChar(_mask[cursorPos - 1]) &&
      keyCode === 8 &&
      (!ziptype || ziptype !== 'am-zipcode') // QUICK FIX added. Needs refactoring)
    ) {
      e.preventDefault()
      e.target.value = e.target.value.substring(0, e.target.value.length - 1)
    }
  }

  // masking incase we have to manage two different length pattern
  const maskFun = (rawInput) => {
    if (_mask && _mask.includes('-')) {
      const maskValue = _mask.split('-')[0]
      const len = maskValue.length
      if (rawInput.length === len) {
        return utils.getMask(maskValue)
      }
    }
    return utils.getMask(_mask)
  }

  if (displayMaskedInput) {
    return (
      <MaskedInput
        type='text'
        mask={ziptype && ziptype === 'am-zipcode' ? maskFun : maskedValue}
        guide={false}
        {...commonProps}
        onKeyDown={(e) => onKeyDown(e)}
        {...props}
      />
    )
  }
  if (type === 'text' || type === 'number' || type === 'password') {
    return (
      <input
        type={type}
        {...commonProps}
        {...props}
        {...(maxLength.restrict ? { maxLength: maxLength.value } : {})}
      />
    )
  }
  if (type === 'text_area') {
    const { otherprops, ..._props} = props
    const { placeholder, ..._commonProps } = commonProps
    return <textarea rows={otherprops.rows} {..._commonProps} {..._props} />
  }
  return <div>Test</div>
}
