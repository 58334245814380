import React, { useRef, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import { FocusTrap } from '../FocusTrap'
import {
  Overlay,
  Content,
  CloseIcon,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalChildrenWrapper
} from './styles'
import { parseHtml, useDocumentWidth } from '../../utils'

const Modal = ({
  handleClose,
  modalHeader,
  modalBody,
  modalContent,
  modalFooter,
  alignContent,
  alignFooter,
  width,
  border,
  height,
  zIndex,
  maxHeight,
  isHeightFixed,
  disableOverlayClick,
  showModalClose = true,
  overlayBgColor,
  hasbodyOutline,
  margin,
  overlayLeft,
  overLaytop,
  overlayRight,
  overlayBottom,
  bodyFontSize,
  bodyPadding,
  ...rest
}) => {
  const wrapperRef = useRef(null)
  const bodyRef = useRef(null)
  const childRef = useRef(null)
  const contentRef = useRef(null)

  useEffect(() => {
    document.querySelector('html').style.overflow = 'hidden'
    if (hasbodyOutline) {
      if (modalBody) {
        bodyRef.current.setAttribute('tabindex', '0')
        bodyRef.current.focus()
        contentRef.current.scrollIntoView()
      } else {
        childRef.current.setAttribute('tabindex', '0')
        childRef.current.focus()
      }
    }
    return () => {
      document.querySelector('html').style.overflow = 'auto'
    }
  }, [])

  useEffect(() => {
    document.addEventListener('click', handleOverlayClick)
    return () => {
      document.removeEventListener('click', handleOverlayClick)
    }
  }, [wrapperRef])

  const handleOverlayClick = (e) => {
    if (wrapperRef.current === e.target && !disableOverlayClick) {
      handleClose()
    }
  }

  const handleCloseIconClick = (event) => {
    event.preventDefault()
    if (event.key === 'Enter') {
      handleClose()
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      handleClose()
    }
  }

  const docWidth = useDocumentWidth()

  return (
    <FocusTrap>
      <Overlay
        name='over-lay'
        id='modalContainer'
        ref={wrapperRef}
        onKeyDown={handleKeyDown}
        onClick={handleOverlayClick}
        overlayBgColor={overlayBgColor}
        tabindex='-1'
        role='dialog'
        aria-labelledby='exampleModalLabel'
        focus
        left={overlayLeft || 0}
        top={overLaytop || 0}
        right={overlayRight || 0}
        bottom={overlayBottom || 0}
        zIndex={zIndex}
      >
        <Content
          name='content'
          alignContent={alignContent}
          width={width || docWidth}
          border={border}
          margin={margin}
          height={height}
          maxHeight={maxHeight}
          isHeightFixed={isHeightFixed}
          ref={contentRef}
        >
          {showModalClose && (
            <>
              <CloseIcon
                onKeyDown={handleCloseIconClick}
                tabIndex='0'
                role='button'
                aria-label='Close modal'
                name='close-icon'
                onClick={handleClose}
              >
                &times;
              </CloseIcon>
              <div style={{ height: '38px' }} />
            </>
          )}
          {modalHeader && (
            <ModalHeader name='modal-header'>
              {typeof modalHeader === 'object'
                ? modalHeader
                : parseHtml(modalHeader)}
            </ModalHeader>
          )}
          {modalBody && (
            <ModalBody
              name='modal-body'
              id='modal-body'
              role='text'
              ref={bodyRef}
              fontSize={bodyFontSize}
              bodyPadding={bodyPadding}
            >
              {typeof modalBody === 'object' ? modalBody : parseHtml(modalBody)}
            </ModalBody>
          )}
          {
            <ModalChildrenWrapper id='modal-child' role='text' ref={childRef}>
              {rest.children}
            </ModalChildrenWrapper>
          }
          {modalFooter && (
            <ModalFooter name='modal-footer' alignFooter={alignFooter}>
              {modalFooter}
            </ModalFooter>
          )}
        </Content>
      </Overlay>
    </FocusTrap>
  )
}

Modal.defaultProps = {
  modalHeader: '',
  modalBody: '',
  modalFooter: '',
  height: '490px',
  maxHeight: '75vh',
  isHeightFixed: true,
  alignFooter: 'right',
  alignContent: 'left',
  disableOverlayClick: false
}

Modal.propTypes = {
  /**
   * Function triggered on Modal Close
   */
  handleClose: PropTypes.func,
  /**
   * Header/Title of modal
   */
  modalHeader: PropTypes.string,
  /**
   * Body/Content of the modal. Can be a string, component
   */
  modalBody: PropTypes.any,
  /**
   * Footer of modal. Can include string/ Buttons, Action Items
   */
  modalFooter: PropTypes.any,
  /**
   * Align modal content. 'center'| 'left' | 'right'
   */
  alignContent: PropTypes.string,
  /**
   * Align footer content. 'center'| 'left' | 'right'
   */
  alignFooter: PropTypes.string,
  /**
   * It will disable click on overlay if true
   */
  disableOverlayClick: PropTypes.bool,
  /**
   * Height of modal.
   */
  height: PropTypes.string,
  /**
   * Max Height of modal.
   */
  maxHeight: PropTypes.string,
  /**
   * Determines if modal height is fixed or variable
   */
  isHeightFixed: PropTypes.bool,
  /**
   * By default if width is not provided then the width will be based on the document's width
   * Width of modal. 'L'| 'M' | 'S' |'XL'
   */
  width: PropTypes.oneOf(['S', 'M', 'L', 'XL'])
}

export default Modal
