export interface LogoutResponse {
    code: number;
    errors: string;
    data: LogoutResponseData;
}

interface LogoutResponseData {
   redirectUrl?: RedirectURL;
   renderForm?: RenderForm;
   serverName: string;
   serverType: string;
}

interface RedirectURL {
    url: string;
}

interface RenderForm {
  form: string;
}

export function getLogoutResponseFromJson(json: any): LogoutResponse {
    return !!json ? {
        code: json.code,
        errors: json.errors,
        data: getLogoutResponseDataFromJson(json.data)
    } : null;
}

function getLogoutResponseDataFromJson(json: any): LogoutResponseData {
    return !!json ? {
        redirectUrl: getRedirectUrlFromJson(json.redirect),
        renderForm: getRedirectFormFromJson(json.render),
        serverName: json.srv_name,
        serverType: json.srv_type
    } : null;
}

function getRedirectUrlFromJson(json: any): RedirectURL {
    return !!json ? {
        url: json.url
    } : null;
}

function getRedirectFormFromJson(json: any): RenderForm {
    return !!json ? {
      form: json.form
    } : null;
}
