import React, { useState } from 'react'
import styled from 'styled-components'
import { themes, inputWidth } from '../../themes'
const getWidth = (width) => {
  const w = inputWidth[width]
  const docWidth = window.innerWidth - 100 // +100 for extra padding.
  if (docWidth < w) {
    return `100%`
  }
  return `${w}px`
}

const Wrapper = styled.div`
  ${({ theme: { showSuggestions } }) =>
    !showSuggestions && 'position: absolute'};
  ${({ theme: { inputBorder } }) => inputBorder && `border: ${inputBorder}`};
  &:focus-within {
    ${({ theme: { borderOnFocus } }) => `border: ${borderOnFocus};`}
  }
  outline: none;
  border: 1px solid #cccccc;
  color: #333333;
  background: #ffffff;
  width: ${({ theme: { width } }) => getWidth(width)};
  z-index: 109999;
  margin-top: -10px;
  ${({ theme: { showSuggestions } }) =>
    showSuggestions && 'margin: 10px 0 10px'};
  border-radius: 4px;
  max-height: ${({ count }) => `${parseInt(count) * 40}px`};
  overflow-y: scroll;
`

const Option = styled.div`
  min-height: 40px;
  width: 100%;
  line-height: 40px;
  padding-left: 10px;
  max-height: 40px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  cursor: default;
  outline: none;
  ${({ active, theme: { color, selectedBgColor, fontColor } }) =>
    active &&
    `background: ${selectedBgColor || themes[color].selectOptionBg};
    color:  ${fontColor || '#FFFFFF'};
    cursor: pointer;
    `}
  ${({ disabled, theme: { color, selectedBgColor, fontColor } }) =>
    !disabled &&
    `
    &:hover {
    background: ${selectedBgColor || themes[color].selectOptionBg};
    color:  ${fontColor || '#FFFFFF'};
    cursor: pointer;
  }
`};
`
const Options = ({ maxOptionsCount, options, onClick }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const activateTab = (activeIndex) => {
    const { id } = options[activeIndex]
    const tabEle = document.getElementById(id)
    if (tabEle) {
      tabEle.focus()
    }
    setActiveIndex(activeIndex)
  }

  const handleKeyDown = (e, option) => {
    let index = activeIndex
    if (e.which === 38) {
      e.preventDefault()
      if (index > 0) {
        index -= 1
      }
    } else if (e.which === 40) {
      e.preventDefault()
      if (index < options.length - 1) {
        index += 1
      }
    } else if (e.key === 'Enter' && !option.disabled) {
      onClick(option)
    }
    activateTab(index)
  }
  return (
    <Wrapper tabIndex='-1' count={maxOptionsCount}>
      {options.map((o, index) => (
        <Option
          id={o.id}
          disabled={o.disabled}
          aria-label={o.label}
          tabIndex={index === activeIndex ? '0' : '-1'}
          onKeyDown={(event) => handleKeyDown(event, o)}
          active={index === activeIndex}
          onClick={() => !o.disabled && onClick(o)}
          key={o.id}
          title={o.label}
        >
          {o.label}
        </Option>
      ))}
    </Wrapper>
  )
}

export default Options
