const updateOptions = (options) => {
  return options.map((o) => {
    return {
      ...o,
      value: o.value || o.id
    }
  })
}

const getDefaultSelected = (props) => {
  return props.defaultSelected !== undefined
    ? props.defaultSelected
    : props.type === 'M'
    ? []
    : {
        label: '',
        id: ''
      }
}

const customSelectOptions = [
  {
    id: 'IN',
    label: 'India',
    disabled: false
  },
  {
    id: 'US',
    label: 'USA',
    disabled: false
  },
  {
    id: 'UK',
    label: 'United Kingdom',
    disabled: false
  },
  {
    id: 'GR',
    label: 'Germany',
    disabled: false
  },
  {
    id: 'SP',
    label: 'Spain (disabled)',
    disabled: true
  },
  {
    id: 'AL',
    label: 'Albania',
    disabled: true
  },
  {
    id: 'CAN',
    label: 'Canada',
    disabled: false
  },
  {
    id: 'BR',
    label: 'Brazil',
    disabled: false
  },
  {
    id: 'AUS',
    label: 'Australia',
    disabled: true
  }
]

export { updateOptions, getDefaultSelected, customSelectOptions }
