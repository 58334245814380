import styled, { createGlobalStyle } from 'styled-components'

const TabGrid = styled.div`
  display: grid;
  grid-template-columns: ${({ colNum }) => `3fr repeat(${colNum - 1},1fr)`};
  grid-auto-rows: 52px;

  /* logic for adding alternate background color to rows */
  ${({ colNum }) => {
    let selector = ''
    for (let i = 1; i <= colNum; i++) {
      selector += `.grid-cell:nth-child(${2 * colNum}n+${colNum + i})${
        i < colNum ? ',' : ''
      }`
    }
    return `${selector}{
        background-color:#f7f7f7;
    }`
  }}

  >.grid-cell {
    display: flex;
    align-items: center;
    padding: 4px;
    font-size: 16px;
    @media(max-width: 1024px){
      font-size: ${({ colNum }) => colNum > 4 ? '12px' : '16px'};
    color: #454545;
    justify-content: flex-end;
  }
  > .grid-cell.header-cell {
    font-weight: bold;
    font-size: 14px;
    > span:first-of-type {
      padding: 6px 14px;
      text-align: right;
      white-space: nowrap;
    }
    > span:last-of-type {
      min-width: 14px;
    }
  }
`
const ClickableCellWrapper = styled.div`
  display: flex;
  align-items: center;
`
const CellContent = styled.div`
  cursor: pointer;
  padding: 4px 8px;
  margin-right: 2px;
  border: 4px solid transparent;
  border-radius:6px;
  &:focus {
      border: 4px solid #d4d4d4;
      outline: none;
  }
  ${({isSelected}) => isSelected && `
      color: #ffff;
      background-color: #00534c;
  `}
`

const CellContentIcon = styled.div`
  min-width: 14px;
  & svg.checkmark {
    width: 14px;
  }
`

const EmptyTabWrapper = styled.div`
  padding: 24px;
  font-size: 16px;
`

const GlobalStyles = createGlobalStyle`
.react-tabs__tab {
    padding:12px 24px;
    font-size:16px;
    background-color:#f7f7f7;
    font-weight:normal;
    border-radius:4px 4px 0px 0px;
    color:#767676;
    border-bottom:1px solid #d8d8d8;
    &:focus {
      box-shadow: none;
      border: 4px solid #d4d4d4;
      border-bottom: none;
    }
}
.react-tabs__tab.react-tabs__tab--disabled {
  &:focus {
    box-shadow: none;
    border: 1px solid #d8d8d8;
    border-bottom: 1px solid transparent;
    &:after {
      background: transparent;
    }
  }
}
.react-tabs__tab--selected {
    color:#00534c;
    font-weight:bold;
    border-color:#d8d8d8;
    background-color:#ffff;
    border-bottom:1px solid transparent;
}
.react-tabs__tab-list {
    border-bottom-color:#d8d8d8;
}
`

export {
  TabGrid,
  ClickableCellWrapper,
  CellContent,
  CellContentIcon,
  EmptyTabWrapper,
  GlobalStyles
}
