import moment from 'moment'

const utils = {}

/**
 * @description Converts one date value to another date accoding to given formatValue
 * @param date date value
 * @param format date format value
 */
utils.convertDateFormat = (date, formatValue) => {
  return moment(date).format(formatValue)
}

/**
 * @description Comapres two date objects
 * @param {Date} date1 First Date Object
 * @param {Date} date2  Second Date Object
 */
utils.compareDates = (date1, date2) => {
  return date1.getTime() === date2.getTime()
}

/**
 * @description Does strict checking for date with the given format
 * @param date date value
 * @param format date format value
 */
utils.strictDateFormatCheck = (date, formatValue) => {
  return moment(date, formatValue, true).isValid()
}

/**
 * @description Formats dateField data according to the value required by the datepicker component.
 * @param date date value
 * @param format date format value
 */
utils.formatDate = (date, formatValue) => {
  if (!date) return
  if (!utils.strictDateFormatCheck(date, formatValue))
    date = utils.convertDateFormat(date, formatValue)
  return new Date(moment(date, formatValue))
}

/**
 * @description Formats dateField date according to the response value
 * @param date date value
 * @param formatValue date format value
 * @param responseFormatValue response format value
 */
utils.responseDateFormat = (date, formatValue, responseFormatValue) => {
  if (!date) return
  const dateValue = moment(date, formatValue)
  if (dateValue.isValid()) return dateValue.format(responseFormatValue)
  return utils.formatDate(date, formatValue)
}

/**
 * @description Checks if the popper is of month year format.
 * @param popperFormatValue popper format value
 */
utils.checkMonthYearFormat = (popperFormatValue) => {
  if (!popperFormatValue) return false
  return (
    popperFormatValue === 'MM/YY' ||
    popperFormatValue === 'YY/MM' ||
    popperFormatValue === 'MM/YYYY' ||
    popperFormatValue === 'YYYY/MM'
  )
}

/**
 * @description Checks if the popper is of year format.
 * @param popperFormatValue popper format value
 */
utils.checkYearFormat = (popperFormatValue) => {
  if (!popperFormatValue) return false
  return popperFormatValue === 'YY' || popperFormatValue === 'YYYY'
}

/**
 * @description Returns YY from Date
 * @param dateValue
 */
utils.getShortYear = (dateValue) => {
  return parseInt(dateValue.getFullYear().toString().substr(-2), 10)
}

/**
 * @description Checks if the popper is of day month format.
 * @param popperFormatValue popper format value
 */
utils.checkDayMonthFormat = (popperFormatValue) => {
  if (!popperFormatValue) return false
  return !popperFormatValue.includes('Y')
}

/**
 * @description Validates date input
 * @param props props passed to the component
 * @param dateValue date value
 * @param formatValue date format value
 */
utils.validateDateInput = (props, dateValue, formatValue) => {
  const { format, minValue, maxValue, required } = props
  const momentDate = moment(dateValue, formatValue, true)

  if (format) {
    const { error_message: errorMessage } = format

    if (dateValue && !momentDate.isValid()) return { error: true, errorMessage }
  }

  if (required) {
    const { value: isRequired, error_message: errorMessage } = required

    if (!dateValue && isRequired) return { error: true, errorMessage }
  }

  if (!utils.checkDayMonthFormat(formatValue.toUpperCase())) {
    if (maxValue) {
      let { value, error_message: errorMessage } = maxValue
      if (!utils.strictDateFormatCheck(value, formatValue))
        value = utils.convertDateFormat(value, formatValue)
      const momentMaxDate = moment(value, formatValue)

      if (dateValue && momentDate > momentMaxDate)
        return { error: true, errorMessage }
    }

    if (minValue) {
      let { value, error_message: errorMessage } = minValue
      if (!utils.strictDateFormatCheck(value, formatValue))
        value = utils.convertDateFormat(value, formatValue)
      const momentMinDate = moment(value, formatValue)

      if (dateValue && momentDate < momentMinDate)
        return { error: true, errorMessage }
    }
  }

  return { error: false, errorMessage: '' }
}

utils.getRandomText = () => {
  return Math.random().toString(36)
}
/**
 * @description Converts the date in `dd/MM/yyyy` format combinations.
 * @param formatValue date format value
 */
utils.formatPickerValue = (formatValue) => {
  if (!formatValue) return 'dd/MM/yyyy'
  return formatValue.toLowerCase().replace(/m/gi, 'M')
}

export default utils
