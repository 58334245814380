import styled from 'styled-components'

const getToastColor = (type) => {
  switch (type) {
    case 'success':
      return '#8bc348'
    case 'warning':
      return '#E0954C'
    case 'error':
      return '#ff0000'
    case 'info':
      return '#55ABC5'
    default:
      return 'ff0000'
  }
}

const Wrapper = styled.div`
  width: ${({ theme }) => (theme.size && theme.size === 'L' ? '90%' : '300px')};
  min-height: 75px;
  position: fixed;
  top: 50px;
  right: ${({ theme }) => (theme.size && theme.size === 'L' ? '50%' : '50px')};
  background: ${({ type }) => getToastColor(type)};
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.size === 'L' && `transform: translateX(50%)`};
  border-radius: ${({ theme }) =>
    theme.shape && theme.shape === 'rectangle' ? '0px' : '8px'};
  color: #ffffff;
  z-index: 7111990;
  ${({ theme }) => theme.customColor && `background: ${theme.customColor};`}
`

const Message = styled.div`
  padding: 5px 0 0 10px;
`

const IconMsgDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const CloseIconDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const Cancel = styled.span`
  height: 34px;
  width: 34px;
  display: none;
  align-items: center;
  padding-right: '10px';
  ${({ theme }) => theme.showCancel && `display: flex;`}
`

const Iconstyle = {
  fontSize: '22px',
  paddingLeft: '10px'
}

const CancelStyle = {
  fontSize: '28px',
  cursor: 'pointer'
}

export {
  Wrapper,
  Message,
  IconMsgDiv,
  CloseIconDiv,
  Iconstyle,
  CancelStyle,
  Cancel
}
