import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Week from './week'
import * as utils from './date_utils'
import styles from './stylesheets/react-datepicker.module.css'
import { getYear } from './date_utils'

const FIXED_HEIGHT_STANDARD_WEEK_COUNT = 6

export default class Month extends React.Component {
  static propTypes = {
    ariaLabelPrefix: PropTypes.string,
    chooseDayAriaLabelPrefix: PropTypes.string,
    disabledDayAriaLabelPrefix: PropTypes.string,
    disabledKeyboardNavigation: PropTypes.bool,
    day: PropTypes.instanceOf(Date).isRequired,
    dayClassName: PropTypes.func,
    endDate: PropTypes.instanceOf(Date),
    orderInDisplay: PropTypes.number,
    excludeDates: PropTypes.array,
    filterDate: PropTypes.func,
    fixedHeight: PropTypes.bool,
    formatWeekNumber: PropTypes.func,
    highlightDates: PropTypes.instanceOf(Map),
    includeDates: PropTypes.array,
    inline: PropTypes.bool,
    locale: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({ locale: PropTypes.object })
    ]),
    maxDate: PropTypes.instanceOf(Date),
    minDate: PropTypes.instanceOf(Date),
    onDayClick: PropTypes.func,
    onDayMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onWeekSelect: PropTypes.func,
    peekNextMonth: PropTypes.bool,
    preSelection: PropTypes.instanceOf(Date),
    selected: PropTypes.instanceOf(Date),
    selectingDate: PropTypes.instanceOf(Date),
    selectsEnd: PropTypes.bool,
    selectsStart: PropTypes.bool,
    showWeekNumbers: PropTypes.bool,
    startDate: PropTypes.instanceOf(Date),
    setOpen: PropTypes.func,
    shouldCloseOnSelect: PropTypes.bool,
    renderDayContents: PropTypes.func,
    showMonthYearPicker: PropTypes.bool,
    showFullMonthYearPicker: PropTypes.bool,
    showQuarterYearPicker: PropTypes.bool,
    handleOnKeyDown: PropTypes.func,
    isInputFocused: PropTypes.bool,
    weekAriaLabelPrefix: PropTypes.string,
    containerRef: PropTypes.oneOfType([
      PropTypes.func,
      // eslint-disable-next-line no-undef
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }

  monthRef = React.createRef()

  constructor(props) {
    super(props)
    this.state = {
      currentMonth: utils.getMonth(this.props.preSelection)
    }
  }

  componentDidMount() {
    const selectedMonth = document.getElementById(this.props.day.getMonth())
    if (selectedMonth) {
      selectedMonth.focus()
    }
  }

  handleDayClick = (day, event) => {
    if (this.props.onDayClick) {
      this.props.onDayClick(day, event, this.props.orderInDisplay)
    }
  }

  handleDayMouseEnter = (day) => {
    if (this.props.onDayMouseEnter) {
      this.props.onDayMouseEnter(day)
    }
  }

  handleMouseLeave = () => {
    if (this.props.onMouseLeave) {
      this.props.onMouseLeave()
    }
  }

  isRangeStartMonth = (m) => {
    const { day, startDate, endDate } = this.props
    if (!startDate || !endDate) {
      return false
    }
    return utils.isSameMonth(utils.setMonth(day, m), startDate)
  }

  isRangeStartQuarter = (q) => {
    const { day, startDate, endDate } = this.props
    if (!startDate || !endDate) {
      return false
    }
    return utils.isSameQuarter(utils.setQuarter(day, q), startDate)
  }

  isRangeEndMonth = (m) => {
    const { day, startDate, endDate } = this.props
    if (!startDate || !endDate) {
      return false
    }
    return utils.isSameMonth(utils.setMonth(day, m), endDate)
  }

  isRangeEndQuarter = (q) => {
    const { day, startDate, endDate } = this.props
    if (!startDate || !endDate) {
      return false
    }
    return utils.isSameQuarter(utils.setQuarter(day, q), endDate)
  }

  isWeekInMonth = (startOfWeek) => {
    const day = this.props.day
    const endOfWeek = utils.addDays(startOfWeek, 6)
    return (
      utils.isSameMonth(startOfWeek, day) || utils.isSameMonth(endOfWeek, day)
    )
  }

  renderWeeks = () => {
    const weeks = []
    var isFixedHeight = this.props.fixedHeight
    let currentWeekStart = utils.getStartOfWeek(
      utils.getStartOfMonth(this.props.day),
      this.props.locale
    )
    let i = 0
    let breakAfterNextPush = false

    while (true) {
      weeks.push(
        <Week
          ariaLabelPrefix={this.props.weekAriaLabelPrefix}
          chooseDayAriaLabelPrefix={this.props.chooseDayAriaLabelPrefix}
          disabledDayAriaLabelPrefix={this.props.disabledDayAriaLabelPrefix}
          key={i}
          day={currentWeekStart}
          month={utils.getMonth(this.props.day)}
          onDayClick={this.handleDayClick}
          onDayMouseEnter={this.handleDayMouseEnter}
          onWeekSelect={this.props.onWeekSelect}
          formatWeekNumber={this.props.formatWeekNumber}
          locale={this.props.locale}
          minDate={this.props.minDate}
          maxDate={this.props.maxDate}
          excludeDates={this.props.excludeDates}
          includeDates={this.props.includeDates}
          inline={this.props.inline}
          highlightDates={this.props.highlightDates}
          selectingDate={this.props.selectingDate}
          filterDate={this.props.filterDate}
          preSelection={this.props.preSelection}
          selected={this.props.selected}
          selectsStart={this.props.selectsStart}
          selectsEnd={this.props.selectsEnd}
          showWeekNumber={this.props.showWeekNumbers}
          startDate={this.props.startDate}
          endDate={this.props.endDate}
          dayClassName={this.props.dayClassName}
          setOpen={this.props.setOpen}
          shouldCloseOnSelect={this.props.shouldCloseOnSelect}
          disabledKeyboardNavigation={this.props.disabledKeyboardNavigation}
          renderDayContents={this.props.renderDayContents}
          handleOnKeyDown={this.props.handleOnKeyDown}
          isInputFocused={this.props.isInputFocused}
          containerRef={this.props.containerRef}
        />
      )

      if (breakAfterNextPush) break

      i++
      currentWeekStart = utils.addWeeks(currentWeekStart, 1)

      // If one of these conditions is true, we will either break on this week
      // or break on the next week
      const isFixedAndFinalWeek =
        isFixedHeight && i >= FIXED_HEIGHT_STANDARD_WEEK_COUNT
      const isNonFixedAndOutOfMonth =
        !isFixedHeight && !this.isWeekInMonth(currentWeekStart)

      if (isFixedAndFinalWeek || isNonFixedAndOutOfMonth) {
        if (this.props.peekNextMonth) {
          breakAfterNextPush = true
        } else {
          break
        }
      }
    }

    return weeks
  }

  onMonthClick = (e, m) => {
    this.handleDayClick(utils.setMonth(this.props.day, m), e)
  }

  onQuarterClick = (e, q) => {
    this.handleDayClick(
      utils.getStartOfQuarter(utils.setQuarter(this.props.day, q)),
      e
    )
  }

  getMonthClassNames = (m) => {
    const { day, startDate, endDate, selected, minDate, maxDate } = this.props
    return classnames(
      styles['react-datepicker__month-text'],
      styles[`react-datepicker__month-${m}`],
      {
        [styles['react-datepicker__month--disabled']]:
          (minDate || maxDate) &&
          utils.isMonthDisabled(utils.setMonth(day, m), this.props),
        [styles['react-datepicker__month--selected']]:
          utils.getMonth(day) === m &&
          utils.getYear(day) === utils.getYear(selected),
        [styles['react-datepicker__month--in-range']]: utils.isMonthinRange(
          startDate,
          endDate,
          m,
          day
        ),
        [styles[
          'react-datepicker__month--range-start'
        ]]: this.isRangeStartMonth(m),
        [styles['react-datepicker__month--range-end']]: this.isRangeEndMonth(m)
      }
    )
  }

  getQuarterClassNames = (q) => {
    const { day, startDate, endDate, selected, minDate, maxDate } = this.props
    return classnames(
      styles['react-datepicker__quarter-text'],
      styles[`react-datepicker__quarter-${q}`],
      {
        [styles['react-datepicker__quarter--disabled']]:
          (minDate || maxDate) &&
          utils.isQuarterDisabled(utils.setQuarter(day, q), this.props),
        [styles['react-datepicker__quarter--selected']]:
          utils.getQuarter(day) === q &&
          utils.getYear(day) === utils.getYear(selected),
        [styles['react-datepicker__quarter--in-range']]: utils.isQuarterInRange(
          startDate,
          endDate,
          q,
          day
        ),
        [styles[
          'react-datepicker__quarter--range-start'
        ]]: this.isRangeStartQuarter(q),
        [styles[
          'react-datepicker__quarter--range-end'
        ]]: this.isRangeEndQuarter(q)
      }
    )
  }

  handleKeyDown = (e, m) => {
    if (e.key === 'ArrowLeft' && m > 0) {
      // if the current year is equal to the minDate year then ,
      // it should check weather the next navigated month is not an invalid month or disabled month
      if (
        getYear(this.props.preSelection) > getYear(this.props.minDate) ||
        m > utils.getMonth(this.props.minDate)
      ) {
        const previousMonth = document.getElementById(m - 1)
        previousMonth.focus()
        this.setState({
          currentMonth: m - 1
        })
      }
    }
    // if the current year is equal to the maxDate year then,
    // it should check weather the next navigated month is not an invalid month or disabled month
    if (e.key === 'ArrowRight' && m < 11) {
      if (
        getYear(this.props.preSelection) < getYear(this.props.maxDate) ||
        m < utils.getMonth(this.props.maxDate)
      ) {
        const nextMonth = document.getElementById(m + 1)
        nextMonth.focus()
        this.setState({
          currentMonth: m + 1
        })
      }
    }
    if (e.key === 'Enter') {
      this.onMonthClick(e, m)
      this.props.toggleMonthView()
    }
  }

  renderMonths = () => {
    const { toggleMonthView, showFullMonthYearPicker, locale } = this.props
    const months = [
      [0, 1, 2, 3],
      [4, 5, 6, 7],
      [8, 9, 10, 11]
    ]
    return months.map((month, i) => (
      <div className={styles['react-datepicker__month-wrapper']} key={i}>
        {month.map((m, j) => (
          <div
            key={j}
            id={m}
            onClick={(ev) => {
              this.onMonthClick(ev, m)
              toggleMonthView()
            }}
            ariaLabel={utils.getMonthInLocale(m, locale)}
            ref={this.monthRef}
            tabIndex={m === this.state.currentMonth ? 0 : -1}
            onKeyDown={(ev) => {
              this.handleKeyDown(ev, m)
            }}
            className={this.getMonthClassNames(m)}
          >
            {showFullMonthYearPicker
              ? utils.getMonthInLocale(m, locale)
              : utils.getMonthShortInLocale(m, locale)}
          </div>
        ))}
      </div>
    ))
  }

  renderQuarters = () => {
    const quarters = [1, 2, 3, 4]
    return (
      <div className={styles['react-datepicker__quarter-wrapper']}>
        {quarters.map((q, j) => (
          <div
            key={j}
            onClick={(ev) => {
              this.onQuarterClick(ev, q)
            }}
            className={this.getQuarterClassNames(q)}
          >
            {utils.getQuarterShortInLocale(q, this.props.locale)}
          </div>
        ))}
      </div>
    )
  }

  getClassNames = () => {
    const {
      selectingDate,
      selectsStart,
      selectsEnd,
      showMonthYearPicker,
      showQuarterYearPicker,
      monthView
    } = this.props
    return classnames(
      styles['react-datepicker__month'],
      {
        [styles['react-datepicker__month--selecting-range']]:
          selectingDate && (selectsStart || selectsEnd)
      },
      {
        [styles['react-datepicker__monthPicker']]:
          showMonthYearPicker || monthView
      },
      { [styles['react-datepicker__quarterPicker']]: showQuarterYearPicker }
    )
  }

  render() {
    const {
      showMonthYearPicker,
      showQuarterYearPicker,
      day,
      ariaLabelPrefix = 'month ',
      monthView
    } = this.props
    return (
      <div
        className={this.getClassNames()}
        onMouseLeave={this.handleMouseLeave}
        aria-label={`${ariaLabelPrefix} ${utils.formatDate(day, 'yyyy-MM')}`}
      >
        {showMonthYearPicker || monthView
          ? this.renderMonths()
          : showQuarterYearPicker
          ? this.renderQuarters()
          : this.renderWeeks()}
      </div>
    )
  }
}
