import styled from 'styled-components'

const RangeStepperWrapper = styled.div`
  display: flex;
  margin: 8px 0;
`

const StepperValueWrapper = styled.div`
  ${({ labelTheme }) =>
    labelTheme ||
    `
        padding: 6px 8px 6px 16px;
        border: 1px solid #bbb;
        min-width: 120px;
        max-width: 150px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    `}
`

export { RangeStepperWrapper, StepperValueWrapper }
