const mock = {}

mock.html = `
  <div>
    <p>
      If you were no longer here, what would you like your life insurance policy
      to pay for? Some common examples are:
    </p>
    <ul>
      <li>A mortgage or car loan</li>
      <li>Credit card debt</li>
      <li>Medical bills</li>
      <li>Student loans or education expenses</li>
      <a target='_default' href='https://www.google.com'>Link is Here!</a>
    </ul>
  </div>
`

mock.tooltipObj = {
  title: '$Tooltip Title',
  body:
    'Short description of what the tooltip is about goes here (try to keep <240 characters)'
}

export default mock
