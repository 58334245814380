import styled from 'styled-components'

const AccordionContainer = styled.div`
  background: #fff;
`

const AccordionHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-bottom: 1px solid transparent;
  ${({ active }) => active && 'border-bottom: 1px solid #e2e2e2;'}
`

const AccordionHeader = styled.div`
  display: flex;
`

const AccordionHeaderIconWrapper = styled.div`
  ${({ iconTheme }) => iconTheme || `padding: 0px 15px; border: none;`}
  & img {
    max-height: 25px;
  }
`

const AccordionHeaderTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 15px;
  flex: 1;
  background: #e1e8ed;
  border: 1px solid #ddd;
  border-radius: ${({ active }) => (active ? '2px 2px 0 0' : '2px')};
`

const AccordionTogglerWrapper = styled.div`
  width: 15px;
`

const AccordionTogglerIcon = styled.div`
  width: 0;
  height: 0;
  margin: 0 5px;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid;
  transform: rotate(0deg);
  ${({ active }) => active && 'transform: rotate(90deg)'};
  transition: transform 0.3s ease-in-out;
`

const AccordionHeaderTitle = styled.div`
  margin: 0 5px;
`

const AccordionBody = styled.div`
  border: 1px solid #ddd;
  margin-top: -1px;
  padding: 15px;
  display: ${({ active }) => (active ? 'block' : 'none')};
  transition: display 0.3s ease-in-out;
  ${({ isIconPresent }) => isIconPresent && `
    width: calc(100% - 40px);
    float: right;
  `};
`

export {
  AccordionContainer,
  AccordionHeaderWrapper,
  AccordionHeader,
  AccordionHeaderIconWrapper,
  AccordionHeaderTitleWrapper,
  AccordionTogglerWrapper,
  AccordionTogglerIcon,
  AccordionHeaderTitle,
  AccordionBody
}
