/* eslint no-debugger: "warn" */
import cx from 'classnames'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ResizeObserver from 'resize-observer-polyfill'
import { capitalize, clamp } from './utils'
import InputUtils from '../TextInput/utils'
import styles from './styles.module.css'

/**
 * Predefined constants
 * @type {Object}
 */
const constants = {
  orientation: {
    horizontal: {
      dimension: 'width',
      direction: 'left',
      reverseDirection: 'right',
      coordinate: 'x'
    },
    vertical: {
      dimension: 'height',
      direction: 'top',
      reverseDirection: 'bottom',
      coordinate: 'y'
    }
  }
}

class Slider extends Component {
  static propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    value: PropTypes.number,
    orientation: PropTypes.string,
    tooltip: PropTypes.bool,
    reverse: PropTypes.bool,
    labels: PropTypes.object,
    handleLabel: PropTypes.string,
    format: PropTypes.func,
    onChangeStart: PropTypes.func,
    onChange: PropTypes.func,
    onChangeComplete: PropTypes.func
  }

  static defaultProps = {
    min: 0,
    max: 100,
    step: 1,
    value: 0,
    orientation: 'horizontal',
    tooltip: true,
    reverse: false,
    labels: {},
    handleLabel: ''
  }

  constructor(props, context) {
    super(props, context)

    this.state = {
      active: false,
      limit: 0,
      grab: 0,
      valueChanged: false
    }
  }

  componentDidMount() {
    this.handleUpdate()
    const resizeObserver = new ResizeObserver(this.handleUpdate)
    resizeObserver.observe(this.slider)
  }

  handleShowOnlyStepMultiples = (value) => {
    const { showOnlyStepMultiples, stepFromBackEnd } = this.props

    if (showOnlyStepMultiples) {
      const rem = value / stepFromBackEnd
      if (rem === 0) {
        return value
      }
      value = Math.ceil(rem) * stepFromBackEnd
    }
    return value
  }

  /**
   * Format label/tooltip value
   * @param  {Number} - value
   * @return {Formatted Number}
   */
  handleFormat = (value) => {
    const { format, currency } = this.props
    value = this.handleShowOnlyStepMultiples(value)
    return currency
      ? InputUtils.formatCurrency(value, currency.format)
      : format
      ? format(value)
      : value
  }

  convertToCurrencyAbbrivation = (labelValue) => {
    const sign = Math.sign(Number(labelValue))
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? sign * (Math.abs(Number(labelValue)) / 1.0e9) + 'B'
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
      ? sign * (Math.abs(Number(labelValue)) / 1.0e6) + 'M'
      : // Three Zeroes for Thousands
      Math.abs(Number(labelValue)) >= 1.0e3
      ? sign * (Math.abs(Number(labelValue)) / 1.0e3) + 'K'
      : Math.abs(Number(labelValue))
  }

  /**
   * Update slider state on change
   * @return {void}
   */
  handleUpdate = () => {
    if (!this.slider) {
      // for shallow rendering
      return
    }
    const { orientation } = this.props
    const dimension = capitalize(constants.orientation[orientation].dimension)
    const sliderPos = this.slider[`offset${dimension}`]
    const handlePos = this.handle[`offset${dimension}`]

    this.setState({
      limit: sliderPos - handlePos,
      grab: handlePos / 2
    })
  }

  /**
   * Attach event listeners to mousemove/mouseup events
   * @return {void}
   */
  handleStart = (e) => {
    if (!this.props.isQuestionsLoading) {
      const { onChangeStart } = this.props
      document.addEventListener('mousemove', this.handleDrag)
      document.addEventListener('mouseup', this.handleEnd)
      this.setState(
        {
          active: true
        },
        () => {
          if (onChangeStart) {
            onChangeStart(e)
          }
        }
      )
    }
  }

  /**
   * Handle drag/mousemove event
   * @param  {Object} e - Event object
   * @return {void}
   */
  handleDrag = (e) => {
    if (!this.props.isQuestionsLoading) {
      e.stopPropagation()
      const { onChange } = this.props
      const {
        target: { className, classList, dataset }
      } = e
      if (!onChange || className === 'rangeslider__labels') return

      let value = this.position(e)

      if (
        classList &&
        classList.contains('rangeslider__label-item') &&
        dataset.value
      ) {
        value = parseFloat(dataset.value)
      }

      if (onChange) {
        onChange(value, e)
      }
    }
  }

  /**
   * Detach event listeners to mousemove/mouseup events
   * @return {void}
   */
  handleEnd = (e) => {
    if (!this.props.isQuestionsLoading) {
      const { onChangeComplete } = this.props
      this.setState(
        {
          active: false
        },
        () => {
          if (onChangeComplete) {
            onChangeComplete(e)
          }
        }
      )
      document.removeEventListener('mousemove', this.handleDrag)
      document.removeEventListener('mouseup', this.handleEnd)
    }
  }

  /**
   * Support for key events on the slider handle
   * @param  {Object} e - Event object
   * @return {void}
   */
  handleKeyDown = (e) => {
    if (!this.props.isQuestionsLoading) {
      const { keyCode } = e
      const { value, min, max, step, stepFromBackEnd, onChange } = this.props
      let sliderValue
      if ([37, 38, 39, 40].indexOf(keyCode) !== -1) {
        this.setState({
          valueChanged: true
        })
      }

      switch (keyCode) {
        case 38:
        case 39:
          e.preventDefault()
          if (stepFromBackEnd) {
            sliderValue =
              value + stepFromBackEnd > max ? max : value + stepFromBackEnd
          } else {
            sliderValue = value + step > max ? max : value + step
          }
          if (onChange) {
            onChange(sliderValue, e)
          }
          break
        case 37:
        case 40:
          e.preventDefault()
          if (stepFromBackEnd) {
            sliderValue =
              value - stepFromBackEnd < min ? min : value - stepFromBackEnd
          } else {
            sliderValue = value - step < min ? min : value - step
          }
          if (onChange) {
            onChange(sliderValue, e)
          }
          break
      }
    }
  }

  handleKeyUp = (e) => {
    if (!this.props.isQuestionsLoading) {
      const { onChangeComplete } = this.props
      const { valueChanged } = this.state

      if (onChangeComplete && valueChanged) {
        onChangeComplete(e)
      }

      this.setState({
        valueChanged: false
      })
    }
  }

  /**
   * Calculate position of slider based on its value
   * @param  {number} value - Current value of slider
   * @return {position} pos - Calculated position of slider based on value
   */
  getPositionFromValue = (value) => {
    const { limit } = this.state
    const { min, max } = this.props
    const diffMaxMin = max - min
    const diffValMin = value - min
    const percentage = diffValMin / diffMaxMin
    const pos = Math.round(percentage * limit)

    return pos
  }

  /**
   * Translate position of slider to slider value
   * @param  {number} pos - Current position/coordinates of slider
   * @return {number} value - Slider value
   */
  getValueFromPosition = (pos) => {
    const { limit } = this.state
    const { orientation, min, max, step } = this.props
    const percentage = clamp(pos, 0, limit) / (limit || 1)
    const baseVal = step * Math.round((percentage * (max - min)) / step)
    const value = orientation === 'horizontal' ? baseVal + min : max - baseVal

    return clamp(value, min, max)
  }

  /**
   * Calculate position of slider based on value
   * @param  {Object} e - Event object
   * @return {number} value - Slider value
   */
  position = (e) => {
    const { grab } = this.state
    const { orientation, reverse } = this.props

    const node = this.slider
    const coordinateStyle = constants.orientation[orientation].coordinate
    const directionStyle = reverse
      ? constants.orientation[orientation].reverseDirection
      : constants.orientation[orientation].direction
    const clientCoordinateStyle = `client${capitalize(coordinateStyle)}`
    const coordinate = !e.touches
      ? e[clientCoordinateStyle]
      : e.touches[0][clientCoordinateStyle]
    const direction = node.getBoundingClientRect()[directionStyle]
    const pos = reverse
      ? direction - coordinate - grab
      : coordinate - direction - grab
    const value = this.getValueFromPosition(pos)

    return value
  }

  /**
   * Grab coordinates of slider
   * @param  {Object} pos - Position object
   * @return {Object} - Slider fill/handle coordinates
   */
  coordinates = (pos) => {
    const { limit, grab } = this.state
    const { orientation } = this.props
    const value = this.getValueFromPosition(pos)
    const position = this.getPositionFromValue(value)
    const handlePos = orientation === 'horizontal' ? position + grab : position
    const fillPos = orientation === 'horizontal' ? handlePos : limit - handlePos

    return {
      fill: fillPos,
      handle: handlePos,
      label: handlePos
    }
  }

  renderLabels = (labels) => (
    <ul
      ref={(sl) => {
        this.labels = sl
      }}
      className={cx(styles.rangeslider__labels, 'rangeslider__labels')}
    >
      {labels}
    </ul>
  )

  render() {
    const {
      value,
      inputRef,
      descriptionId,
      orientation,
      className,
      reverse,
      labels,
      min,
      max,
      handleLabel,
      prefix,
      postfix,
      showOnlyStepMultiples,
      hasCurrencyAbbrivation,
      showValueToLeft,
      showValueToTop,
      haveCenterValueItem,
      currency,
      displayType,
      donotShowValueOnSlider,
      customLabelStyles = [],
      sortLabelValues,
      formatSliderValue,
      disabled,
      markingArray
    } = this.props
    const { dimension } = constants.orientation[orientation]
    const direction = reverse
      ? constants.orientation[orientation].reverseDirection
      : constants.orientation[orientation].direction
    const position = this.getPositionFromValue(value)
    const coords = this.coordinates(position)
    const fillStyle = { [dimension]: `${coords.fill}px` }
    const handleStyle = { [direction]: `${coords.handle}px` }

    const labelItems = []
    let labelKeys = Object.keys(labels)
    if (labelKeys.length > 0) {
      labelKeys = sortLabelValues
        ? labelKeys.sort((a, b) => (reverse ? a - b : b - a))
        : labelKeys
      let i = 0
      for (const key of labelKeys) {
        let labelStyle = {}
        if (customLabelStyles.length === 0) {
          const labelPosition = this.getPositionFromValue(key)
          const labelCoords = this.coordinates(labelPosition)
          labelStyle = { [direction]: `${labelCoords.label}px` }
          if (
            labelCoords.label > 20 &&
            document.documentElement.clientWidth < 720
          ) {
            labelStyle = { [direction]: `${labelCoords.label - 20}px` }
          }
        } else {
          labelStyle = customLabelStyles[i]
          i++
        }

        let val = this.props.labels[key]
        if (val && currency && currency.format) {
          val = InputUtils.removeCurrencyFormat(val)
          if (hasCurrencyAbbrivation) {
            const convertedValue = this.convertToCurrencyAbbrivation(val * 1)
            val = `${prefix}${convertedValue}${postfix}`
          } else {
            const { format, preserveFormattingInCb } = currency
            const formattedValue = InputUtils.formatCurrency(val, format)
            if (preserveFormattingInCb) {
              val = `${prefix}${formattedValue}${postfix}`
            }
          }
        }
        labelItems.push(
          <li
            key={key}
            className={cx(
              styles['rangeslider__label-item'],
              'rangeslider__label-item'
            )}
            data-value={key}
            onMouseDown={this.handleDrag}
            onTouchStart={this.handleStart}
            onTouchEnd={this.handleEnd}
            style={labelStyle}
          >
            {val}
          </li>
        )
      }
      if (haveCenterValueItem) {
        const centerValue =
          (labelKeys[0] * 1 + labelKeys[labelKeys.length - 1] * 1) / 2

        labelItems.push(
          <li
            key={centerValue}
            className={cx(
              styles['rangeslider__label-item'],
              styles['middle-value'],
              'rangeslider__label-item',
              'middle-value'
            )}
            data-value={centerValue}
            onMouseDown={this.handleDrag}
            onTouchStart={this.handleStart}
            onTouchEnd={this.handleEnd}
            style={{ left: '50%' }}
          >
            {`${prefix}${this.convertToCurrencyAbbrivation(
              centerValue
            )}${postfix}`}
          </li>
        )
      }
    }
    let modifiedValue = value
    if (showOnlyStepMultiples) {
      modifiedValue = this.handleShowOnlyStepMultiples(value * 1)

      if (formatSliderValue) {
        const { format } = currency
        modifiedValue = InputUtils.formatCurrency(modifiedValue, format)
      }
    }
    if (hasCurrencyAbbrivation) {
      modifiedValue = this.convertToCurrencyAbbrivation(modifiedValue)
    }

    let stylesClassName = `rangeslider-container${
      displayType === 'contents' ? `-customDisplay` : ``
    }${disabled ? `-disable` : `-enable`}`

    const markingItems = markingArray.map((mark) => {
      const markingPosition = this.getPositionFromValue(mark)
      const markingCoords = this.coordinates(markingPosition)
      const markingStyle = { [direction]: `${markingCoords.label}px` }
      console.log(mark, markingStyle)
      if (
        markingCoords.label > 20 &&
        document.documentElement.clientWidth < 720
      ) {
        labelStyle = { [direction]: `${markingCoords.label - 20}px` }
      }
      return (
        <div
          style={{
            borderLeft: '2px solid #c1c1c1',
            height: '31px',
            position: 'absolute',
            marginTop: '-12px',
            ...markingStyle
          }}
        />
      )
    })

    return (
      <React.Fragment>
        {showValueToTop ? (
          <div className={styles['rangeslider__handle-tooltip']}>
            {`${prefix}${modifiedValue}${postfix}`}
          </div>
        ) : null}
        <div className={styles[stylesClassName]}>
          {showValueToLeft && (
            <div className={styles['rangeslider__left-value']}>
              {`${prefix}${modifiedValue}${postfix}`}
            </div>
          )}
          <div
            ref={(s) => {
              this.slider = s
            }}
            className={cx(
              styles.rangeslider,
              'rangeslider',
              styles[`rangeslider-${orientation}`],
              `rangeslider-${orientation}`,
              { 'rangeslider-reverse': reverse },
              className
            )}
            onMouseDown={this.handleDrag}
            onMouseUp={this.handleEnd}
            onTouchStart={this.handleStart}
            onTouchEnd={this.handleEnd}
            role='slider'
            aria-valuemin={min}
            aria-valuemax={max}
            aria-valuenow={value}
            aria-orientation={orientation}
            aria-labelledby={descriptionId}
          >
            <div
              className={cx(styles.rangeslider__fill, 'rangeslider__fill')}
              style={fillStyle}
            >
              <ul style={{display: 'inline-flex'}}>{markingItems}</ul>
            </div>
            <div
              ref={(sh) => {
                this.handle = sh
                if (inputRef) {
                  inputRef.current = sh
                }
              }}
              className={cx(styles.rangeslider__handle, 'rangeslider__handle')}
              onMouseDown={this.handleStart}
              onTouchMove={this.handleDrag}
              onTouchEnd={this.handleEnd}
              onKeyDown={this.handleKeyDown}
              onKeyUp={this.handleKeyUp}
              style={handleStyle}
              tabIndex={0}
            >
              {!donotShowValueOnSlider && (
                <div
                  ref={(st) => {
                    this.tooltip = st
                  }}
                  className={cx(
                    styles['rangeslider__handle-tooltip'],
                    'rangeslider__handle-tooltip'
                  )}
                >
                  <span>{`${prefix}${InputUtils.formatCurrency(modifiedValue, currency.format)}${postfix}`}</span>
                </div>
              )}
              <div
                className={cx(
                  styles['rangeslider__handle-label'],
                  'rangeslider__handle-label'
                )}
              >
                {handleLabel}
              </div>
            </div>
            {labels ? this.renderLabels(labelItems) : null}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Slider
