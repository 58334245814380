import styled, { css } from 'styled-components'
import DatePicker from '../../packages/react-datepicker'

const StyledSpan = styled.span`
  outline: 0;
  pointer-events: none !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${({ theme }) =>
    theme.calendarIconPlacement && theme.calendarIconPlacement === 'left'
      ? `left: 10px`
      : `right:18px`}
`

const StyledIcon = styled.i`
  height: 100%;
  outline: 0;
  color: ${(props) => (props.isDate ? '#a1a1a1' : '')};
  margin-right: ${(props) => (props.isDate ? '' : '10px')};
`

const StyledDatePicker = styled(DatePicker)`
  position: relative;
`

const DateErrorContainer = styled.div`
  background: #e60000;
  color: #ffffff;
  font-size: 12px;
  padding: 8px;
  margin: 5px;
  max-width: 262px;
`
const CustomDatePicker = styled.input.attrs((props) => ({
  type: props.type,
  placeholder: props.placeholder
}))`
  &:before {
    content: ${(props) => props.placeholder};
    color: #aaa;
    margin-right: 0.5em;
  }

  &:focus: before,
  &:valid: before {
    content: '';
  }

  ${(props) =>
    !props.open &&
    css`
      &::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
      }

       {
        text-transform: uppercase;
      }
      ::-webkit-input-placeholder {
        /* WebKit browsers */
        text-transform: none;
      }
      :-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        text-transform: none;
      }
      ::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        text-transform: none;
      }
      :-ms-input-placeholder {
        /* Internet Explorer 10+ */
        text-transform: none;
      }
      ::placeholder {
        /* Recent browsers */
        text-transform: none;
      }
    `}
`

const Input = styled.input`
  border: none;
  outline: none;
  color: rgb(73, 80, 87);
  font-size: 16px;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  padding: 0px;
  margin: 0px;
  float: left;
  width: ${(props) => props.width};
  min-width: 15px;
  max-width: ${(props) =>
    props.name === 'day' || props.name === 'month' ? '25px' : '40px'};

  &:placeholder-shown {
    width: ${(props) => (props.name === 'year' ? '30%' : 'calc(100% / 14)')};
    @media (min-width: 321px) and (max-width: 720px) {
      width: ${(props) => (props.name === 'year' ? '30%' : 'calc(100% / 8)')};
    }
    @media (max-width: 320px) {
      width: ${(props) => (props.name === 'year' ? '36%' : 'calc(100% / 6)')};
    }
  }
  &:-ms-input-placeholder {
    width: ${(props) => (props.name === 'year' ? '30%' : '8%')};
  }
  & {
    -moz-appearance: textfield;
  }
`

const Separator = styled.span`
  white-space: pre;
  font-size: 19px;
  color: rgb(73, 80, 87);
  padding: 0px;
  margin: 0px;
  float: left;
`

const DummyInput = styled.input`
  visibility: hidden;
  position: absolute;
  top: -1000px;
  left: -1000px;
`

const Label = styled.label`
  font-weight: ${({ theme }) => theme.labelFontWeight || 'bold'};
  color: ${({ theme }) => theme.labelFontColor || '#767676'};
  font-size: ${({ theme }) => theme.labelFontSize || 'auto'};
`

export {
  StyledSpan,
  StyledDatePicker,
  StyledIcon,
  DateErrorContainer,
  CustomDatePicker,
  Input,
  Separator,
  DummyInput,
  Label
}
