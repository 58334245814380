import React from 'react'
import PropTypes from 'prop-types'
import MonthYearDropdownOptions from './month_year_dropdown_options'
import onClickOutside from 'react-onclickoutside'
import {
  addMonths,
  formatDate,
  getStartOfMonth,
  isAfter,
  isSameMonth,
  isSameYear,
  newDate,
  getTime,
  getYear
} from './date_utils'
import styles from './stylesheets/react-datepicker.module.css'

var WrappedMonthYearDropdownOptions = onClickOutside(MonthYearDropdownOptions)

export default class MonthYearDropdown extends React.Component {
  static propTypes = {
    dropdownMode: PropTypes.oneOf(['scroll', 'select']).isRequired,
    dateFormat: PropTypes.string.isRequired,
    locale: PropTypes.string,
    maxDate: PropTypes.instanceOf(Date).isRequired,
    minDate: PropTypes.instanceOf(Date).isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
    onChange: PropTypes.func.isRequired,
    scrollableMonthYearDropdown: PropTypes.bool
  }

  state = {
    dropdownVisible: false
  }

  renderSelectOptions = () => {
    let currDate = getStartOfMonth(this.props.minDate)
    const lastDate = getStartOfMonth(this.props.maxDate)
    const options = []

    while (!isAfter(currDate, lastDate)) {
      const timepoint = getTime(currDate)
      options.push(
        <option key={timepoint} value={timepoint}>
          {formatDate(currDate, this.props.dateFormat, this.props.locale)}
        </option>
      )

      currDate = addMonths(currDate, 1)
    }

    return options
  }

  onSelectChange = (e) => {
    this.onChange(e.target.value)
  }

  renderSelectMode = () => (
    <select
      value={getTime(getStartOfMonth(this.props.date))}
      className={styles['react-datepicker__month-year-select']}
      onChange={this.onSelectChange}
    >
      {this.renderSelectOptions()}
    </select>
  )
  handleKeyDown = (event, props) => {
    const eventKey = event.key
    if (eventKey === 'Enter') {
      if (props.monthView) props.toggleMonthView()
        props.toggleYearView()
    }
  }
  
  renderReadView = (visible) => {
    let yearMonth = formatDate(
      this.props.date,
      this.props.dateFormat,
      this.props.locale
    )

    if (this.props.yearView) yearMonth = getYear(this.props.date)

    return (
      <div
        key='read'
        style={{ visibility: visible ? 'visible' : 'hidden' }}
        className={styles['react-datepicker__month-year-read-view']}
        onClick={() => {
          if (this.props.monthView) this.props.toggleMonthView()
          this.props.toggleYearView()
        }}
        tabIndex={0}
        onKeyDown={(event) => this.handleKeyDown(event, this.props)}
        aria-label={`${yearMonth} choose year dropdown`}
      >
        {this.props.yearView ||
        (this.props.monthView && !this.props.showMonthYearPicker) ? (
          <span
            className={
              styles['react-datepicker__month-year-read-view--up-arrow']
            }
          />
        ) : (
          <span
            className={
              styles['react-datepicker__month-year-read-view--down-arrow']
            }
          />
        )}
        <span
          className={
            styles[
              'react-datepicker__month-year-read-view--selected-month-year'
            ]
          }
        >
          {yearMonth}
        </span>
      </div>
    )
  }

  renderDropdown = () => (
    <WrappedMonthYearDropdownOptions
      key='dropdown'
      date={this.props.date}
      dateFormat={this.props.dateFormat}
      onChange={this.onChange}
      onCancel={this.toggleDropdown}
      minDate={this.props.minDate}
      maxDate={this.props.maxDate}
      scrollableMonthYearDropdown={this.props.scrollableMonthYearDropdown}
    />
  )

  renderScrollMode = () => {
    const { dropdownVisible } = this.state
    const result = [this.renderReadView(!dropdownVisible)]
    if (dropdownVisible) {
      result.unshift(this.renderDropdown())
    }
    return result
  }

  onChange = (monthYearPoint) => {
    this.toggleDropdown()

    const changedDate = newDate(parseInt(monthYearPoint))

    if (
      isSameYear(this.props.date, changedDate) &&
      isSameMonth(this.props.date, changedDate)
    ) {
      return
    }

    this.props.onChange(changedDate)
  }

  toggleDropdown = () =>
    this.setState({
      dropdownVisible: !this.state.dropdownVisible
    })

  render() {
    let renderedDropdown
    switch (this.props.dropdownMode) {
      case 'scroll':
        renderedDropdown = this.renderScrollMode()
        break
      case 'select':
        renderedDropdown = this.renderSelectMode()
        break
    }

    return (
      <div
        className={`${
          styles['react-datepicker__month-year-dropdown-container']
        } ${
          styles[
            `react-datepicker__month-year-dropdown-container--${this.props.dropdownMode}`
          ]
        }`}
      >
        {renderedDropdown}
      </div>
    )
  }
}
