import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
  background: #ffffff;
  max-width: ${({ themeConfiguration }) =>
    (themeConfiguration && themeConfiguration.maxWidth) || '780px'};
  width: ${({ themeConfiguration }) =>
    (themeConfiguration && themeConfiguration.width) || '90%'};
  border-radius: ${({ themeConfiguration }) =>
    themeConfiguration && themeConfiguration.borderRadius
      ? themeConfiguration.borderRadius
      : '8px'};
  margin: ${({ themeConfiguration }) =>
    (themeConfiguration && themeConfiguration.margin) || '0 auto'};
  @media (max-width: 767px) {
    width: 100%;
  }
`

const getPadding = ({ layout, padding }) => {
  if (padding) {
    return padding
  }
  if (layout === 'auto') {
    return 0
  }
  return '80px 100px 40px 100px'
}

const CardWrapper = styled(Wrapper)`
  ${({ hideWrapper }) => hideWrapper && 'display: none'};
  max-width: ${({ layout }) => (layout === 'single' ? '80%' : '780px')};
  padding: ${(props) => getPadding(props)};
  position: relative;
  margin-bottom: ${({ layout }) => (layout !== 'auto' ? '40px' : '0')};
  background: ${({ background }) => background};
  ${({ marginLeft }) =>
    marginLeft &&
    `
    margin-left: ${marginLeft};
  `}
  @media (max-width: 767px) {
    padding: ${({ layout }) => (layout !== 'auto' ? '20px 20px' : '0')};
    max-width: 100%;
    padding: ${({ padding }) => padding};
    margin-left: 0%;
  }
  ${({ colorCode, width }) =>
    colorCode &&
    `
    border-left: ${width} solid ${colorCode};
  `};
  ${({ maxWidth }) =>
    maxWidth &&
    `
    max-width: ${maxWidth};
    `}
  ${({ themeConfiguration }) =>
    themeConfiguration.border && `border: ${themeConfiguration.border}`};
  ${({ themeConfiguration }) =>
    themeConfiguration.height && `height: ${themeConfiguration.height}`};
`

const CollapsedCard = styled(Wrapper)`
  max-width: ${({ layout }) => (layout === 'single' ? '80%' : '780px')};
  display: flex;
  flex-direction: row;
  height: 60px;
  padding: 20px 20px;
  background: rgba(255, 255, 255, 1);
  margin-top: 10px;
  margin-bottom: 10px;
  ${({ maxWidth }) =>
    maxWidth &&
    `
    max-width: ${maxWidth};
    `}
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: ${({ themeConfiguration }) =>
    themeConfiguration && themeConfiguration.marginRight};
`

const Image = styled.img.attrs((props) => ({
  alt: props.alt
}))`
  width: 40%;
  @media (min-width: 768px) {
    ${({ themeConfiguration }) =>
      !themeConfiguration.imageWidth && 'width : auto'};
    border-radius: 8px;
  }
  max-height: 128px;
  padding-top: 5px;
  padding-bottom: 5px;
  ${({ themeConfiguration }) =>
    themeConfiguration.imageHeight &&
    `height : ${themeConfiguration.imageHeight}`};
  ${({ themeConfiguration }) =>
    themeConfiguration.imageWidth &&
    `width : ${themeConfiguration.imageWidth}`};
  src: ${(props) => props.src};
`

const Card = ({
  imgUrl,
  imgAlt,
  children,
  layout,
  noStyle,
  collapsed,
  padding,
  leftBorderWidth,
  leftBorderColor,
  themeConfiguration,
  background,
  maxWidth,
  hideWrapper,
  marginLeft,
  id,
  isOpened,
  onClick
}) => {
  let onClickAttributes = {}
  if (onClick) {
    onClickAttributes = {
      onClick: onClick,
      role: 'button',
      tabIndex: 0,
      'aria-expanded': isOpened,
      onKeyPress: onClick
    }
  }

  if (noStyle) {
    return children
  }
  if (collapsed) {
    return (
      <CollapsedCard
        id={id}
        name='collapsed-card'
        themeConfiguration={themeConfiguration}
        maxWidth={maxWidth}
      >
        {children}
      </CollapsedCard>
    )
  }
  if (imgUrl) {
    if (leftBorderWidth && leftBorderColor) {
      return (
        <CardWrapper
          id={id}
          name='card-wrapper'
          layout={layout}
          padding='0px 0px'
          background={background}
          themeConfiguration={themeConfiguration}
          colorCode={leftBorderColor}
          width={leftBorderWidth}
          hideWrapper={hideWrapper}
          maxWidth={maxWidth}
          {...onClickAttributes}
        >
          <ImageWrapper
            name='image-wrapper'
            themeConfiguration={themeConfiguration}
          >
            {children}
            <Image
              name='image'
              src={imgUrl}
              alt={imgAlt}
              themeConfiguration={themeConfiguration}
            />
          </ImageWrapper>
        </CardWrapper>
      )
    }
    return (
      <CardWrapper
        id={id}
        name='card-wrapper'
        layout={layout}
        padding={padding}
        background={background}
        themeConfiguration={themeConfiguration}
        hideWrapper={hideWrapper}
        maxWidth={maxWidth}
        {...onClickAttributes}
      >
        <ImageWrapper
          name='image-wrapper'
          themeConfiguration={themeConfiguration}
        >
          {children}
          <Image
            name='image'
            src={imgUrl}
            alt={imgAlt}
            themeConfiguration={themeConfiguration}
          />
        </ImageWrapper>
      </CardWrapper>
    )
  }
  return (
    <CardWrapper
      id={id}
      name='card-wrapper'
      layout={layout}
      padding={padding}
      background={background}
      themeConfiguration={themeConfiguration}
      colorCode={leftBorderColor}
      width={leftBorderWidth}
      maxWidth={maxWidth}
      marginLeft={marginLeft}
      {...onClickAttributes}
    >
      {children}
    </CardWrapper>
  )
}

Card.defaultProps = {
  layout: 'double',
  imgAlt: '',
  noStyle: false,
  collapsed: false,
  padding: '80px 100px 40px 100px',
  leftBorderWidth: '',
  leftBorderColor: '',
  background: '#ffffff',
  maxWidth: '',
  themeConfiguration: {},
  hideWrapper: false
}

Card.propTypes = {
  /**
   * React Component or Element to be displayed.
   */
  children: PropTypes.node.isRequired,
  /**
   * Specify the layout of the background to adjust the card.
   */
  layout: PropTypes.oneOf(['auto', 'single', 'double']),
  /**
   * Returns only children without applying any style.
   */
  noStyle: PropTypes.bool,
  /**
   * Returns collapsed card view.
   */
  collapsed: PropTypes.bool,
  /**
   * Value of padding
   */
  padding: PropTypes.string,
  /**
   * Value of left border width
   */
  leftBorderWidth: PropTypes.string,
  /**
   * Value of left border color
   */
  leftBorderColor: PropTypes.string,
  /**
   * Value for card shape(rounded/rectangular)
   */
  themeConfiguration: PropTypes.object,
  /**
   * Value of background Color
   */
  background: PropTypes.string,
  maxWidth: PropTypes.string,
  /**
   * Hides Card wrapper
   */
  hideWrapper: PropTypes.bool,
  /**
   * Value of Image Alt Text
   */
  imgAlt: PropTypes.string,
  /**
   * ID for card
   */
  id: PropTypes.string,
  onClick: PropTypes.func
  /**
   * function for onClick handler
   */
}

export default Card
