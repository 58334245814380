import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '../Button'
import { RangeStepperWrapper, StepperValueWrapper } from './styles'

const RangeStepper = (props) => {
  const {
    defaultValue,
    stepperOptions,
    onChange,
    decrementBtnTheme,
    incrementBtnTheme,
    labelTheme
  } = props
  const [currentIndex, setCurrentIndex] = useState(0)
  const stepperLabel =
    stepperOptions.length && stepperOptions[currentIndex]
      ? stepperOptions[currentIndex].label
      : ''

  useEffect(() => {
    if (defaultValue) {
      setCurrentIndex(defaultValue.id || 0)
    }
  }, [defaultValue])

  const handleDecrement = () => {
    const revisedIndex = currentIndex - 1
    setCurrentIndex(revisedIndex)
    onChange(stepperOptions[revisedIndex])
  }

  const handleIncrement = () => {
    const revisedIndex = currentIndex + 1
    setCurrentIndex(revisedIndex)
    onChange(stepperOptions[revisedIndex])
  }

  return (
    <RangeStepperWrapper>
      <Button
        label='-'
        alignment='center'
        themeConfiguration={decrementBtnTheme}
        disabled={stepperOptions.length === 0 || currentIndex === 0}
        handleButtonClick={handleDecrement}
      />
      <StepperValueWrapper labelTheme={labelTheme}>
        {stepperLabel}
      </StepperValueWrapper>
      <Button
        label='+'
        alignment='center'
        themeConfiguration={incrementBtnTheme}
        disabled={
          stepperOptions.length === 0 ||
          currentIndex === stepperOptions.length - 1
        }
        handleButtonClick={handleIncrement}
      />
    </RangeStepperWrapper>
  )
}

RangeStepper.defaultProps = {
  defaultValue: null,
  stepperOptions: [],
  onChange: () => {},
  decrementBtnTheme: {
    selectedBgColor: '#ddd',
    bgColor: '#000',
    padding: '6px 16px',
    border: '1px solid #bbb',
    borderRight: 'none',
    borderRadius: '8px 0 0 8px'
  },
  incrementBtnTheme: {
    selectedBgColor: '#ddd',
    bgColor: '#000',
    padding: '6px 16px',
    border: '1px solid #bbb',
    borderLeft: 'none',
    borderRadius: '0 8px 8px 0'
  },
  labelTheme: null
}

RangeStepper.propTypes = {
  defaultValue: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string
  }),
  stepperOptions: PropTypes.array,
  onChange: PropTypes.func,
  decrementBtnTheme: PropTypes.object,
  incrementBtnTheme: PropTypes.object,
  labelTheme: PropTypes.object
}

export default RangeStepper
