import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import { AppConstants } from '../model/AppConstants';
const instance = axios.create({
    baseURL: AppConstants.jsonServerUrl,
    timeout: 15000
});

/* for developer help which prints calling url in console
axios.interceptors.request.use(req => {
    console.log("Intercepted url ",`${req.method} ${req.url}`);
    // Important: request interceptors **must** return the request.
    return req;
});*/

const responseBody = (response: AxiosResponse) => response.data;

/**
 * Helper function for axios http methods
 */
export const requests = {
    get: (url: string, requestConfig?: {}) => instance.get(url, requestConfig).then(responseBody),
    post: (url: string, body: {}, requestConfig?: {}) => instance.post(url, body, requestConfig).then(responseBody),
    put: (url: string, body: {}) => instance.put(url, body).then(responseBody),
    delete: (url: string) => instance.delete(url).then(responseBody),
};

/**
 * Build {@link AxiosRequestConfig} object from input parameters
 * @param accessToken - Value for access_token header
 * @param params - Optional params that need to be passed in
 */
export function buildRequestConfigWithAccessToken<T>(accessToken: string, params?: T): AxiosRequestConfig {
  return {
    headers: {
      'access_token': `Bearer ${accessToken}`
    },
    params: params
  } ;
}

/**
 * Build {@link AxiosRequestConfig} object from input parameters
 * @param role - Value for X-User-Type header
 * @param params - Optional params that need to be passed in
 */
export function buildRequestConfigWithRole<T>(role: string, params?: T): AxiosRequestConfig {
  return {
    method: "GET",
    headers: {
      'X-User-Type': role,
    },
    params: params
  };
}
