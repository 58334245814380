import React from 'react'
import PropTypes from 'prop-types'
import styles from './stylesheets/react-datepicker.module.css'

export default class inputTime extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    timeString: PropTypes.string,
    timeInputLabel: PropTypes.string,
    customTimeInput: PropTypes.element
  }

  constructor(props) {
    super(props)

    this.state = {
      time: this.props.timeString
    }
  }

  onTimeChange = (time) => {
    this.setState({ time })
    const date = new Date()
    date.setHours(time.split(':')[0])
    date.setMinutes(time.split(':')[1])
    this.props.onChange(date)
  }

  renderTimeInput = () => {
    const { time } = this.state
    const { timeString, customTimeInput } = this.props

    if (customTimeInput) {
      return React.cloneElement(customTimeInput, {
        value: time,
        onChange: this.onTimeChange
      })
    }

    return (
      <input
        type='time'
        className={styles['react-datepicker-time__input']}
        placeholder='Time'
        name='time-input'
        required
        value={time}
        onChange={(ev) => {
          this.onTimeChange(ev.target.value || timeString)
        }}
      />
    )
  }

  render() {
    return (
      <div className={styles['react-datepicker__input-time-container']}>
        <div className={styles['react-datepicker-time__caption']}>
          {this.props.timeInputLabel}
        </div>
        <div className={styles['react-datepicker-time__input-container']}>
          <div className={styles['react-datepicker-time__input']}>
            {this.renderTimeInput()}
          </div>
        </div>
      </div>
    )
  }
}
