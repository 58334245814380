import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Select, { components } from 'react-select'
import AsyncSelect from 'react-select/async'
import styled, { ThemeProvider } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import { useDocumentWidth, validateInnerHTML, stripHtml } from '../../utils'
import {
  SelectWrapper,
  SelectContainer,
  getBorderColor,
  ErrorMessage,
  SelectLabel,
  SelectLabelWrapper,
  HelperText
} from './styles'
import { inputWidth } from '../../themes'
import { Tooltip } from '../Tooltip'
import { getWidth, TooltipWrapper } from '../../styles'
import { updateOptions, getDefaultSelected } from './react.select.utils'
import { fetchResults } from '../Select/utils'
import utils from '../TextInput/utils'

library.add(faChevronDown)

const { ValueContainer, Placeholder } = components

const Label = styled(Placeholder)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  width: ${({ width }) => `${inputWidth[width] - 50}px`};
`
const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      {(!props.hasValue || props.selectProps.floatingLabel) && (
        <Label
          {...props}
          width={props.selectProps.width}
          isFocused={props.isFocused}
        >
          <div
            id={`${props.selectProps.id}-label`}
            dangerouslySetInnerHTML={{
              __html: validateInnerHTML(props.selectProps.placeholder)
            }}
          />
        </Label>
      )}
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  )
}

const ReactSelect = (props) => {
  const {
    id,
    type,
    options,
    inputRef,
    corner,
    labelMargin,
    width,
    color,
    styles,
    disabled,
    hasTooltip,
    tooltipPlacement,
    tooltipTitle,
    tooltipBody,
    tooltipHTML,
    labelText,
    customSelectPlaceholder,
    maxOptionsCount,
    handleSelect,
    isControlled,
    value,
    themeConfiguration,
    asyncProps,
    enableAsync,
    disableCapitalization = false,
    errorMessageColor,
    errorFontSize,
    tabSelectsValue,
    spanText,
    selectLabelProps,
    isAsterisk,
    autoComplete,
    isCustomDropdown,
    helperText
  } = props

  // Reference - https://frontarm.com/james-k-nelson/conditionally-set-default-props/
  let { defaultSelected } = props
  defaultSelected = getDefaultSelected(props)
  const autoSuggestDefaultOption = [
    {
      id: 'loading',
      label: 'Please wait...',
      disabled: true
    }
  ]

  let { floatingLabel, labelPosition } = props
  const floatingOverride = utils.overrideFloatingLabel(
    labelText,
    floatingLabel,
    null,
    labelPosition,
    80
  )
  floatingLabel = floatingOverride.floatingLabel
  labelPosition = floatingOverride.labelPosition

  const docWidth = useDocumentWidth()
  let exactWidth = ''
  if (themeConfiguration && themeConfiguration.exactWidth) {
    exactWidth = themeConfiguration.exactWidth[width]
  }

  useEffect(() => {
    if (isControlled) {
      setSelectedOption(value)
    }
  }, [value])

  useEffect(() => {
    if (type === 'M' && defaultSelected) {
      setSelectedOption(updateOptions(defaultSelected))
    }
  }, [])

  useEffect(() => {
    if (type !== 'M') {
      setSelectedOption(
        options.filter((o) => {
          return o.id === defaultSelected
        })[0]
      )
    }
  }, [defaultSelected])

  const [selectedOption, setSelectedOption] = useState(null)
  const [hasError, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [hasInputVal, setInputVal] = useState(false)
  useEffect(() => {
    if (selectedOption && selectedOption.error_message) {
      setErrorMsg(selectedOption.error_message)
      setError(true)
    } else {
      setErrorMsg('')
      setError(false)
    }
  }, [selectedOption])
  const handleChange = (_selectedOption, actionObj) => {
    if (isControlled && actionObj.action === 'remove-value') {
      const options = Object.values(selectedOption)
      options.push(actionObj.removedValue)
      handleSelect(options)
      return
    }
    if (
      type === 'M' &&
      JSON.stringify(selectedOption) === JSON.stringify(_selectedOption)
    ) {
      return
    }
    setSelectedOption(_selectedOption)
    handleSelect(_selectedOption)
  }

  const floatingPlaceholderProps = (state) => {
    if (floatingLabel) {
      return {
        position: 'absolute',
        top: state.hasValue || state.selectProps.inputValue ? 5 : '50%',
        transition: 'top 0.25s ease-in-out, font-size 0.25s ease-in-out',
        transform:
          state.hasValue || state.selectProps.inputValue
            ? 'translate(-3px,-7px) scale(0.7)'
            : 'translateY(-50%)',
        transformOrigin: '0 0',
        fontSize: (state.hasValue || state.selectProps.inputValue) && 13,
        marginTop: (state.hasValue || state.selectProps.inputValue) && '-5px',
        marginLeft: (state.hasValue || state.selectProps.inputValue) && '2px'
      }
    }
    return {
      display:
        state.isFocused || state.isSelected || state.selectProps.inputValue
          ? 'none'
          : 'block'
    }
  }

  const colourStyles = {
    container: () => ({
      position: 'static',
      height: '100%',
      maxWidth: '100%',
      '&:focus-within': {
        outline:
          themeConfiguration && themeConfiguration.outlineOnFocus
            ? themeConfiguration.outlineOnFocus
            : '',
        border:
          themeConfiguration && themeConfiguration.borderOnFocus
            ? themeConfiguration.borderOnFocus
            : '',
        ...(themeConfiguration.borderRadiusOnFocus && {
          borderRadius: themeConfiguration.borderRadiusOnFocus
        }),
        '& > div': {
          borderRadius: 0
        }
      }
    }),
    control: (base, state) => ({
      ...base,
      cursor:
        disabled && themeConfiguration?.disabledPointer
          ? themeConfiguration.disabledPointer
          : 'pointer',
      '&:hover': {
        borderColor: getBorderColor(state.isFocused, color, themeConfiguration)
      },
      boxShadow: 'none',
      minHeight: 52,
      maxWidth: '100%',
      width: exactWidth || getWidth(width),
      outline: 'none',
      backgroundColor:
        themeConfiguration && themeConfiguration.backgroundColor
          ? themeConfiguration.backgroundColor
          : 'rgba(255, 255, 255)',

      border: `${
        (themeConfiguration && themeConfiguration.borderWidth) || '1px'
      } solid ${getBorderColor(state.isFocused, color, themeConfiguration)}`,
      borderBottom:
        themeConfiguration && themeConfiguration.bottomBorderWidth
          ? `${themeConfiguration.bottomBorderWidth} solid ${getBorderColor(
              state.isFocused,
              color,
              themeConfiguration
            )}}`
          : '',
      textTransform: disableCapitalization ? 'none' : 'capitalize',
      borderRadius:
        themeConfiguration && themeConfiguration.borderRadius
          ? themeConfiguration.borderRadius
          : 5,
      '&:focus': {
        borderRadius: 0
      }
    }),
    valueContainer: (base, state) => ({
      ...base,
      padding: '5px 8px',
      overflow: 'visible',
      ...(themeConfiguration &&
        themeConfiguration.selectFont && {
          font: themeConfiguration.selectFont
        }),
      marginTop:
        floatingLabel &&
        (state.hasValue || state.selectProps.inputValue) &&
        '10px'
    }),
    option: (base, state) => {
      let backgroundColor = '#fff'
      if (!value && selectedOption && selectedOption.id === state.data.id) {
        backgroundColor =
          themeConfiguration && themeConfiguration.selectedBgColor
            ? themeConfiguration.selectedBgColor
            : '#2424ff'
      } else if (state.isFocused) {
        backgroundColor =
          themeConfiguration && themeConfiguration.optionHoverColor
            ? themeConfiguration.optionHoverColor
            : '#D2E4EA'
      }

      let color = '#000'
      if (!value && selectedOption && selectedOption.id === state.data.id) {
        color = '#fff'
      }

      return {
        ...base,
        minHeight: '40px',
        backgroundColor,
        color,
        ':active': {
          backgroundColor:
            themeConfiguration && themeConfiguration.selectedBgColor
              ? themeConfiguration.selectedBgColor
              : '#2424ff',
          color: '#f5f5f5'
        },
        borderRadius: 2
      }
    },
    menuPortal: (base) => {
      return {
        ...base,
        zIndex: 2147483647
      }
    },
    menu: (base) => ({
      ...base,
      maxWidth: getWidth(width),
      textTransform: disableCapitalization ? 'none' : 'capitalize',
      position: 'absolute',
      fontSize: '14px !important'
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: `${maxOptionsCount * 40}px`
    }),
    dropdownIndicator: (base) => ({
      ...base,
      zIndex: 1
    }),
    placeholder: (base, state) => ({
      ...base,
      ...floatingPlaceholderProps(state),
      fontWeight: 'bold',
      ...(themeConfiguration && themeConfiguration.placeholder)
    }),
    multiValue: (base, state) => ({
      ...base,
      marginTop: (state.hasValue || state.selectProps.inputValue) && '5px',
      maxWidth: '100px'
    }),
    indicatorContainer: (base) => ({
      ...base,
      ...(themeConfiguration && themeConfiguration.indicatorContainer)
    }),
    indicatorSeparator: (base) => ({
      ...base,
      ...(themeConfiguration && themeConfiguration.indicatorSeparator)
    })
  }

  const customDropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon
          icon='chevron-down'
          style={{
            ...(themeConfiguration &&
              themeConfiguration.customDropdownIndicator)
          }}
        />
      </components.DropdownIndicator>
    )
  }

  const CustomSelectTooltip = () => (
    <TooltipWrapper name='tool-tip-wrapper' tooltipPlacement={tooltipPlacement}>
      <Tooltip
        name='tool-tip'
        tooltipTitle={tooltipTitle}
        tooltipBody={tooltipBody}
        tooltipHTML={tooltipHTML}
      />
    </TooltipWrapper>
  )

  const getCustomDropDown = () => {
    return themeConfiguration && themeConfiguration.customDropdownIndicator
      ? { DropdownIndicator: customDropdownIndicator }
      : null
  }

  const getComponents = () => ({
    ValueContainer: CustomValueContainer,
    ...getCustomDropDown()
  })

  // Custom ReactSelect Component for handling floatingLabel
  let customComponentProp = {}
  if (floatingLabel) {
    customComponentProp = {
      components: getComponents()
    }
  }

  if (isCustomDropdown && !floatingLabel) {
    customComponentProp = {
      components: getCustomDropDown()
    }
  }

  if (autoComplete && autoComplete === 'off') {
    const _components = customComponentProp?.components || {}
    // disabling auto complete of the drop down
    const Input = ({ autoComplete, ...props }) => (
      <components.Input {...props} autoComplete='new-password' />
    )
    customComponentProp = {
      components: { ..._components, Input }
    }
  }

  // Configures Props for MultiSelect Dropdown
  let multiSelectProp = {}
  if (type === 'M') {
    multiSelectProp = {
      isMulti: true,
      hideSelectedOptions: false
    }
  }

  const promiseOptions = async (inputValue) => {
    return await fetchResults(asyncProps, inputValue)
  }
  const handleInputChange = (input) => {
    if (input.length > 0) {
      setInputVal(true)
    } else {
      setInputVal(false)
    }
  }
  const screenReaderText = 'Select ' + stripHtml(labelText)

  const showHelperText = helperText && !hasError
  
  const HelperTextComponent = (
    <HelperText data-testid='helper-text' aria-live='polite'>
      {helperText}
    </HelperText>
  )

  const ErrorComponent = (
    <ErrorMessage data-testid='error-message'>{errorMsg}</ErrorMessage>
  )

  return (
    <ThemeProvider
      theme={{
        floatingLabel,
        labelPosition,
        corner,
        labelMargin,
        hasContent: !!selectedOption,
        width,
        color,
        hasError,
        errorMessageColor,
        spanText,
        selectLabel: selectLabelProps,
        errorFontSize,
        hasTooltip,
        ...themeConfiguration
      }}
    >
      <SelectContainer name='select-container'>
        {!floatingLabel && (
          <SelectLabelWrapper
            id={`react-select-label-${id}`}
            hasTooltip={hasTooltip}
            docWidth={docWidth}
          >
            <SelectLabel
              name='select-label'
              htmlFor={id}
              isAsterisk={isAsterisk}
            >
              <span
                id={screenReaderText}
                dangerouslySetInnerHTML={{
                  __html: validateInnerHTML(labelText, isAsterisk)
                }}
              />
            </SelectLabel>
            {hasTooltip && tooltipPlacement === 'label' && (
              <CustomSelectTooltip />
            )}
          </SelectLabelWrapper>
        )}
        <SelectWrapper
          name='select-wrapper'
          id={`react-select-field-${id}`}
          exactWidth={exactWidth}
          hasTooltip={hasTooltip}
        >
          {enableAsync ? (
            <AsyncSelect
              id={id}
              ref={inputRef}
              aria-label={screenReaderText}
              menuPortalTarget={document.querySelector('body')}
              cacheOptions
              defaultOptions={autoSuggestDefaultOption}
              onChange={handleChange}
              openMenuOnFocus
              styles={styles || colourStyles}
              placeholder={floatingLabel ? labelText : customSelectPlaceholder}
              loadOptions={promiseOptions}
              value={isControlled ? value : selectedOption}
              tabSelectsValue={tabSelectsValue}
              isMulti
            />
          ) : (
            <Select
              id={id}
              ref={inputRef}
              name='react-select'
              aria-label={screenReaderText}
              menuPortalTarget={document.querySelector('body')}
              value={isControlled ? value : selectedOption}
              onChange={handleChange}
              options={updateOptions(options)}
              styles={styles || colourStyles}
              placeholder={floatingLabel ? labelText : customSelectPlaceholder}
              openMenuOnFocus
              tabSelectsValue={tabSelectsValue && (value === '' || hasInputVal)}
              isDisabled={disabled}
              onInputChange={floatingLabel ? null : handleInputChange}
              {...multiSelectProp}
              {...customComponentProp}
              floatingLabel
              width
            />
          )}
          {hasTooltip && tooltipPlacement === 'field' && (
            <CustomSelectTooltip />
          )}
        </SelectWrapper>
      </SelectContainer>
      {showHelperText ? HelperTextComponent : ErrorComponent}
    </ThemeProvider>
  )
}

const defaultOptions = [
  {
    id: 'IN',
    label: 'India',
    disabled: false
  },
  {
    id: 'US',
    label: 'USA',
    disabled: false
  },
  {
    id: 'UK',
    label: 'United Kingdom',
    disabled: false
  },
  {
    id: 'GR',
    label: 'Germany',
    disabled: false
  },
  {
    id: 'SP',
    label: 'Spain (disabled)',
    disabled: true
  }
]

ReactSelect.defaultProps = {
  floatingLabel: true,
  labelIsHTML: false,
  type: 'D',
  width: 'L',
  disabled: false,
  corner: 'round',
  labelMargin: 'min',
  options: defaultOptions,
  maxOptionsCount: 5,
  filterFunction: undefined,
  isControlled: false,
  value: '',
  asyncProps: {},
  enableAsync: false,
  customSelectPlaceholder: 'Select...',
  tooltipPlacement: 'field',
  tabSelectsValue: false,
  isAsterisk: false
}

ReactSelect.propTypes = {
  /**
   * An ID for the component
   */
  id: PropTypes.string.isRequired,
  /**
   * Ref for React Select.
   */
  inputRef: PropTypes.object,
  /**
   * A label for the input.
   */
  labelText: PropTypes.string.isRequired,
  /**
   * A placeholder for the input.
   */
  placeholder: PropTypes.string.isRequired,
  /**
   * Indicates behaviour of label.
   */
  floatingLabel: PropTypes.bool,
  /**
   * Indicates if select field is disabled.
   */
  disabled: PropTypes.bool,
  /**
   * Custom React Select specific style object.
   */
  styles: PropTypes.object,
  /**
   * Position of label, works only if floatingLabel is set to `false`.
   */
  labelPosition: PropTypes.oneOf(['left', 'top']),
  /**
   * Indicates if label is an HTML element.
   */
  labelIsHTML: PropTypes.bool,
  /**
   * Determines the appearance of edge of the box.
   */
  corner: PropTypes.oneOf(['round', 'sharp']),
  /**
   * It refers to the gap between label and the input field. It is applicable only
   * for fixed label when label appears on the left side.
   */
  labelMargin: PropTypes.oneOf(['min', 'max']),
  /**
   * On change handler
   */
  handleSelect: PropTypes.func.isRequired,
  /**
   * Options if it is a dropdown.
   */
  options: PropTypes.array,
  /**
   * Dropdown, Autosuggest, Multiselect Dropdown, DropdownSearch
   */
  type: PropTypes.oneOf(['D', 'A', 'M', 'DS']),
  /**
   * Width of the input field
   */
  width: PropTypes.oneOf(['S', 'M', 'L', 'XL']),
  /**
   * Maximum number of options count.
   */
  maxOptionsCount: PropTypes.number,
  /**
   * Custom Filter Function
   */
  filterFunction: PropTypes.func,
  /**
   * Set filter support.
   */
  isFilterable: PropTypes.bool.isRequired,
  /**
   * Default selected
   */
  defaultSelected: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  /**
   * Displays value passed as the prop (makes it a controlled select)
   */
  isControlled: PropTypes.bool,
  /**
   * Value passed by the parent
   */
  value: PropTypes.string,
  /**
   * Theme configuration object
   */
  themeConfiguration: PropTypes.object,
  enableAsync: PropTypes.bool,
  asyncProps: PropTypes.object,
  /**
   * A custom placeholder for the select input.
   */
  customSelectPlaceholder: PropTypes.string,
  /**
   * If true, the labels for each options will be rendered without capitalizaion; defaults to false
   */
  disableCapitalization: PropTypes.bool,
  /**
   * places tooltip beside field or label.
   */
  tooltipPlacement: PropTypes.oneOf(['field', 'label']),
  /**
   * Determines the color of error message.
   */
  errorMessageColor: PropTypes.string,
  /**
   * Indicate if the input is required or not.
   */
  isAsterisk: PropTypes.bool,
  /**
   * Indicate if the autoComplete is required or not.
   */
  autoComplete: PropTypes.string
}

export default ReactSelect
