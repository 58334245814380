import {getLogin, postLogin, getLogoutRedirect, getTraits} from "./api/api";
import {
  setItemWithExpiry,
  getItemWithExpiry,
  forceDeleteItem,
  getSamlIdpTokenFromCookie,
  executeSamlFormUrl
} from "./utils/CommonUtil"
import { AppConstants } from "./model/AppConstants";
import { PostLoginResponse, IdpTokenReqParamKeyEnum } from "./model/PostLogin";
import { LogoutResponse } from './model/LogoutResponse';


const cleanUpLocalStorage = ():void => {
  forceDeleteItem(AppConstants.accessToken);
  forceDeleteItem(AppConstants.expiresAt);
  forceDeleteItem(AppConstants.expiresIn);
  forceDeleteItem(AppConstants.baseUri);
  forceDeleteItem(AppConstants.role);
  forceDeleteItem(AppConstants.redirectUrl);
  forceDeleteItem(AppConstants.isIdpInitiatedFlow);
};

window.onload = async () => {
  const isIdPInitiatedFlow = !!(getItemWithExpiry(AppConstants.isIdpInitiatedFlow));
  let url = window.location.search;
  if (!isIdPInitiatedFlow && (url?.includes(AppConstants.code) || url?.includes(AppConstants.saml))) {
    console.debug('Not idp initiated flow. Trigger post login flow to get the access token');
    const baseUri = getItemWithExpiry(AppConstants.baseUri);
    const role = getItemWithExpiry(AppConstants.role);
    const redirectUrl = getItemWithExpiry(AppConstants.redirectUrl);
    if (baseUri && role && redirectUrl) {
      await getAccessTokenAndRedirect(baseUri, role, redirectUrl);
    }
  }
}

/**
 * Entry function to initiate the SSO auth flow. This function doesn't return as it changes the context to IDP
 * Hence, errors or success is communicated via redirect URL to the parent. If no accessToken is available in localStorage, that's an error
 * condition
 * @param baseUri - Base URI of Lifetime auth service
 * @param role - User Type that's mapped to SSO configuration
 * @param redirectUrl - URL to redirect users to after tokens are fetched
 * @param isIdPInitiatedFlow - (Optional) boolean to tell whether this is idp initiated flow
 */
export async function executeAuthRequest(baseUri: string, role: string, redirectUrl: string, isIdPInitiatedFlow?: boolean) {
  cleanUpLocalStorage();
  console.debug(`Received Baseurl: ${baseUri}, Role: ${role}, RedirectUrl: ${redirectUrl}, isIdpInitiatedFlow: ${!!isIdPInitiatedFlow}`);
  if (baseUri && role && redirectUrl) {
    // Storing the values in localstorage to use in post call on pageload after the redirection from thirdparty URL
    setItemWithExpiry(AppConstants.baseUri, baseUri, AppConstants.storageAccessTime);
    setItemWithExpiry(AppConstants.role, role, AppConstants.storageAccessTime);
    setItemWithExpiry(AppConstants.redirectUrl, redirectUrl, AppConstants.storageAccessTime);
    setItemWithExpiry(AppConstants.isIdpInitiatedFlow, !!isIdPInitiatedFlow, AppConstants.storageAccessTime);
    if(isIdPInitiatedFlow) {
      console.debug('Initiating idp initated flow');
      await getAccessTokenAndRedirect(baseUri, role, redirectUrl);
    } else {
      console.debug('Initiating sp initiated flow')
      await getLogin(baseUri, role);
    }
  } else {
    console.error ("Required parameters are missing");
    if(!baseUri) {
      console.error("BaseUri is missing");
    }
    if(!role) {
      console.error("Role/UserType is missing");
    }
    if(!redirectUrl) {
      console.error("RedirectUrl is missing");
    }
    window.location.replace(redirectUrl);
  }
}

async function getAccessTokenAndRedirect(baseUri: string, role: string, redirectUrl: string) {
  const queryString = window.location.search;
  if(!queryString) {
    throw new Error('Missing query string');
  }
  const urlParams = new URLSearchParams(queryString);
  console.debug(`Query String: ${queryString}. Url Params: ${urlParams}`);
  let idpToken = "";
  let requestKey = IdpTokenReqParamKeyEnum.Code;
  if(urlParams.has(AppConstants.saml)){
    requestKey = IdpTokenReqParamKeyEnum.SamlResponse;
    const samlCookieKey = `${urlParams.get(AppConstants.saml)}_${requestKey}`;
    console.debug(`Saml cookie key: ${samlCookieKey}`);
    idpToken = getSamlIdpTokenFromCookie(samlCookieKey);
    if(idpToken === undefined) {
      throw new Error(`SAML Cookie with key: ${samlCookieKey} not found.`)
    }
  }else if(urlParams.has(AppConstants.code)){
    idpToken = urlParams.get(AppConstants.code);
    if(idpToken === undefined) {
      throw new Error(`OIDC code not found.`)
    }
  }
  console.debug(`IDP token: ${idpToken}`);
  if (idpToken) {
    try {
      const postLoginResponse: PostLoginResponse | void = await postLogin(baseUri, idpToken, role, requestKey);
      console.debug('Post Login Response:');
      console.debug(postLoginResponse);
        if (postLoginResponse) {
          setItemWithExpiry(AppConstants.accessToken, '' + postLoginResponse?.data?.response?.accessToken, AppConstants.storageAccessTime);
          setItemWithExpiry(AppConstants.expiresAt, '' + postLoginResponse?.data?.response?.expiresAt, AppConstants.storageAccessTime);
          setItemWithExpiry(AppConstants.expiresIn, '' + postLoginResponse?.data?.response?.expiresIn, AppConstants.storageAccessTime);
          window.location.replace(redirectUrl);
        }else{
          console.error("There is some issue with post login response", postLoginResponse);
          window.location.replace(redirectUrl);
        }

    } catch (error) {
      console.error("PostLoginError", error);
      window.location.replace(redirectUrl);
    }
  }else{
    console.error("No IDP Token");
    window.location.replace(redirectUrl);
  }
}

/**
 * Logout users across all SSO session
 * @param baseUri - Base url of Lifetime auth service
 * @param accessToken - Sureify access token
 */
export async function executeLogout(baseUri: string, accessToken: string) {
  if(!baseUri) {
    throw new Error('Base url not provided');
  }

  if(!accessToken) {
    throw new Error('Access token not provided');
  }

  const logoutRedirect: LogoutResponse = await getLogoutRedirect(baseUri, accessToken);
  const redirectUrl: string = logoutRedirect?.data?.redirectUrl?.url;
  const renderForm: string = logoutRedirect?.data?.renderForm?.form;
  if(redirectUrl) {
    window.location.replace(redirectUrl);
  } else if(renderForm) {
    executeSamlFormUrl(renderForm);
  } else {
    throw new Error('Missing IDP SSO logout url/form in the response');
  }
}

/**
 * Fetch all or set of traits for a given configuration
 * @param baseUri - Base url of Lifetime auth service
 * @param accessToken - Sureify access token provided by the Lifetime auth service
 * @param traitKeys - Optional argument to retrieve selected traits
 */
export async function getSSOTraits(baseUri: string, accessToken: string, traitKeys?: Array<string>): Promise<Map<string, unknown>> {
  if(!baseUri) {
    throw new Error('Base url not provided');
  }
  if(!accessToken) {
    throw new Error('Access token not provided');
  }
  const traitsResponse = await getTraits(baseUri, accessToken, traitKeys);
  return traitsResponse.data;
}