import styled from 'styled-components'

const getSize = (width) => {
  if (width <= 320) {
    return '90vw'
  } else if (width > 320 && width <= 480) {
    return '80vw'
  } else if (width > 481 && width <= 720) {
    return '60vw'
  } else {
    return '50vw'
  }
}

const getWidth = (width) => {
  if (typeof width === 'number') {
    return getSize(width)
  }
  if (width === 'S') {
    return '20vw'
  } else if (width === 'M') {
    return '40vw'
  } else if (width === 'L') {
    return '70vw'
  } else if (width === 'XL') {
    return '90vw'
  }
  return width
}

export const Overlay = styled.div`
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: ${(props) => props.top || 0};
  left: ${(props) => props.left || 0};
  right: ${(props) => props.right || 0};
  bottom: ${(props) => props.bottom || 0};
  background-color: ${({ overlayBgColor }) =>
    overlayBgColor || 'rgba(255, 255, 255, 0.2)'};
  z-index: ${({ zIndex }) => zIndex || '9999'};
  cursor: default;
`

export const Content = styled.div`
  margin: ${(props) => props.margin || '7% auto'};
  background-color: white;
  border-radius: 0.25rem;
  width: ${(props) => getWidth(props.width)};
  ${({ isHeightFixed, height, maxHeight }) =>
    isHeightFixed ? `height: ${height};` : `max-height: ${maxHeight};`}
  padding: 0.5rem 1.4rem;
  position: relative;
  text-align: ${(props) => props.alignContent || 'left'};
  border-top: ${(props) => props.border || '1.5rem solid white'};
  border-bottom: ${(props) => props.border || '1.5rem solid white'};
  border-left: ${(props) => props.border || '1px solid white'};
  border-right: ${(props) => props.border || '1px solid white'};
  overflow-y: auto;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
`
export const CloseIcon = styled.span`
  color: #000000;
  padding: 10px;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  position: absolute;
  top: 0;
  right: 1.2rem;
  font-size: 30px;
  @media (min-width: 480px) and (max-width: 1024px) {
    right: 0.5rem;
  }
`

export const ModalHeader = styled.div`
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 1em 0;
`

export const ModalBody = styled.div`
  padding: 10px;
  font-size: ${(props) => props.fontSize || '16px'};
  @media (min-width: 480px) and (max-width: 1024px) {
    padding: 0px;
    &:focus {
      outline: 0px solid transparent;
    }
  }
  @media (max-width: 480px) {
    padding: ${(props) => props.bodyPadding || '10px'};
  }
`

export const ModalFooter = styled.div`
  margin-top: 1em;
  text-align: ${(props) => props.alignFooter || 'right'};
`
export const ModalChildrenWrapper = styled.div`
  padding: 10px;
  @media (min-width: 480px) and (max-width: 1024px) {
    padding: 0px;
    &:focus {
      outline: 0px solid transparent;
    }
  }
`
