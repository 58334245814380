import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { CheckboxWrapper } from './styles'
import Checkbox from './Checkbox'
import { themes } from '../../themes'

const CheckboxGroup = (props) => {
  const {
    options,
    orientation,
    shape,
    label,
    label1,
    color,
    onChange,
    inputRef,
    selectedOptions,
    columns,
    isControlledInput,
    themeConfiguration,
    handlePopup,
    disableGroup,
    id,
    alignTopWithLabel
  } = props

  // Remove this when theme is passed from all the clients
  const theme = themeConfiguration || themes[color]

  return (
    <ThemeProvider theme={{ color, shape, orientation, columns, ...theme }}>
      {label && <div dangerouslySetInnerHTML={{ __html: label }} />}
      {label1}
      <CheckboxWrapper id={`checkbox-wrapper-${id}`}>
        {options.map((option) => (
          <Checkbox
            key={option.id}
            id={`checkbox-option-${id}-${option.id}`}
            inputRef={inputRef}
            label={option.label}
            label1={option.label1}
            shape={shape}
            handlePopup={handlePopup}
            isControlledInput={isControlledInput}
            disabled={disableGroup || option.disabled}
            checked={selectedOptions && selectedOptions.indexOf(option.id) > -1}
            handleCheckboxChange={(e) => onChange(e, option)}
            alignTopWithLabel={alignTopWithLabel}
            tooltipProp={option.tooltipProp}
          />
        ))}
      </CheckboxWrapper>
    </ThemeProvider>
  )
}
CheckboxGroup.defaultProps = {
  shape: 'square',
  orientation: 'rows',
  color: 'blue',
  label: '',
  selectedOptions: [],
  columns: 0,
  isControlledInput: false,
  disableGroup: false
}

CheckboxGroup.propTypes = {
  /**
   * Label for the checkbox group.
   */
  label: PropTypes.string,
  /**
   * Description of all available options
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  /**
   * disable all checkboxes at once if disableGroup is true
   */
  disableGroup: PropTypes.bool,
  /**
   * Ref for Checkbox Group.
   */
  inputRef: PropTypes.object,
  /**
   * List of selected option ids
   */
  selectedOptions: PropTypes.array,
  /**
   * Shape of the checkbox
   */
  shape: PropTypes.oneOf([
    'square',
    'squircle',
    'cardcircle',
    'standard_squircle'
  ]),
  /**
   * Orientation of the checkboxes inside the group. I
   * */
  orientation: PropTypes.oneOf(['rows', 'columns']),
  /**
   * Determines the color theme of the Checkbox
   */
  color: PropTypes.string,
  /**
   * Change handler
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Number of columns to be displayed in a row
   */
  columns: PropTypes.number,
  /**
   * Makes checkbox a controlled component
   */
  isControlledInput: PropTypes.bool,
  /**
   * Theme configuration object
   */
  themeConfiguration: PropTypes.object,
  /**
   * Id for the checkbox group.
   */
  id: PropTypes.string
}

export default CheckboxGroup
