import { AppConstants } from "./AppConstants";

export interface GetLoginResponse {
    code: number,
    data: GetData,
    errors?: string,
}

export interface GetData {
    redirectUrl?: GetRedirect,
    responseType: string,
    serverName: string,
    serverType: string,
    render?: GetRedirect

}

export interface GetRedirect {
    url?: string,
    form?: string
}

export function getLoginResponseFromJson(json: any): GetLoginResponse {
    return {
        code: json?.code,
        data: getDataFromJson(json?.data),
        errors: json?.errors,
    };
}

export function getDataFromJson(data: any): GetData {
    let rtnData = null;
    if(!data) throw new Error("Missing data");
    if (data.srv_type === AppConstants.oidc) {
        rtnData = {
            redirectUrl: getRedirect(data.redirect),
            responseType: data.response_type,
            serverName: data.srv_name,
            serverType: data.srv_type
        };
    } else if (data.srv_type === AppConstants.saml2) {
        if (data.redirect) {
            rtnData = {
                redirectUrl: getRedirect(data.redirect),
                responseType: data.response_type,
                serverName: data.srv_name,
                serverType: data.srv_type
            };
        } else if (data.render){
            rtnData = {
                responseType: data.response_type,
                serverName: data.srv_name,
                serverType: data.srv_type,
                render: getRender(data.render)
            };
        } else {
            throw new Error("Missing redirect or render info");
        }
    }  else {
        throw new Error("Missing server type info");
    }
    return rtnData;
}

export function getRedirect(redirect: any): GetRedirect {
    return {
        url: redirect?.url
    };
}

export function getRender(render: any): GetRedirect {
    return {
        form: render?.form
    };
}
