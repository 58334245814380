import React, { useState, useLayoutEffect } from 'react'
import ReactHtmlParser from 'react-html-parser'

const isMobile = /iPhone|Android/i.test(navigator.userAgent)

/**
 * @description Checks innerHTML input for script tag.
 * @param {String} str html string
 * @param {Boolean} isAsterisk
 * @returns {string|*}
 */
const validateInnerHTML = (str, isAsterisk = false) => {
  if (!str) return ''
  try {
    if (str.match(/<script>/gi)) {
      console.error('Cannot insert script tags')
      return ''
    }
    if (isAsterisk) return stripHtml(str)
    else return str
  } catch (e) {
    console.error(e)
    return ''
  }
}

const useDocumentWidth = () => {
  const [width, setWidth] = useState()
  useLayoutEffect(() => {
    const updateWidth = () => {
      setWidth(document.documentElement.clientWidth)
    }
    window.addEventListener('resize', updateWidth)
    updateWidth()
    return () => window.removeEventListener('resize', updateWidth)
  }, [])
  return width
}

const stripHtml = (html) => {
  const tmp = document.createElement('DIV')
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ''
}

const parseHtml = (htmlText, clickHandler) => {
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      clickHandler(e)
    }
  }
  return ReactHtmlParser(htmlText, {
    transform: (node) => {
      if (
        node.name === 'a' &&
        node.attribs &&
        node.attribs.modal_reference_id
      ) {
        return (
          <a
            onClick={clickHandler}
            onKeyDown={(e) => handleKeyDown(e)}
            className='anchor'
            role='button'
            tabIndex='0'
          >
            {node.children[0].data}
          </a>
        )
      }
    }
  })
}

export { isMobile, validateInnerHTML, useDocumentWidth, parseHtml, stripHtml }
