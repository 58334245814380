import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Tab, TabPanel, Tabs, TabList } from 'react-tabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import 'react-tabs/style/react-tabs.css';
import {
  ClickableCellWrapper,
  CellContent,
  CellContentIcon,
  EmptyTabWrapper,
  GlobalStyles,
  TabGrid
} from './styles';

const StyledTabPanel = styled(TabPanel)`
  pointer-events: ${({ disabled }) => disabled ? 'none' : 'auto'}
`;

const ClickableCell = ({
  colIndex,
  rowIndex,
  tabIndex,
  columnObj,
  currentSelection,
  handleChange,
  disabled
}) => {
  const {
    content,
    is_selected: defaultSelected,
    show_checkmark: showCheckmark
  } = columnObj;

  useEffect(() => {
    if (defaultSelected) {
      handleChange({ tab: tabIndex, row: rowIndex, col: colIndex });
    }
  }, []);

  const isSelected =
    currentSelection &&
    rowIndex === currentSelection.row &&
    colIndex === currentSelection.col &&
    tabIndex === currentSelection.tab;

  const handleClick = () => {
    handleChange({ tab: tabIndex, row: rowIndex, col: colIndex });
  };

  return (
    <ClickableCellWrapper>
      <CellContent
        isSelected={isSelected}
        onClick={handleClick}
        tabIndex={ disabled ? '-1' : '0' }
        onKeyDown={(e) => {
          if (e.key === 'Enter') handleClick();
        }}
      >
        {content}
      </CellContent>
      <CellContentIcon>
        {showCheckmark && (
          <FontAwesomeIcon
            icon={faCheckCircle}
            color='#00534c'
            className='checkmark'
          />
        )}
      </CellContentIcon>
    </ClickableCellWrapper>
  );
};

const NoContent = ({ tab, onChange }) => {
  useEffect(() => {
    onChange({ noPlans: true });
  }, []);

  return <EmptyTabWrapper>{tab.message}</EmptyTabWrapper>;
};

const firstColumnStyles = (colIndex) =>
  colIndex === 0 ? { justifyContent: 'flex-start' } : {};

const getTabs = (tabs, disabled) =>
  tabs.map((tab, index) => <Tab key={index} disabled={ disabled }>{tab.title}</Tab>);

const ReactTab = ({ tabs = [], defaultTab = 0, onChange, disabled }) => {
  const [currentSelection, setCurrentSelection] = useState({});

  const handleChange = (obj) => {
    if (!disabled) {
      setCurrentSelection(obj);
      onChange(obj);
    }
  };

  return (
    <Tabs defaultIndex={defaultTab} onChange={() => handleChange({})} disableUpDownKeys={ disabled }>
      <GlobalStyles />
      <TabList>{getTabs(tabs, disabled)}</TabList>
      {tabs.map((tab, tabIndex) => {
        return (
          <StyledTabPanel key={tabIndex} disabled={ disabled }>
            {tab.message ? (
              <NoContent tab={tab} onChange={onChange}>
                {tab.message}
              </NoContent>
            ) : (
              <TabGrid colNum={tab.header.length}>
                {tab.header.map((header, index) => (
                  <div
                    style={firstColumnStyles(index)}
                    className='grid-cell header-cell'
                    key={index}
                  >
                    <span>{header.heading}</span>
                    <span />
                  </div>
                ))}
                {tab.data.map((row, rowIndex) => {
                  return row.map((col, colIndex) => (
                    <div
                      style={firstColumnStyles(colIndex)}
                      className='grid-cell'
                      key={colIndex}
                    >
                      {col.clickable ? (
                        <ClickableCell
                          rowIndex={rowIndex}
                          colIndex={colIndex}
                          tabIndex={tabIndex}
                          columnObj={col}
                          currentSelection={currentSelection}
                          handleChange={handleChange}
                          disabled={ disabled }
                        />
                      ) : (
                        <span style={{ padding: '6px 14px' }}>
                          {col.content}
                        </span>
                      )}
                    </div>
                  ));
                })}
              </TabGrid>
            )}
            <hr />
          </StyledTabPanel>
        );
      })}
    </Tabs>
  );
};

export default ReactTab;
