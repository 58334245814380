import styled from 'styled-components'
import { themes } from '../../themes'

const getBorderColor = (isFocused, color, themeConfiguration) => {
  if (themeConfiguration) {
    return isFocused
      ? themeConfiguration.focusColor
      : themeConfiguration.borderColor
  }
  return isFocused ? themes[color].focusColor : '#999999'
}

const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.errorMessageColor || '#ff0000'};
  font-size: 0.75em;
  padding: 0.5em;
  visibility: ${({ theme }) => (theme.hasError ? 'visible' : 'hidden')};
`

const HelperText = styled.span`
  color: ${({ theme }) => theme.helperTextColor || '#555555'};
  font-size: 0.75em;
  padding: 0.5em;
  padding-left: 0em;
  width: 100%;
`

const SelectContainer = styled.div`
  display: flex;
  flex-direction: ${({ theme: { labelPosition } }) =>
    labelPosition === 'left' ? 'row' : 'column'};
  ${({ theme: { labelPosition } }) =>
    labelPosition === 'left' && 'align-items: center'};
`

const SelectWrapper = styled.div`
  display: flex;
  align-items: ${({ theme }) => theme.labelWrapperStyles?.alignItems || ''};
  ${(props) => props.hasTooltip === true && `position: relative;`}
  ${({ theme: { labelText } }) =>
    labelText &&
    `
      & > span {
        color:${labelText.color};
        font-size:${labelText.fontSize};
        font-family:${labelText.fontFamily};
        line-height:${labelText.lineHeight};
        font-weight:${labelText.fontWeight};
      }
    `};
  ${({ theme: { spanText } }) =>
    spanText &&
    `
      div {
          color:${spanText.fontColor};
          font-size:${spanText.fontSize};
          font-family:${spanText.fontFamily};
        }
    `};
  ${({ theme: { labelPosition, width } }) =>
    labelPosition === 'left'
      ? `
      margin-left: 10px;
      width: ${width || '50%'};
      & > div {
        width: 100%;
      }
    `
      : `
      margin-left: 0;
      width: 100%;
    `}
  @media (max-width: 767px) {
    margin-left: 0;
    width: 100%;
    ${({ theme: { selectWrapper } }) =>
      selectWrapper && selectWrapper.width && `width: ${selectWrapper.width}`};
    ${(props) => props.exactWidth && 'width : 100%'};
  }

  ${({ theme: { selectWrapper } }) =>
    selectWrapper && selectWrapper.width && `width: ${selectWrapper.width}`};
  ${(props) => props.exactWidth && 'width : 100%'};
`

const SelectLabelWrapper = styled(SelectWrapper)`
  ${({ theme: { labelPosition, selectLabel } }) =>
    labelPosition === 'left' && selectLabel && selectLabel.marginTop && `margin-top: ${selectLabel.marginTop}`};
  margin-left: 0;
  & > div {
    width: auto;
  }
  @media (min-width: 768px) {
    & > label {
      ${({ theme: { labelPosition } }) => labelPosition === 'left' && 'width: 100%'};
    }
  }
`

const SelectLabel = styled.label`
  ${({ theme }) =>
    theme.selectLabel
      ? `
      text-align: ${
        theme.hasTooltip && theme.selectLabel.labelTooltipAlign
          ? `${theme.selectLabel.labelTooltipAlign}`
          : ''
      };
      span,
      div {
        font-family: ${theme.selectLabel.fontFamily || 'inherit'};
        font-weight: ${theme.selectLabel.fontWeight};
        color: ${theme.selectLabel.color};
        font-size:${theme.selectLabel.fontSize};
        line-height:${theme.selectLabel.lineHeight};
        font:${theme.selectLabel.font};
      }
      `
      : `
    div {
      font-weight: bold;
      color: #767676;
    }
    `};
  ${(props) => props.isAsterisk ? `
    &:before{
      content:'*';
      margin:5px 5px 0 0;
    }` : ''};
`

export {
  SelectContainer,
  SelectWrapper,
  getBorderColor,
  ErrorMessage,
  SelectLabel,
  SelectLabelWrapper,
  HelperText
}
