export interface TraitsResponse {
  code: string;
  data: Map<string, unknown>;
  errors: string;
}

export function getTraitsResponseFromJson(json: any): TraitsResponse | null {
  return !!json ? {
    code: json.code,
    errors: json.errors,
    data: !!json.data ? objectToMap(json.data) : null
  } : null;
}

const objectToMap = (object: Object): Map<string, unknown> => {
  const traitsMap: Map<string, unknown> = new Map();
  Object.entries(object).forEach((pair: [string, any]) => {
    traitsMap.set(pair[0], pair[1]);
  })
  return traitsMap;
}
