import styled from 'styled-components'
import DatePicker from '../../packages/react-datepicker'

const StyledSpan = styled.span`
  z-index: 2001;
  outline: 0;
  &:focus {
    outline: 2px solid black;
  }
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${({ theme }) =>
    theme.calendarIconPlacement && theme.calendarIconPlacement === 'left'
      ? `left: 10px`
      : `right:18px`}
`

const StyledIcon = styled.i`
  height: 100%;
  outline: 0;
  ${(props) =>
    props.isDate && `color: ${props.theme.calendarIconColor || '#a1a1a1'}`};
  margin-right: ${(props) => (props.isDate ? '' : '10px')};
  ${({ theme }) => 
    (theme.disabled && theme.disabledPointer) ? `
      cursor: ${theme.disabledPointer};    
      ` : null
  }
`

const StyledDatePicker = styled(DatePicker)`
  position: relative;
  ::-ms-clear {
    display: none;
  }
  &&& {
    width: 100%;
    ${({ theme }) =>
        ( theme.disabled && theme.disabledPointer ) ? `
            cursor: ${theme.disabledPointer};
        ` : null }
  }
`

const DateErrorContainer = styled.div`
  background: ${(props) => (props.errorMessageColor ? '#ffffff' : '#e60000')};
  color: ${(props) => props.errorMessageColor || '#ffffff'};
  font-size: 12px;
  padding: 8px;
  margin: 5px;
  max-width: 262px;
`

export { StyledSpan, StyledDatePicker, StyledIcon, DateErrorContainer }
