import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Message,
  Wrapper,
  IconMsgDiv,
  CloseIconDiv,
  Iconstyle,
  CancelStyle,
  Cancel
} from './styles'
import { ThemeProvider } from 'styled-components'

const Toaster = ({
  intent,
  showToaster,
  message,
  hideCallback,
  timeout,
  size,
  shape,
  showIcon,
  showCancel,
  customColor,
  tabIndex,
  toasterRef
}) => {
  const [show, setShow] = useState(showToaster)
  const hideToaster = useCallback(hideCallback, [hideCallback, showToaster])
  useEffect(() => {
    if (showToaster) {
      setShow(true)
      setTimeout(() => {
        setShow(false)
        hideToaster && hideToaster()
      }, timeout * 1000)
    }
  }, [hideToaster, showToaster])

  if (show) {
    let iconSrc = null
    if (intent) {
      if (intent === 'success') {
        iconSrc = <i className='fa fa-check' style={Iconstyle} />
      } else if (intent === 'warning') {
        iconSrc = <i className='fa fa-exclamation-triangle' style={Iconstyle} />
      } else if (intent === 'error') {
        iconSrc = <i className='fa fa-times' style={Iconstyle} />
      } else if (intent === 'info') {
        iconSrc = <i className='fa fa-info' style={Iconstyle} />
      } else {
        iconSrc = <i className='fa fa-times' style={Iconstyle} />
      }
    }

    return (
      <ThemeProvider theme={{ size, shape, customColor, showCancel }}>
        <Wrapper
          name='wrapper'
          type={intent}
          tabIndex={tabIndex}
          ref={toasterRef}
          role='alert'
        >
          <IconMsgDiv>
            {showIcon && iconSrc}
            <Message name='message'>{message}</Message>
          </IconMsgDiv>
          <CloseIconDiv>
            <Cancel name='cancel' onClick={() => setShow(false)}>
              <span style={CancelStyle} aria-hidden='true'>
                &times;
              </span>
            </Cancel>
          </CloseIconDiv>
        </Wrapper>
      </ThemeProvider>
    )
  }
  return null
}

Toaster.defaultProps = {
  intent: 'error',
  timeout: 5,
  hideCallback: undefined,
  toasterRef: null
}

Toaster.propTypes = {
  /**
   * Intention of showing toaster.
   */
  intent: PropTypes.oneOf(['error', 'warning', 'info', 'success']),
  /**
   * Message to be displayed
   */
  message: PropTypes.string.isRequired,
  /**
   * Duration after which toaster should disappear (in seconds).
   */
  timeout: PropTypes.number,
  /**
   * Callback function to be called when toaster disappears.
   */
  hideCallback: PropTypes.func,
  /**
   * Size of toaster. Supports 'L'
   */
  size: PropTypes.string,
  /**
   * By default toaster has border-radius applied. shape='rectangle' gives with-out border radius
   */
  shape: PropTypes.string,
  /**
   * By default toaster does not show any icon. We can enable this to show icons before the message
   */
  showIcon: PropTypes.bool,
  /**
   * By default toaster does not have a cancel button. We can enable by passing this prop
   */
  showCancel: PropTypes.bool,
  /**
   * By default toaster has pre-defined colors based on the intent. If we wish to give custom colors we can pass it as a string eg. '#767676'
   */
  customColor: PropTypes.string,
  /**
   * tabIndex of the button
   */
  tabIndex: PropTypes.number,
  /**
   * reference of the toaster
   */
  toasterRef: PropTypes.object
}
export default Toaster
