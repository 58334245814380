import styled from 'styled-components'
import { themes } from '../../themes'

const StyledButton = styled.button`
  // outline: none !important;
  ${({ theme: { outlineOffset } }) =>
    outlineOffset && `outline-offset: ${outlineOffset}`};
  ${({ theme: { width } }) =>
    width === 'variable'
      ? `
    padding: 5px 20px;
  `
      : `
    width: ${width};
    padding: 10px;
  `};
  ${({ theme }) => theme.addBtnTheme};
  display: flex;
  align-items: center;
  ${({ theme }) => theme.fontFamily && `font-family: ${theme.fontFamily};`}
  ${({ theme }) =>
    theme.iconAlignment === 'left' && `flex-direction: row-reverse `};
  justify-content: ${({ theme }) =>
    theme.alignment === 'right' ? 'flex-end' : theme.alignment};
  ${({ theme }) =>
    theme.disabled
      ? `background-color: ${
          theme.supportWCAGTheme
            ? themes.WCAGDisabled.selectedBgColor
            : theme.disabledTheme
            ? theme.disabledTheme.selectedBgColor
            : themes.disabled.selectedBgColor
        };
         color: ${
           theme.supportWCAGTheme
             ? themes.WCAGDisabled.fontColor
             : theme.disabledTheme
             ? theme.disabledTheme.bgColor
             : themes.disabled.fontColor
         }`
      : theme.type === 'filled' ||
        (theme.type === 'subsection' && theme.exactBtnTheme)
      ? ` background-color: ${theme.selectedBgColor};
          color: ${theme.bgColor}
      `
      : `background: none;
         color: ${theme.selectedBgColor};
      `};
  ${({ theme }) =>
    theme.type === 'text' ||
    (theme.type === 'subsection' && !theme.exactBtnTheme) ||
    theme.type === 'cancel'
      ? `border: none;
      background: none;`
      : `border: ${theme.disabled ? themes.disabled.border : theme.border};`};
  border-radius: ${({ theme }) =>
    theme.shape === 'rectangle' ? '4px' : '16px'};
    font-size: ${({ theme }) => (theme.fontSize ? theme.fontSize : '18px')};
    font-weight: ${({ theme }) => theme.fontWeight};
    font: ${({ theme }) => theme.font};
    padding:${({ theme: { padding, disabled, disabledPadding } }) =>
      disabled ? disabledPadding : padding};
    padding-left: ${({ theme }) => theme.paddingLeft};
    margin-left:${({ theme }) => theme.marginLeft};
    margin-right:${({ theme }) => theme.marginRight};
    border-radius:${({ theme }) => theme.borderRadius};
    line-height:${({ theme }) => theme.lineHeight};
    border-right:${({ theme }) => theme.borderRight};
    border-left:${({ theme }) => theme.borderLeft};
    border-bottom:${({ theme }) => theme.borderBottom};
    ${({ theme: { buttonBorder, disabled, supportWCAGTheme } }) =>
      buttonBorder &&
      `
      border: ${
        !disabled
          ? buttonBorder
          : supportWCAGTheme
          ? themes.WCAGDisabled.border
          : themes.disabled.border
      }; 
    `}
  cursor: pointer;
    ${({ theme }) =>
      !theme.disabled && (theme.type === 'filled' || theme.type === 'outlined')
        ? `&:hover { 
          background-color: ${
            theme.hover ? theme.hover.selectedBgColor : theme.selectedBgColor
          };
          color: ${theme.hover ? theme.hover.bgColor : theme.bgColor};
          border:${theme.hover ? theme.hover.border : ''}
        };
        &:focus {
          background-color: ${theme.selectedBgColor};
          color: ${theme.bgColor}
        }
     `
        : null}
    ${({ theme }) =>
      theme.disabled && theme.disabledPointer
        ? `&:hover {
          cursor: ${theme.disabledPointer};
        }
      `
        : null}
    ${({ theme }) => theme.margin && `margin:${theme.margin}`}
  }
  &:focus {
    ${({ theme: { type, borderBottom, borderOnFocus, focusMargin } }) =>
      type === 'text' && borderBottom
        ? `
      border-bottom: ${borderBottom};
    `
        : `
    border: ${borderOnFocus};
    margin:${focusMargin};
    `}
    background-color: ${({ theme }) => theme.bgOnFocus};
    text-decoration: ${({ theme }) => theme.textDecoration};
    text-decoration-thickness: ${({ theme }) => theme.textDecorationThickness};
  }
`
const FaIcon = styled.i`
  display: ${({ theme }) =>
    theme.type !== 'subsection' ? 'none' : 'inline-block'};
  ${({ theme }) =>
    theme.iconAlignment === 'left'
      ? `margin-right: 10px`
      : `margin-left: 10px`};
`
const FaCrossIcon = styled.i`
  display: ${({ theme }) =>
    theme.type !== 'cancel' ? 'none' : 'inline-block'};
  ${({ theme }) =>
    theme.iconAlignment === 'left'
      ? `margin-right: 10px`
      : `margin-left: 10px`};
`
const FaLoadingIcon = styled.i`
  display: ${({ theme }) => (theme.isLoading ? 'inline-block' : 'none')};
  ${({ theme }) =>
    theme.iconAlignment === 'left'
      ? `margin-right: 10px`
      : `margin-left: 10px`};
`

const FaPlusCircleIcon = styled.i`
  display: ${({ theme }) => (theme.showPlusIcon ? 'inline-block' : 'none')};
  margin-right: 10px;
`
const ButtonDescription = styled.div`
  ${({ theme: { buttonDescriptionTheme } }) =>
    buttonDescriptionTheme || {
      margin: '10px 10px 10px 0'
    }}
`

export {
  StyledButton,
  FaIcon,
  FaCrossIcon,
  FaLoadingIcon,
  FaPlusCircleIcon,
  ButtonDescription
}
