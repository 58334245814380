import React from 'react'
import PropTypes from 'prop-types'
import styles from './stylesheets/react-datepicker.module.css'

export default class MonthDropdownOptions extends React.Component {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    month: PropTypes.number.isRequired,
    monthNames: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
  }

  renderOptions = () => {
    return this.props.monthNames.map((month, i) => (
      <div
        className={
          this.props.month === i
            ? `${styles['react-datepicker__month-option']} ${styles['react-datepicker__month-option--selected_month']}`
            : styles['react-datepicker__month-option']
        }
        key={month}
        onClick={this.onChange.bind(this, i)}
      >
        {this.props.month === i ? (
          <span className={styles['react-datepicker__month-option--selected']}>
            ✓
          </span>
        ) : (
          ''
        )}
        {month}
      </div>
    ))
  }

  onChange = (month) => this.props.onChange(month)

  handleClickOutside = () => this.props.onCancel()

  render() {
    return (
      <div className={styles['react-datepicker__month-dropdown']}>
        {this.renderOptions()}
      </div>
    )
  }
}
